import { SlotStats, NodeStats } from "./Node";
import { UnitStats } from "./Platoon";
export type StatDetails<T extends object = Stat> = {
    readonly [key in keyof T]: {
        readonly name: string,
        readonly description?: string
    }
}
export type Stat = UnitStats & SlotStats & NodeStats;
export const unitStatDetails: StatDetails<UnitStats> = {
    maxHealth: {
        name: "Maximale Lebenspunkte"
    },
    defense: {
        name: "Verteidigung",
        description: "Die Verteidigungspunkte dieser Einheit. "
            + "\n"
            + "Eine Einheit mit vielen Verteidigungspunkten nimmt weniger Schaden als eine Einheit mit wenigen. "
            + "\n\n"
            + "Es werden immer die Einheiten mit den meisten Verteidigungspunkten in den Nahkampf gehen, während "
            + "schlechter verteidigende Einheiten im Fernkampf bleiben."
    },
    meleeAttack: {
        name: "Nahkampfangriffsstärke"
    },
    meleeRange: {
        name: "Nahkampfreichweite"
    },
    moveSpeed: {
        name: "Bewegungsgeschwindigkeit"
    },
    rangedAttack: {
        name: "Fernkampfangriffsstärke"
    },
    rangedRange: {
        name: "Fernkampfreichweite"
    },
    rangedReloadTime: {
        name: "Fernkampfnachladezeit"
    },
    rangedStartupTime: {
        name: "Fernkampfladezeit"
    },
    trainCosts: {
        name: "Ausbildungskosten"
    },
    trainTime: {
        name: "Ausbildungszeit"
    },
    manpower: {
        name: "Anzahl Soldaten",
        description: "Die Anzahl der Soldaten in dieser Einheit. Um die Einheit auszubilden, wird diese Menge an Bevölkerung benötigt."
    },
    maxHealing: {
        name: "Maximale Heilung",
        description: "Die maximal mögliche Menge an Lebenspunkten, die an einem Tag durch Heilung in Städten zurückgewonnen werden kann."
    }
};

export const slotStatDetails: StatDetails<SlotStats> = {
    buildCosts: {
        name: "Baukosten"
    },
    buildTime: {
        name: "Bauzeit"
    },
    miningSpeed: {
        name: "Abbaugeschwindigkeit"
    },
    productionPerTurn: {
        name: "Produktion pro Runde"
    },
    constructionJobs: {
        name: "Max. Bauarbeiter",
        description: "Maximale Anzahl der Arbeiter, die während des Baus am Gebäude arbeiten können."
    },
    maxWorkerJobs: {
        name: "Max. Arbeiter",
        description: "Maximale Anzahl der Arbeiter, die wärend des Betriebs im Gebäude arbeiten können."
    },
    minWorkerJobs: {
        name: "Min. Arbeiter",
        description: "Anzahl der Arbeiter, die zum Betrieb des Gebäudes notwendig ist."
    },
    miningJobs: {
        name: "Max. Bergarbeiter",
        description: "Maximale Anzahl der Bergarbeiter, die hier arbeiten können."
    }
}

export const nodeStatDetails: StatDetails<NodeStats> = {
    amenities: {
        name: "Annehmlichkeiten"
    },
    housing: {
        name: "Wohnraum"
    },
    popGrowth: {
        name: "Bevölkerungswachstum",
        description: "Das Bevölkerungswachstum in Prozent pro Jahr."
    },
    healing: {
        name: "Heilung pro Tag",
        description: "Die Anzahl der Lebenspunkte, die auf diesem Knoten täglich zurückgewonnen werden. "
            + "Die Lebenspunkte werden gleichmäßig auf die Einheiten verteilt, einzelne Einheiten haben "
            + "aber eine maximale Heilungsgeschwindigkeit, die nicht überschritten werden kann."
    },
    foodUse: {
        name: "Nahrungsmittelverbrauch",
        description: "Der Nahrungsmittelverbrauch durch Bevölkerung pro Tag."
    }
}

export const statDetails: StatDetails = { ...unitStatDetails, ...slotStatDetails, ...nodeStatDetails }
