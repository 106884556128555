import { GameState } from "../logic/GameState";
import { OnEdge, Platoon } from "../logic/Platoon";
import { GameObject } from "../logic/GameObject";
import { CanvasItem } from "./Canvas";
import { mouseOverCircle } from "./VisualUtils";

export function platoonOnCanvas(
    gameState: GameState,
    platoon: Platoon,
    position: [number, number],
    selection: ReadonlySet<string>): CanvasItem<GameObject> {
    const [x, y] = position;
    const isSelected = selection.has(platoon.tag);
    return {
        render(context, fow) {
            fow.beginPath();
            fow.ellipse(x, y, 7, 7, 0, 0, 2 * Math.PI);
            fow.fill();

            context.moveTo(x, y);
            context.beginPath();
            context.ellipse(x, y, 5, 5, 0, 0, 2 * Math.PI);

            if (platoon.faction === "player") {
                context.fillStyle = "blue";
                context.strokeStyle = "lightblue";
            }
            else {
                context.fillStyle = "darkred";
                context.strokeStyle = "orange";
            }
            context.lineWidth = isSelected ? 2 : 1;

            context.fill();
            context.stroke();
        },
        mouseOver: mouseOverCircle(x, y, 6),
        payload: { type: "platoon", tag: platoon.tag },
        isSelected(rect) {
            return x >= rect.left
                && y >= rect.top
                && x <= rect.right
                && y <= rect.bottom;
        }
    }
}

export function getWorldPos(gameState: GameState, position: OnEdge): [number, number] {
    const edge = gameState.edges[position.edge];
    const [node1, node2] = edge.nodes.map(node => gameState.nodes[node]);
    const t = position.position;
    return [
        node1.position[0] * (1 - t) + node2.position[0] * t,
        node1.position[1] * (1 - t) + node2.position[1] * t]
}