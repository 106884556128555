import _ from "lodash";
import { GameState } from "../../GameState";
import { produce } from "immer";
import Utils from "../../../utils";
import { requiredXp } from "../../Platoon";
import { getUnitStats } from "../../getUnitStats";

const battleWidth = 5;

export function updateBattles(gameState: GameState): GameState {
    return produce(gameState, gameState => {
        for (const battle of Utils.values(gameState.battles)) {
            const participants = Utils.resolveDraft(battle.participants, gameState.platoons)
                .map(platoon => ({
                    platoon,
                    units: platoon.units.map(unit => ({
                        unit,
                        stats: getUnitStats(gameState, unit),
                    })),
                    damageReceived: 0
                }));
            for (const platoon of participants) {
                const fightersSortedByDef = _.sortBy(platoon.units, x => -x.stats.defense);
                let i: number = 0;
                let totalDamage: number = 0;
                for (const { unit, stats } of fightersSortedByDef) {
                    if (i < battleWidth) {
                        totalDamage += stats.meleeAttack + unit.level;
                    }
                    else {
                        if (stats.rangedAttack !== undefined) {
                            if (unit.remainingReloadTime === undefined) {
                                unit.remainingReloadTime = stats.rangedStartupTime;
                            }
                            if ((unit.remainingReloadTime || 0) > 0) {
                                unit.remainingReloadTime = (unit.remainingReloadTime || 0) - 1;
                            }
                            else {
                                unit.remainingReloadTime = stats.rangedReloadTime;
                                totalDamage += (stats.rangedAttack || 0) + unit.level;
                            }
                        }
                    }
                    i++;
                }
                //const totalDamage = platoon.units.map(unit => (gameState.unitTypes[unit.kind].meleeAttack + unit.level)).reduce(_.add);
                const enemies = participants.filter(x => x.platoon.faction !== platoon.platoon.faction);
                const totalEnemyUnits = enemies.map(x => x.units.length).reduce(_.add, 0);
                for (const enemy of enemies) {
                    enemy.damageReceived += totalDamage * enemy.units.length / totalEnemyUnits;
                }
            }

            // apply damage and apply experience
            for (const { units, damageReceived } of participants) {
                const engagedUnits = Math.min(units.length, battleWidth);
                const damagePerUnit = damageReceived / engagedUnits;
                const defenders = units.map(({ unit, stats }) => ({ unit, defense: stats.defense }))
                const bestDefenders = _.sortBy(defenders, x => -x.defense).slice(0, engagedUnits);
                for (const defender of bestDefenders) {
                    const damageMultiplier = 10 / (10 + defender.defense + defender.unit.level);
                    defender.unit.currentHealth -= damagePerUnit * damageMultiplier;
                    defender.unit.experience += 1;
                }
                for (const { unit } of units) {
                    unit.experience += 1;
                    if (unit.experience >= requiredXp(unit.level)) {
                        unit.experience = 0;
                        unit.level += 1;
                    }
                }
            }

            const killedPlatoons = new Set<string>();
            for (const { platoon } of participants) {
                platoon.units = platoon.units.filter(unit => unit.currentHealth > 0);
                if (platoon.units.length === 0) {
                    killedPlatoons.add(platoon.tag);
                }
            }
            for (const killed of killedPlatoons) {
                delete gameState.platoons[killed];
            }

            const remainingParticipants = Utils.resolveDraft(battle.participants, gameState.platoons);
            const remainingFactions = _.uniq(remainingParticipants.map(p => p.faction));
            if (remainingFactions.length <= 1) {
                // the battle is removed -- need to reset the positions of all remaining platoons to the position of the battle
                for (const participant of remainingParticipants) {
                    participant.position = battle.position;
                }
                delete gameState.battles[battle.tag];
            }
        }
    });
}
