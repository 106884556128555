import { Faction } from "../Faction";
import { GameState } from "../GameState";
import { isAvailable } from "../Technology";
import validateIsOwnTown from "./_validation/validateIsOwnTown";

export interface TrainUnitCommand {
    type: "trainUnitCommand";
    unitType: string;
    node: string;
}
export function applyTrainUnitCommand(gameState: GameState, command: TrainUnitCommand, sender: Faction): GameState {
    const oldNode = validateIsOwnTown(gameState, command.node, sender);
    if(oldNode === undefined) return gameState;
    const unitType = gameState.unitTypes[command.unitType];
    if (sender !== "enemy") {
        const trainability = isAvailable(unitType.requiredTech, gameState.research);
        if (trainability !== "available") {
            console.warn(`Invalid command: Cannot train ${command.unitType}: `, trainability)
            return gameState;
        }
    }
    if (unitType.requiredBuilding !== undefined
        && !oldNode.buildSlots.some(x => x.site.type === "building" && x.site.kind === unitType.requiredBuilding)) {
        console.warn(`Training ${unitType.tag} in ${oldNode.tag} failed, since no building of type ${unitType.requiredBuilding} was found.`);
        return gameState;
    }
    return {
        ...gameState, nodes: {
            ...gameState.nodes, [command.node]: {
                ...oldNode,
                trainingQueue: [
                    ...(oldNode.trainingQueue ?? []), {
                        kind: command.unitType,
                        deliveredResources: {},
                        progress: 0,
                        conscriptedManpower: 0
                    }
                ]
            }
        }
    };
}
