import Utils from "../../utils";
import { Faction } from "../Faction";
import { GameState } from "../GameState";
import { Platoon, Unit } from "../Platoon";


export interface TransferUnitsCommand {
    type: "transferUnitsCommand";
    sourcePlatoon: string;
    targetPlatoon: string | null;
    units: number[] | "all";
}
export function applyTransferUnitsCommand(gameState: GameState, command: TransferUnitsCommand, sender: Faction): GameState {
    if (command.sourcePlatoon === command.targetPlatoon) {
        console.log("Invalid command: The source and target platoons must be identical.");
        return gameState;
    }
    const source = gameState.platoons[command.sourcePlatoon];
    if (source === undefined) {
        console.warn(`Invalid command: The source platoon ${command.sourcePlatoon} does not exist`);
        return gameState;
    }
    if (source.faction !== sender) {
        console.warn(`Invalid command: Cannot transfer units from other faction`);
        return gameState;
    }
    let newSourceUnits, movedUnits: Unit[];
    if (command.units === "all") {
        newSourceUnits = [];
        movedUnits = source.units;
    }
    else {
        const movedIndices = new Set(command.units);
        if (movedIndices.size !== command.units.length) {
            console.warn(`Invalid command: Cannot move units more than once`);
            return gameState;
        }
        newSourceUnits = [];
        movedUnits = [];
        for (let i = 0; i < source.units.length; i++) {
            const element = source.units[i];
            if (movedIndices.has(i))
                movedUnits.push(element);
            else
                newSourceUnits.push(element);
        }
    }
    let target: Platoon;
    const createTarget = (tag: string) => ({
        faction: source.faction,
        path: [],
        position: source.position,
        units: movedUnits,
        tag
    });
    if (command.targetPlatoon === null) {
        target = createTarget(Utils.getUnusedTag("p"));
    }
    else {
        const maybeTarget = gameState.platoons[command.targetPlatoon];
        if (maybeTarget === undefined)
            target = createTarget(command.targetPlatoon);
        else {
            target = maybeTarget;
            if (target.faction !== sender) {
                console.warn("Invalid command: Cannot transfer units to other faction");
                return gameState;
            }
            target = {
                ...target,
                units: [...target.units, ...movedUnits]
            }
        }
    }
    const newPlatoons = {
        ...gameState.platoons,
        [source.tag]: { ...source, units: newSourceUnits },
        [target.tag]: target
    };
    if (newSourceUnits.length === 0)
        delete newPlatoons[source.tag]
    if (target.units.length === 0) {
        console.warn("Invalid command: Did not move any units");
        return gameState;
    }
    return {
        ...gameState,
        platoons: newPlatoons
    };
}
