import React from 'react';
import { applyCommand } from '../logic/commands/_Command';
import { totalVision, updateAllVision } from '../logic/fogOfWar';
import { Cheats, CheatUi } from './Cheats';
import { applyTick, ExtendedCommand, SaveGame, speeds, SpeedState } from './SaveGame';
import { GameSource, loadOrCreateGame } from './GameSource';
import { Screen } from '../App';
import { IngameMenu } from './IngameMenu';
import GameScreen from './GameScreen';
import { GameStorage } from './GameStorage';
import { SpeedContext, SubmitCommandContext } from './SaveGame';

export function SinglePlayerGameScreen(props: {
  source: GameSource;
  setScreen: (screen: Screen) => void;
}) {
  const [saveGame, setSaveGame] = React.useState<SaveGame>();
  React.useEffect(() => {
    setSaveGame(undefined);
    loadOrCreateGame(props.source)
      .then(setSaveGame)
      .catch(e => alert(`Could not load game: ${e}`))
      ;
  }, [props.source]);

  if (!saveGame)
    return <>Laden...</>
  else
    return <SinglePlayerGameScreenLoaded saveGame={saveGame} setSaveGame={setSaveGame} setScreen={props.setScreen} />
}

function SinglePlayerGameScreenLoaded(props: {
  saveGame: SaveGame;
  setSaveGame: (saveGame: SaveGame) => void;
  setScreen: (screen: Screen) => void;
}) {
  const { saveGame, setSaveGame } = props;
  const [cheats, setCheats] = React.useState<Cheats>({ aiInactive: false, fullVision: false });

  const [speedState, setSpeedState] = React.useState<SpeedState>({ speed: 0, paused: true });

  // Use refs to prevent the tick method to capture any mutable state. 
  // This way, the TimeControlUi will not try to re-set the interval on each upate.
  const saveGameRef = React.useRef(saveGame);
  saveGameRef.current = saveGame;
  const cheatsRef = React.useRef(cheats);
  cheatsRef.current = cheats;
  const tick = React.useCallback(async () => {
    const newSaveGame = applyTick(saveGameRef.current, cheatsRef.current.aiInactive);
    setSaveGame(newSaveGame);
    await GameStorage.autosave(newSaveGame);
  }, [saveGameRef, cheatsRef, setSaveGame]);

  React.useEffect(() => {
    if (speedState.paused)
      return;
    const interval = setInterval(tick, speeds[speedState.speed]);
    return () => clearInterval(interval);
  }, [speedState, tick]);

  const submitScreenCommand = (command: ExtendedCommand) => {
    switch (command.type) {
      case "setPausedCommand":
        setSpeedState({ ...speedState, paused: command.paused });
        break;
      case "setSpeedCommand":
        setSpeedState({ ...speedState, speed: command.speed });
        break;
      case "setSavegameNameCommand":
        setSaveGame({ ...saveGame, ref: { name: command.name, isAutosave: false } });
        break;
      default:
        const newGameState = applyCommand(saveGame.gameState, command, "player");
        setSaveGame({
          ...saveGame,
          gameState: newGameState,
          visibleGameStates: updateAllVision(newGameState, saveGame.visibleGameStates),
        });
        break;
    }
  };


  const visibleState = cheats.fullVision ? totalVision(saveGame.gameState) : saveGame.visibleGameStates["player"];

  return <SpeedContext.Provider value={speedState}>
    <SubmitCommandContext.Provider value={submitScreenCommand}>
      <GameScreen
        gameState={visibleState}
        cheatUi={<CheatUi cheats={cheats} setCheats={setCheats} />}
        ingameMenu={close => <IngameMenu
          closeMenu={close}
          setScreen={props.setScreen}
          saveGame={saveGame}
        />} />
    </SubmitCommandContext.Provider>
  </SpeedContext.Provider>;
}
