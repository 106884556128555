import { Immutable } from "immer";
import _ from "lodash";
import Utils from "../../../utils";
import { GameState } from "../../GameState";
import { getSlotStatsFor } from "../../getSlotStats";
import { Building, Construction, isTown, Mine, Node, Town } from "../../Node";

export function updateJobs(gameState: GameState): GameState {
    return {
        ...gameState,
        nodes: Utils.mapObject(gameState.nodes, node => recalculateJobAssignments(gameState, node))
    };
}
export function recalculateJobAssignments(gameState: GameState, node: Node): Node {
    if (!isTown(node))
        return node;

    const assignments = getJobAssignments(gameState, node);
    const assignmentsBySlot = new Map(assignments.map(x => [x.index, x.assignedJobs]))

    return {
        ...node,
        buildSlots: node.buildSlots.map((slot, index) => ({
            ...slot,
            site: {
                ...slot.site,
                assignedJobs: assignmentsBySlot.get(index)!
            }
        }))
    }
}
function getJobAssignments(gameState: GameState, node: Town) {
    const _sites = node.buildSlots
        .map((x, i) => ({ site: x.site, slotIndex: i }))
        .filter(x => x.site.type !== "rubble") as { site: Immutable<Building | Construction | Mine>, slotIndex: number }[];
    const sites = _sites.map(({ site, slotIndex }) => {
        const stats = getSlotStatsFor(gameState, { nodeTag: node.tag, slotIndex });
        let minJobs: number, maxJobs: number;
        switch (site.type) {
            case "building":
                minJobs = stats.minWorkerJobs;
                maxJobs = stats.maxWorkerJobs;
                break;
            case "construction":
                minJobs = 0;
                maxJobs = stats.constructionJobs;
                break;
            case "mine":
                minJobs = 0;
                maxJobs = stats.miningJobs;
                break;
        }
        return {
            site,
            index: slotIndex,
            minJobs, maxJobs,
            assignedJobs: 0
        }
    });
    sites.sort((a, b) => b.site.priority - a.site.priority);

    let freeWorkers = node.population;
    // 1: reset all jobs
    for (const site of sites) {
        site.assignedJobs = 0;
    }

    // 2: remove inactive sites
    const activeSites = sites.filter(x => x.site.active);

    // 3: assign minimum jobs
    for (const site of activeSites) {
        if (!site.site.active) continue;
        const minJobs = site.minJobs;
        if (minJobs > freeWorkers) {
            site.assignedJobs = freeWorkers;
            return sites;
        }
        site.assignedJobs = minJobs;
        freeWorkers -= minJobs;
    }

    // 4: assign up to maximum jobs
    // 4.1: group buildings by priority
    const groups = []
    let lastPriority = undefined;
    for (const site of activeSites) {
        if (!site.site.active) continue;
        if (site.site.priority !== lastPriority) {
            groups.push([site]);
            lastPriority = site.site.priority;
        }
        else {
            groups[groups.length - 1].push(site);
        }
    }
    // 4.2: distribute remaining workers within the groups
    for (const group of groups) {
        const numWorkplaces = group
            .map(x => x.maxJobs - x.assignedJobs)
            .reduce(_.add);
        if (numWorkplaces < freeWorkers) {
            for (const site of group) {
                site.assignedJobs = site.maxJobs;
            }
            freeWorkers -= numWorkplaces;
        }
        else {
            group.sort((a, b) => b.maxJobs - a.maxJobs);
            for (let i = group.length - 1; i >= 0; i--) {
                const site = group[i];
                const remainingBuildings = i + 1;
                const workersPerBuilding = Math.floor(freeWorkers / remainingBuildings);
                const requiredWorkers = site.maxJobs - site.assignedJobs;
                if (requiredWorkers < workersPerBuilding) {
                    site.assignedJobs += requiredWorkers;
                    freeWorkers -= requiredWorkers;
                }
                else {
                    site.assignedJobs += workersPerBuilding;
                    freeWorkers -= workersPerBuilding;
                }
            }
        }
    }
    return sites
}