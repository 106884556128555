import { addModifierSource, ModifierWithSource } from "./Modifiers";
import { getTechModifiers, isNodeTechModifier } from "./Technology";
import { GameState } from "./GameState";
import makeStatsBuilder from "./makeStatsBuilder";
import Utils from "../utils";
import { defaultNodeStats, isTown, Node, NodeStats } from "./Node"

export function getNodeStats(
    gameState: GameState,
    node: Node) {

    const builder = makeNodeStats({ foodUse: (node as any).population ?? 0 });

    // add tech modifiers
    builder.addModifiers(getTechModifiers(gameState.technologies, gameState.research)
        .filter(x => isNodeTechModifier(x)
            && (Utils.isSubset(x.features, Object.keys(node.features)))
        ) as ModifierWithSource<keyof NodeStats>[]);

    // add building modifiers
    builder.addModifiers(node.buildSlots.flatMap((slot, i) => {
        const site = slot.site;
        if (site.type !== "building" || !site.active)
            return [];
        const type = gameState.buildingTypes[site.kind];
        const scaling = type.maxWorkerJobs !== undefined ? site.assignedJobs / type.maxWorkerJobs : 1;
        return addModifierSource(
            gameState.buildingTypes[site.kind].nodeModifiers,
            { type: "fromBuilding", buildingType: type.tag, slot: i },
            scaling);
    }));

    // add feature modifiers
    builder.addModifiers(Object.entries(node.features).flatMap(([feature, props]) => {
        return addModifierSource(
            gameState.nodeFeatures[feature].nodeModifiers,
            { type: "fromNodeFeature", feature },
            props.strength);
    }));

    // add pop growth modifiers
    if (isTown(node)) {
        const housingAvailability = (builder.housing - node.population) / node.population;
        let popGrowthModifier = 0;
        const lowAvailabilityThreshold = 0.1;
        if (node.population === 0) {
            popGrowthModifier = 0;
        }
        else if (housingAvailability < 0) {
            popGrowthModifier = -5 + housingAvailability * 100;
        }
        else if (housingAvailability < lowAvailabilityThreshold) {
            popGrowthModifier = -5 * (1 - housingAvailability / lowAvailabilityThreshold);
        }
        else {
            popGrowthModifier = 0;
        }
        builder.addModifier({
            source: { type: "fromOtherStat", stat: "housing" },
            property: "popGrowth",
            type: "flat",
            value: popGrowthModifier,
        })
    }

    return builder.freeze();
}
const makeNodeStats = makeStatsBuilder(defaultNodeStats);

export function getNodeStatsFor(gameState: GameState, node: string | Node) {
    if (typeof node === "string")
        node = gameState.nodes[node];
    return getNodeStats(
        gameState,
        node);
}
