import { GameState } from "../logic/GameState";
import { isTown, Node, Biome, AbandonedStructures } from "../logic/Node";
import { Platoon } from "../logic/Platoon";
import { GameObject } from "../logic/GameObject";
import { CanvasItem } from "./Canvas";
import { platoonOnCanvas } from "./PlatoonOnCanvas";
import { mouseOverCircle } from "./VisualUtils";
import { isOrcified } from "../logic/mapGeneration/generateMap";
import { VisibleNode } from "../logic/fogOfWar";
import { VisualSelection } from "./VisualSelection";
import { Faction } from "../logic/Faction";

export function getBiomeColor(biome: Biome) {
  switch (biome) {
    case Biome.Lakes:
      return "hsl(210, 30%, 30%)";
    case Biome.ThermalSprings:
      return "hsl(250, 20%, 30%)";
    case Biome.Volcanic:
      return "hsl(45, 25%, 40%)";
    case Biome.VolcanicExtreme:
      return "hsl(25, 25%, 40%)";
    case Biome.Standard:
    default:
      return "#555";
  }
}

export function getFactionColor(faction: Faction) {
  if (faction === "player") {
    return "#aaa";
  }
  else {
    return "hsl(-10, 60%, 50%)";
  }
}

export function nodeOnCanvas(
  gameState: GameState,
  node: VisibleNode,
  platoons: Platoon[],
  selection: VisualSelection): CanvasItem<GameObject>[] {
  const [x, y] = node.position;
  const size = getNodeSize(node);

  const textStyle = selection.nodes.has(node.tag) ? "bold" : ""

  const isVisible = node.lastSeen === gameState.time;
  const adjacentVisible = node.knowsAdjacentNodes;

  const fillStyle = (() => {
    //colour nodes by abandoned structure for test purposes; Change boolean to enable/disable
    let abandonedTest: boolean = false;
    if (abandonedTest) {
      switch (node.abandonedStructure) {
        case AbandonedStructures.City:
          return "#00f";
        case AbandonedStructures.Town:
          return "#0f0";
        case AbandonedStructures.Bastion:
          return "#ff0";
        case AbandonedStructures.Mine:
          return "#f00";
        default:
          return "#555";
      }
    }

    //colour nodes by orcification for test purposes; Change boolean to enable/disable
    let orcificationTest: boolean = false;
    if (orcificationTest && isOrcified(node)) {
      switch (node.orcification) {
        case 1:
          return "#0f0";
        case 2:
          return "#ff0";
        case 3:
          return "#f00";
        default:
          return "#555";
      }
    }

    //colour nodes by node features for test purposes; Change boolean to enable/disable
    let specialNodeTest: boolean = false;
    if (specialNodeTest) {
      if (node.features["protectedValley"] !== undefined) {
        return "#0f0";
      }
      if (node.features["greatBath"] !== undefined) {
        return "#00f";
      }
      if (node.features["forgeOfTheAncients"] !== undefined) {
        console.log("foundForge");
        return "#f00";
      }
      if (Object.keys(node.features).length > 0) {
        return "#fff";
      }
      // return "#555";
    }

    return getBiomeColor(node.biome);
  })();

  function renderNode(context: CanvasRenderingContext2D, fow: CanvasRenderingContext2D, deco: CanvasRenderingContext2D) {
    context.beginPath();
    context.ellipse(x, y, size, size, 0, 2 * Math.PI, 0);

    if (isTown(node)) {
      context.fillStyle = getFactionColor(node.faction);
      context.strokeStyle = fillStyle;
      context.lineWidth = 5;
      context.fill();
      context.stroke();

      deco.fillStyle = fillStyle;
      deco.beginPath();
      deco.textAlign = 'center';
      deco.textBaseline = 'top'
      deco.font = textStyle + " 10pt sans-serif"
      deco.fillText(node.name, x, y + size + 10, size * 4);
    }
    else {
      context.fillStyle = fillStyle;
      context.fill();
    }

    if (!adjacentVisible) {
      context.strokeStyle = fillStyle;
      context.setLineDash([2, 4]);
      context.lineWidth = 2;
      context.beginPath();
      context.ellipse(x, y, size + 4, size + 4, 0, 2 * Math.PI, 0);
      context.stroke();
      context.setLineDash([]);
    }
    if (isVisible) {
      fow.beginPath();
      fow.ellipse(x, y, size + 5, size + 5, 0, 2 * Math.PI, 0);
      fow.fill();
    }
  }

  return [
    {
      render: renderNode,
      mouseOver: mouseOverCircle(x, y, size + 2.5),
      payload: { type: "node", tag: node.tag }
    },
    ...platoons.map((platoon, index) => platoonOnCanvas(gameState,
      platoon,
      [x - (index - (platoons.length - 1) / 2) * 20, y - size - 5],
      selection.platoons))
  ]
}
export function getNodeSize(node: Node) {
  return 5 + Math.sqrt(node.buildSlots.length) * 7;
}
