import { produce } from "immer";
import { Faction } from "../Faction";
import { GameState } from "../GameState";
import { getSlotStatsFor } from "../getSlotStats";
import { BuildSlotIdentifier, hasJobs } from "../Node";
import { ResourceUtils } from "../Resources";
import { isAvailable } from "../Technology";
import { recalculateJobAssignments } from "./TickCommand/updateJobs";
import validateIsOwnTown from "./_validation/validateIsOwnTown";

export interface BuildCommand {
    type: "buildCommand";
    slot: BuildSlotIdentifier;
    target?: string;
}
export function applyBuildCommand(gameState: GameState, command: BuildCommand, sender: Faction): GameState {
    return produce(gameState, gameState => {
        const { nodeTag, slotIndex } = command.slot;
        const node = validateIsOwnTown(gameState, nodeTag, sender);
        if (node === undefined) return;
        const slot = node.buildSlots[slotIndex];
        if (slot === undefined) {
            console.warn(`Invalid command: The slot ${nodeTag}#${slotIndex} does not exist`);
            return;
        }
        const site = slot.site;
        if (command.target !== undefined) {
            const buildingType = gameState.buildingTypes[command.target];
            const availability = isAvailable(buildingType.requiredTech, gameState.research);
            if (availability !== "available") {
                console.warn(`Invalid command: Cannot build ${command.target} due to research status: ${availability}`);
                return;
            }
            if (buildingType.requiredFeature !== undefined
                && !slot.features.includes(buildingType.requiredFeature)
                && node.features[buildingType.requiredFeature] !== undefined) {
                console.warn(`Invalid command: The feature ${buildingType.requiredFeature} required by ${command.target} is not present on ${nodeTag}#${slotIndex}.`);
                return;
            }
            if (buildingType.upgradesFrom !== undefined
                && !(slot.site.type === "building" && slot.site.kind === buildingType.upgradesFrom)) {
                console.warn(`Invalid command: The building ${command.target} is an upgrade to ${buildingType.upgradesFrom}, which is not present on ${nodeTag}#${slotIndex}.`);
                return;
            }
        }
        let jobs = {
            active: true,
            assignedJobs: 0,
            priority: 0
        }
        if (site.type === "empty") {
            if (command.target === undefined)
                return;
            slot.site = {
                type: "construction",
                kind: command.target,
                progress: 0,
                resources: {},
                ...jobs
            }
        }
        else {
            if (hasJobs(site))
                jobs.priority = site.priority;
            slot.site = {
                type: "mine",
                plannedBuildingType: command.target,
                resources: site.type === "building" ?
                    ResourceUtils.scale(getSlotStatsFor(gameState, command.slot).buildCosts, 0.6) :
                    site.resources,
                ...jobs
            };
        }

        gameState.nodes[command.slot.nodeTag] = recalculateJobAssignments(gameState, node);
    });
}
