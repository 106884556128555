import { GameState } from "../../GameState";
import Utils from "../../../utils";
import { isTown, raze } from "../../Node";
import { getNodeStatsFor } from "../../getNodeStats";

export function updatePopulation(gameState: GameState): GameState {
    return {
        ...gameState,
        nodes: Utils.mapObject(gameState.nodes, node => {
            if (!isTown(node))
                return node;
            const stats = getNodeStatsFor(gameState, node);
            const newPop = node.population * (1 + stats.popGrowth / 100) ** (1 / 365);
            if (newPop < 0)
                return raze(node);

            else
                return { ...node, population: newPop };
        })
    };
}
