import { GameState } from "../../GameState";
import { ResearchStatus, Technology } from "../../Technology";

export function updateResearch(gameState: GameState): GameState {
    //TODO: research should be retrieved from buildings
    let remainingResearch = 1;
    let queue = gameState.researchQueue;
    let research = { ...gameState.research };
    while (queue.length > 0 && remainingResearch > 0) {
        const tag = queue[0];
        const result = applyResearch(gameState.technologies, research, tag, remainingResearch);
        if (result.newState === "finished") {
            queue = queue.slice(1);
            research[tag] = "finished";
        }
        else if (result.newState === "invalid") {
            queue = queue.slice(1);
        }
        else {
            research[tag] = result.newState;
        }
        remainingResearch = result.newRemainingResearch;
    }
    return { ...gameState, researchQueue: queue, research };
}
function applyResearch(technologies: { [tag: string]: Technology; }, research: { [tag: string]: ResearchStatus; }, techTag: string, remainingResearch: number): { newState: "finished" | "invalid" | number; newRemainingResearch: number; } {
    let state = research[techTag];
    if (state === "finished")
        return { newState: "finished", newRemainingResearch: remainingResearch };
    const tech = technologies[techTag];
    const dependenciesMet = tech.dependencies.map(dep => research[dep] === "finished").reduce((a, b) => a && b, true);
    if (!dependenciesMet)
        return { newState: "invalid", newRemainingResearch: remainingResearch };
    state = state ?? 0;
    const workLeft = tech.researchTime - state;
    if (workLeft < remainingResearch)
        return { newState: "finished", newRemainingResearch: remainingResearch - workLeft };

    else
        return { newState: state + remainingResearch, newRemainingResearch: 0 };
}
