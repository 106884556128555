import { GameState } from "../../GameState";
import { ResourceUtils } from "../../Resources";
import { produce } from "immer";
import { getSlotStatsFor } from "../../getSlotStats";


export default function updateMines(gameState: GameState) {
    const mines = Object.values(gameState.nodes)
        .flatMap(node => node.buildSlots.map((slot, i) => (slot.site.type === "mine" ? { nodeTag: node.tag, slotIndex: i } : undefined)));
    gameState = produce(gameState, gameState => {
        for (const _site of mines) {
            if (_site === undefined)
                continue;
            const node = gameState.nodes[_site.nodeTag];
            const slot = node.buildSlots[_site.slotIndex];
            if (slot.site.type !== "mine")
                continue; // need this guard to make typescript happy :-/
            if (!slot.site.active)
                continue;
            const stats = getSlotStatsFor(gameState, _site);

            const { extracted, remaining } = ResourceUtils.doMining(slot.site.resources, stats.miningSpeed);
            gameState.resources = ResourceUtils.add(gameState.resources, extracted);
            if (remaining !== undefined)
                slot.site.resources = remaining;

            else {
                if (slot.site.plannedBuildingType !== undefined) {
                    slot.site = {
                        type: "construction",
                        active: true,
                        assignedJobs: 0,
                        kind: slot.site.plannedBuildingType,
                        priority: slot.site.priority,
                        progress: 0,
                        resources: {}
                    };
                }
                else {
                    slot.site = { type: "empty" }
                }
            }
        }
    });
    return gameState;
}
