import React from 'react';
import { BuildingType } from '../logic/Node';
import { UnitType } from '../logic/Platoon';
import "./Icon.css"

export function BuildingIcon(props: { buildingType: BuildingType; }) {
  return <ObjectIcon class="building" tag={props.buildingType.tag} name={props.buildingType.name}/>
}
export function UnitIcon(props: { unitType: UnitType; }) {
  return <ObjectIcon class="unit" tag={props.unitType.tag} name={props.unitType.name}/>
}

export function ObjectIcon(props: { class: string, tag: string, name: string }) {
  return <img 
      className={`icon ${props.class}-icon`}
      src={`${process.env.PUBLIC_URL}/icons/${props.class}s/${props.tag}.png`}
      alt={props.name} 
    />;
}
