import { GameMap } from "../GameState";
import { Biome, building } from "../Node";

export const testMap: GameMap = {
    nodes: {
        "zh": {
            tag: "zh",
            name: "Zwergenhausen",
            description: "Hauptstadt des Zwergenreichs",
            founded: 5327 * 365,
            population: 30,
            position: [800, 300],
            biome: Biome.Standard,
            buildSlots: [
                building("house"),
                building("farm"),
                { features: [], site: { type: "rubble", resources: { stone: 100 } } },
                { features: [], site: { type: "rubble", resources: { stone: 100 } } },
                { features: [], site: { type: "rubble", resources: { stone: 100 } } },
                { features: [], site: { type: "rubble", resources: { stone: 100 } } },
                { features: [], site: { type: "rubble", resources: { stone: 50, iron: 50 } } },
                { features: [], site: { type: "rubble", resources: { stone: 100 } } },
                { features: [], site: { type: "rubble", resources: { stone: 100 } } }
            ],
            faction: "player",
            features: {}
        },
        "zd": {
            tag: "zd",
            name: "Zwergendorf",
            description: "Erste Kolonie des Zwergenreichs",
            founded: 5327 * 365,
            population: 7,
            position: [1200, 400],
            biome: Biome.Standard,
            buildSlots: [
                { features: [], site: { type: "rubble", resources: { iron: 100 } } },
                { features: [], site: { type: "rubble", resources: { iron: 100 } } }
            ],
            faction: "player",
            features: {"exit": {}}
        },
        "oh": {
            tag: "oh",
            name: "Orkheim",
            description: "Eine Siedlung der Orks",
            biome: Biome.Standard,
            buildSlots: [
                { features: [], site: { type: "rubble", resources: { iron: 100 } } },
                { features: [], site: { type: "rubble", resources: { iron: 100 } } },
                { features: [], site: { type: "rubble", resources: { iron: 100 } } },
                { features: [], site: { type: "rubble", resources: { iron: 100 } } },
            ],
            faction: "enemy",
            founded: 4321 * 365,
            population: 50,
            position: [940, 820],
            features: {}
        },
        "j1": {
            tag: "j1",
            buildSlots: [],
            biome: Biome.Standard,
            position: [1000, 300],
            features: {}
        },
        "j2": {
            tag: "j2",
            buildSlots: [],
            biome: Biome.Standard,
            position: [1050, 420],
            features: {}
        },
        "j3": {
            tag: "j3",
            buildSlots: [],
            biome: Biome.Standard,
            position: [950, 460],
            features: {}
        },
        "j4": {
            tag: "j4",
            buildSlots: [
                { features: [], site: { type: "rubble", resources: { iron: 100 } } },
                { features: [], site: { type: "rubble", resources: { iron: 100 } } },
                { features: [], site: { type: "rubble", resources: { iron: 100 } } },
                { features: [], site: { type: "rubble", resources: { iron: 100 } } }
            ],
            biome: Biome.Standard,
            position: [960, 580],
            features: {}
        },
        "j5": {
            tag: "j5",
            buildSlots: [],
            biome: Biome.Standard,
            position: [920, 700],
            features: {}
        }
    },
    edges: {
        "e1": { tag: "e1", nodes: ["j1", "j2"], length: 100 },
        "e2": { tag: "e2", nodes: ["j1", "zh"], length: 100 },
        "e3": { tag: "e3", nodes: ["j2", "zd"], length: 100 },
        "e4": { tag: "e4", nodes: ["j2", "j3"], length: 100 },
        "e5": { tag: "e5", nodes: ["j3", "j4"], length: 100 },
        "e6": { tag: "e6", nodes: ["j4", "j5"], length: 100 },
        "e7": { tag: "e7", nodes: ["j5", "oh"], length: 100 },
    },
    platoons: {
        "expedition1": {
            tag: "expedition1",
            path: [],
            position: { type: "onEdge", position: 0.3, edge: "e2" },
            units: [
                { kind: "settler", currentHealth: 100, level: 0, experience: 0 }
            ],
            faction: "player"
        },
        "zd_guard": {
            tag: "zd_guard",
            path: [],
            position: { type: "onNode", node: "zd" },
            units: [
                { kind: "heavy guard", currentHealth: 100, level: 0, experience: 0 },
            ],
            faction: "player"
        },
        "enemy1": {
            tag: "enemy1",
            path: [],
            position: { type: "onNode", node: "oh" },
            units: [
                // { kind: "heavy guard", currentHealth: 100, level: 0, experience: 0 },
                { kind: "goblin", currentHealth: 100, level: 0, experience: 0 }
            ],
            faction: "enemy"
        }
    },
    battles: {},
    heroes: {}
};
