import Delaunator from "delaunator";
import _ from "lodash";
import Utils from "../../utils";
import { Edge } from "../Edge";
import { Node } from "../Node";

/**
 * Gets the edges of a delaunay triangulation of a bunch of points.
 * 
 * Note that only 2^16 (65k) nodes are supported at max.
 * 
 * @param vertices The input vertex positions.
 * @returns The resulting edges as pairs of vertex indices.
 */
function generateRawDelaunayEdges(vertices: [number, number][]): [number, number][] {
    if (vertices.length > (1 << 16))
        throw new Error("This function does not support more than 2^16 nodes.");
    const x = Delaunator.from(vertices);
    const edges = new Set<number>();
    const addEdge = (i: number, j: number) => {
        // no tuples yet :-( have to encode two numbers into one
        if (i < j)
            edges.add((i << 16) | j);
        else
            edges.add((j << 16) | i);
    }
    for (let i = 0; i < x.triangles.length; i += 3) {
        addEdge(x.triangles[i], x.triangles[i + 1])
        addEdge(x.triangles[i + 1], x.triangles[i + 2])
        addEdge(x.triangles[i + 2], x.triangles[i])
    }
    for (let i = 1; i < x.hull.length; i++) {
        addEdge(x.hull[i - 1], x.hull[i]);
    }
    return _.map([...edges], (e: number) => [e >> 16, e & 0xffff])
}

export function generateDelaunayEdges(rawNodePositions: [number, number][], initialnodeArray: Node[]): Edge[] {
    const rawEdges = generateRawDelaunayEdges(rawNodePositions);
    return rawEdges.map(([i, j]) => {
        const a = initialnodeArray[i];
        const b = initialnodeArray[j];
        const dx = a.position[0] - b.position[0];
        const dy = a.position[1] - b.position[1];
        const length = Math.sqrt(dx * dx + dy * dy);
        return { nodes: [a.tag, b.tag], length, tag: Utils.getUnusedTag("e") };
    });
}
