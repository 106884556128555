import React from 'react';
import { useHotkey } from '../ui/Hotkey';

export interface Rectangle {
  left: number,
  top: number,
  right: number,
  bottom: number,
}

export interface CanvasItem<T> {
  render: (main: CanvasRenderingContext2D,
    fogOfWar: CanvasRenderingContext2D,
    deco: CanvasRenderingContext2D) => void
  mouseOver?: (x: number, y: number) => boolean
  payload?: T
  isSelected?: (rect: Rectangle) => boolean
}

interface Camera {
  x: number,
  y: number,
  zoom: number
}

const minZoom = 0.1;
const maxZoom = 10;

function prepareContext(ref: React.RefObject<HTMLCanvasElement>, camera: Camera) {
  const canvas = ref.current;
  if (canvas === null) {
    throw new Error("Ref can't be null here.");
  }

  const context = canvas.getContext("2d");

  if (context === null) {
    alert("Canvas wird nicht unterstützt. Sadface dot png.");
    return;
  }

  canvas.width = canvas.clientWidth * devicePixelRatio;
  canvas.height = canvas.clientHeight * devicePixelRatio;

  context.clearRect(0, 0, canvas.width, canvas.height);
  context.translate(camera.x, camera.y);
  context.scale(camera.zoom, camera.zoom);

  return context;
}

export function CanvasElement<T>(props: {
  items: CanvasItem<T>[],
  onClick: (payload?: T) => void,
  onRightClick: (payload?: T) => void,
  onSelect: (payload: T[]) => void
}) {
  const ref = React.useRef<HTMLCanvasElement>(null);
  const decoRef = React.useRef<HTMLCanvasElement>(null);
  const fowRef = React.useRef<HTMLCanvasElement>(null);
  // The following size field is not used, but the setSize method is used to trigger a redraw on resize.
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [size, setSize] = React.useState<[number, number]>([0, 0]);
  const [camera, setCamera] = React.useState<Camera>({ x: 0, y: 0, zoom: 1 })

  const getMouseOverItem = (x: number, y: number) => {
    for (let i = props.items.length - 1; i >= 0; i--) {
      const item = props.items[i];
      if (item.mouseOver && item.mouseOver(x, y))
        return item
    }
  }

  const getWorldPos = (x: number, y: number): [number, number] => {
    return [
      (x - camera.x) / camera.zoom,
      (y - camera.y) / camera.zoom
    ]
  }

  const getMousePos = (e: React.MouseEvent): [number, number] => [e.clientX * devicePixelRatio, e.clientY * devicePixelRatio];

  const handleClick = (e: React.MouseEvent, callback: (payload?: T) => void) => {
    const [x, y] = getWorldPos(...getMousePos(e));
    const target = getMouseOverItem(x, y);
    callback(target?.payload);
  }

  // Frame count is interesting for animations. If no animations occur, it is not required.
  // TODO: Find a way to lazily update the frame count
  // const [frameCount, setFrameCount] = React.useState<number>(0);
  // React.useEffect(() => {
  //   const frameId = requestAnimationFrame(() => setFrameCount(frameCount + 1));
  //   return () => cancelAnimationFrame(frameId);
  // }, [frameCount, setFrameCount]);

  const moveCamera = (dx: number, dy: number) => {
    setCamera(camera => ({
      ...camera,
      x: camera.x + dx,
      y: camera.y + dy
    }))
  }

  const zoomCamera = (multiplier: number, clientX: number, clientY: number) => {
    let newZoom = camera.zoom * multiplier;
    if (newZoom > maxZoom) newZoom = maxZoom;
    if (newZoom < minZoom) newZoom = minZoom;
    const zoomMultiplier = newZoom / camera.zoom;
    setCamera({
      ...camera,
      x: zoomMultiplier * (camera.x - clientX) + clientX,
      y: zoomMultiplier * (camera.y - clientY) + clientY,
      zoom: camera.zoom * zoomMultiplier
    })
  }

  useHotkey((dt) => moveCamera(0, +1 * dt), "w", {}, true)
  useHotkey((dt) => moveCamera(0, -1 * dt), "s", {}, true)
  useHotkey((dt) => moveCamera(+1 * dt, 0), "a", {}, true)
  useHotkey((dt) => moveCamera(-1 * dt, 0), "d", {}, true)

  useHotkey((dt) => moveCamera(0, +1 * dt), "ArrowUp", {}, true)
  useHotkey((dt) => moveCamera(0, -1 * dt), "ArrowDown", {}, true)
  useHotkey((dt) => moveCamera(+1 * dt, 0), "ArrowLeft", {}, true)
  useHotkey((dt) => moveCamera(-1 * dt, 0), "ArrowRight", {}, true)

  useHotkey((dt) => zoomCamera(Math.pow(2, dt / 300), size[0] / 2, size[1] / 2), "q", {}, true)
  useHotkey((dt) => zoomCamera(Math.pow(2, -dt / 300), size[0] / 2, size[1] / 2), "e", {}, true)

  const select = (area: Rectangle) => {
    const selected = props.items
      .filter(item => item.payload !== undefined && item.isSelected?.(area))
      .map(item => item.payload);
    if (selected.length === 0
      && area.left === area.right
      && area.top === area.bottom) {
      const target = getMouseOverItem(area.left, area.top);
      props.onClick(target?.payload);
    }
    else
      props.onSelect(selected as T[])
  };

  const [dragStart, setDragStart] = React.useState<[number, number] | undefined>();
  const [dragRect, setDragRect] = React.useState<Rectangle | undefined>();
  const [dragMode, setDragMode] = React.useState<"select" | "pan" | undefined>();

  const getSelectionArea = (x: number, y: number, inWorldSpace?: boolean): Rectangle | undefined => {
    if (inWorldSpace)
      [x, y] = getWorldPos(x, y)
    if (dragStart === undefined) return undefined;
    const [startX, startY] = inWorldSpace ? getWorldPos(...dragStart) : dragStart;
    return {
      left: Math.min(x, startX),
      right: Math.max(x, startX),
      top: Math.min(y, startY),
      bottom: Math.max(y, startY),
    }
  }

  React.useEffect(() => {
    const canvas = ref.current!;
    if (size[0] !== canvas.width || size[1] !== canvas.height)
      setSize([canvas.width, canvas.height]);

    const mainContext = prepareContext(ref, camera);
    const fowContext = prepareContext(fowRef, camera);
    const decoContext = prepareContext(decoRef, camera);
    if (!(mainContext && fowContext && decoContext)) return;

    fowContext.fillStyle = "#000";
    fowContext.fillRect(-1e9, -1e9, 2e9, 2e9);
    fowContext.globalCompositeOperation = "destination-out";
    fowContext.strokeStyle = "#fff";
    fowContext.fillStyle = "#fff";

    for (const item of props.items) {
      item.render(mainContext, fowContext, decoContext);
    }
    if (dragRect) {
      decoContext.resetTransform();
      decoContext.strokeStyle = "white";
      decoContext.lineWidth = 0.5;
      // decoContext.setLineDash([5, 5]);
      decoContext.beginPath();
      decoContext.rect(
        dragRect.left,
        dragRect.top,
        dragRect.right - dragRect.left,
        dragRect.bottom - dragRect.top);
      decoContext.stroke();
    }

    const onResize = () => setSize([canvas.clientWidth, canvas.clientHeight]);
    window.addEventListener("resize", onResize);
    return () => window.removeEventListener("resize", onResize);
  }, [ref, ref.current?.clientWidth, ref.current?.clientHeight,
    props.items, camera, size, dragRect]);

  return <div className="main-map"
    onContextMenu={e => { e.preventDefault(); handleClick(e, props.onRightClick); }}
    onMouseDown={e => {
      if (e.button === 1)
        setDragMode("pan")
      else if (e.button === 0) {
        setDragMode("select");
        setDragStart(getMousePos(e));
      }
    }}
    onMouseMove={e => {
      if (dragMode === "pan")
        moveCamera(e.movementX * devicePixelRatio, e.movementY * devicePixelRatio);
      else if (dragMode === "select")
        setDragRect(getSelectionArea(...getMousePos(e)));
    }}
    onMouseUp={e => {
      if (dragMode === "select") {
        const rect = getSelectionArea(...getMousePos(e), true);
        if (rect !== undefined)
          select(rect);
        setDragRect(undefined);
      }
      setDragMode(undefined);
    }}
    onWheel={e => zoomCamera(1 - Math.sign(e.deltaY) * 0.1, ...getMousePos(e))}
  >
    <canvas className="main-canvas"
      ref={ref}
    />
    <canvas className="fog-of-war" ref={fowRef} />
    <canvas className="map-decorations" ref={decoRef} />
  </div>;
}
