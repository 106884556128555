import { GameState } from "../../GameState";
import { getPlatoonsByPosition } from "../../Platoon";
import { getUnitStats } from "../../getUnitStats";
import _ from "lodash";
import { getNodeStatsFor } from "../../getNodeStats";
import { produce } from "immer";
import { tagify } from "../../../utils/utils";
import { isTown } from "../../Node";

export function applyHealing(gameState: GameState): GameState {
    const platoonsOnNodes = getPlatoonsByPosition(gameState.platoons).onNode;
    const healedPlatoons = platoonsOnNodes.entries().flatMap(([nodeTag, platoons]) => {
        const node = gameState.nodes[nodeTag];
        if (!isTown(node)) return [];
        const healableUnits = platoons.flatMap(platoon => {
            if (platoon.faction !== node.faction)
                return []
            return platoon.units.map(
                (unit, index) => {
                    const stats = getUnitStats(gameState, unit);
                    const missingHp = stats.maxHealth - unit.currentHealth;
                    const maxHealing = Math.min(stats.maxHealing, missingHp);
                    return { platoon: platoon.tag, index, maxHealing };
                })
        }).filter(x => x.maxHealing > 0);
        healableUnits.sort((a, b) => a.maxHealing - b.maxHealing);
        const nodeStats = getNodeStatsFor(gameState, node);
        let remainingHealing = nodeStats.healing;
        let remainingUnits = healableUnits.length;
        let healedUnits = [];
        for (const { maxHealing, platoon, index } of healableUnits) {
            const healingPerUnit = remainingHealing / remainingUnits;
            const healing = Math.min(healingPerUnit, maxHealing);
            remainingHealing -= healing;
            remainingUnits -= 1;
            healedUnits.push({ platoon, index, healing });
        }
        const healedUnitsByPlatoon = _.groupBy(healedUnits, "platoon");
        const newPlatoons = Object.entries(healedUnitsByPlatoon).map(([platoonTag, unitHealing]) =>
            produce(gameState.platoons[platoonTag]!, platoon => {
                for (const { index, healing } of unitHealing) {
                    platoon.units[index].currentHealth += healing;
                }
            })
        );
        return newPlatoons;
    });
    return {
        ...gameState,
        platoons: {
            ...gameState.platoons,
            ...tagify(healedPlatoons)
        }
    };
}
