import Utils from "../utils";
import { Resources, ResourceUtils } from "./Resources";

export type Modifier<T extends string> = {
    type: "flat" | "percentage" | "multiplier",
    property: T,
    value: number | Resources
};

export function flat<T extends string>(property: T, value: number | Resources):
    Modifier<T> & { type: "flat" } {
    return {
        type: "flat",
        property,
        value
    }
}

export function percentage<T extends string>(property: T, value: number | Resources):
    Modifier<T> & { type: "percentage" } {
    return {
        type: "percentage",
        property,
        value
    }
}

export function multiplier<T extends string>(property: T, value: number | Resources):
    Modifier<T> & { type: "multiplier" } {
    return {
        type: "multiplier",
        property,
        value
    }
}

export function applyModifiers(baseValue: Resources, aggregated: AggregatedModifiers | undefined): Resources
export function applyModifiers(baseValue: number, aggregated: AggregatedModifiers | undefined): number
export function applyModifiers(baseValue: number | Resources, aggregated: AggregatedModifiers | undefined): number | Resources {
    if (aggregated === undefined)
        return baseValue;

    const { flat, percentage, multiplier } = aggregated;

    if (typeof baseValue === "number") {
        if (typeof flat !== "number"
            || typeof percentage !== "number"
            || typeof multiplier !== "number") {
            throw new Error();
        }
        return (baseValue + flat)
            * (1 + percentage / 100)
            * multiplier;
    }
    else {
        let result = baseValue;

        if (flat === 0) { }
        else if (typeof flat === "number") {
            throw new Error();
        }
        else {
            result = ResourceUtils.add(result, flat);
        }

        const factor = multiply(percentageToFactor(percentage), multiplier);
        return multiply(result, factor);
    }
}

function percentageToFactor(percentage: number | Resources): number | Resources {
    if (typeof percentage === "number")
        return 1 + percentage / 100;
    return Utils.mapObject(percentage, x => (1 + x! / 100));
}

function multiply(a: number | Resources, b: number | Resources): number | Resources {
    if (typeof a === "number") {
        if (typeof b === "number")
            return a * b;
        else
            return ResourceUtils.scale(b, a);
    }
    else {
        if (typeof b === "number")
            return ResourceUtils.scale(a, b);
        else {
            const result = { ...a };
            for (const [k, v] of Object.entries(b)) {
                const a_k = result[k];
                if (a_k === undefined)
                    continue;
                else
                    result[k] = a_k * v!;
            }
            return result;
        }
    }
}

function add(a: number | Resources, b: number | Resources): number | Resources {
    if (typeof a === "number") {
        if (typeof b === "number")
            return a + b;
        else if (a === 0)
            return b;
        else
            throw new Error("Cannot add numbers and resources together!");
    }
    else {
        if (typeof b === "number") {
            if (b === 0)
                return a;
            else
                throw new Error("Cannot add numbers and resources together!");
        }
        else {
            const result = { ...a };
            for (const [k, v] of Object.entries(b)) {
                result[k] = (result[k] ?? 0) + v!;
            }
            return result;
        }
    }
}

export type AggregatedModifiers = {
    flat: number | Resources,
    percentage: number | Resources,
    multiplier: number | Resources
}

export type FromTechnology = {
    type: "fromTechnology",
    technology: string
}
export type FromSlotFeature = {
    type: "fromSlotFeature",
    feature: string
}
export type FromNodeFeature = {
    type: "fromNodeFeature",
    feature: string,
}
export type FromBuilding = {
    type: "fromBuilding",
    buildingType: string,
    slot: number
}
export type FromJobs = {
    type: "fromJobs",
    assignedJobs: number,
    maxJobs: number,
}
export type FromOtherStat = {
    type: "fromOtherStat",
    stat: string
}
export type FromUnitLevel = {
    type: "fromUnitLevel"
}
export type ModifierSource =
    | FromTechnology
    | FromSlotFeature
    | FromBuilding
    | FromNodeFeature
    | FromJobs
    | FromOtherStat
    | FromUnitLevel
export type ModifierWithSource<T extends string> = Modifier<T> & { source: ModifierSource }
/** Small helper function that adds the same source to all given modifiers. */
export function addModifierSource<T extends string>(modifiers: Modifier<T>[] | undefined, source: ModifierSource, scaling?: number): ModifierWithSource<T>[] {
    if (modifiers === undefined) return []
    if (scaling !== undefined)
        return modifiers.map((mod): ModifierWithSource<T> => ({ ...mod, value: multiply(mod.value, scaling), source }));
    return modifiers.map((mod): ModifierWithSource<T> => ({ ...mod, source }));
}
export function scaleModifier<T extends Modifier<string>>(mod: T, scaling: number | undefined): T {
    if (scaling === undefined)
        return mod;
    if (mod.type === "multiplier") {
        // Multiplicative modifiers should scale from 1, not from 0.
        // E.g. if a node is 10% malnourished and 100% malnourishment means 0.1x healing speed,
        // then the healing should not be 0.01x, but rather 0.91x
        if (typeof mod.value === "number") {
            const value = (mod.value - 1) * scaling + 1;
            return { ...mod, value };
        }
        else {
            const value: Resources = {};
            for (const resource in mod.value) {
                value[resource] = ((mod.value[resource] ?? 1) - 1) * scaling + 1;
            }
            return { ...mod, value };
        }
    }
    return { ...mod, value: multiply(mod.value, scaling) };
}


export type StatInfo<T extends string> = {
    isDefault: boolean,
    base: number | Resources,
    flatBoni: ModifierWithSource<T>[],
    percentBoni: ModifierWithSource<T>[],
    multiplierBoni: ModifierWithSource<T>[]
}

export function reduceAdd<T extends Modifier<string>>(modifiers: T[]) {
    return modifiers.reduce((a, b) => add(a, b.value), 0 as Resources | number);
}
export function reduceMultiply<T extends Modifier<string>>(modifiers: T[]) {
    return modifiers.reduce((a, b) => multiply(a, b.value), 1 as Resources | number);
}
