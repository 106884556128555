import { Faction } from "../Faction";
import { GameState } from "../GameState";
import { ResourceUtils } from "../Resources";
import validateIsOwnTown from "./_validation/validateIsOwnTown";

export interface CancelTrainingCommand {
    type: "cancelTrainingCommand";
    node: string;
    queueIndex: number;
}
export function applyCancelTrainingCommand(gameState: GameState, command: CancelTrainingCommand, sender: Faction): GameState {
    let resources = gameState.resources;
    const node = validateIsOwnTown(gameState, command.node, sender);
    if (node === undefined) return gameState;
    if (node.trainingQueue === undefined) {
        console.error(`Can't cancel training: The target node '${command.node}' does not have a training queue.`);
        return gameState;
    }
    const trainingQueue = [...node.trainingQueue];
    const [removedUnit] = trainingQueue.splice(command.queueIndex, 1);
    resources = ResourceUtils.add(resources, removedUnit.deliveredResources);
    return {
        ...gameState, nodes: {
            ...gameState.nodes, [node.tag]: {
                ...node, trainingQueue, population: node.population + removedUnit.conscriptedManpower
            }
        }, resources
    };
}
