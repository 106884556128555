import Utils from "../../utils";
import { Faction } from "../Faction";
import { GameState } from "../GameState";
import { isTown, Town } from "../Node";
import { Platoon } from "../Platoon";

export interface SettleCommand {
    type: "settleCommand";
    platoon: string;
    unit: number;
    settlementName: string;
}
export function applySettleCommand(gameState: GameState, command: SettleCommand, sender: Faction): GameState {
    const platoon = gameState.platoons[command.platoon];
    if(platoon === undefined) {
        console.warn(`Invalid command: The platoon ${platoon} ceased to exist.`);
        return gameState;
    }
    if(platoon.faction !== sender) {
        console.warn(`Invalid command: Tried settling with unit ${command.platoon}#${command.unit} of faction ${platoon.faction}, which is not friendly.`);
    }
    const unit = platoon.units[command.unit];
    const unitType = gameState.unitTypes[unit.kind];
    if (!unitType.features.includes("settler")) {
        console.warn(`Invalid command: Tried settling with unit ${command.platoon}#${command.unit} of type ${unit.kind}, which can not settle.`);
        return gameState;
    }
    if (platoon.position.type !== "onNode") {
        console.warn(`Invalid command: Tried settling with unit ${command.platoon}#${command.unit}, which is not positioned on a node.`);
        return gameState;
    }
    const node = gameState.nodes[platoon.position.node];
    if (isTown(node)) {
        console.warn(`Invalid command: Tried settling with unit ${command.platoon}#${command.unit} on node ${node.tag}, which is already a town.`);
        return gameState;
    }
    const town: Town = {
        ...node,
        name: command.settlementName,
        description: "",
        founded: gameState.time,
        population: 10,
        faction: sender
    }
    const newPlatoon: Platoon = {
        ...platoon,
        units: Utils.removeIndex(platoon.units, command.unit)
    }
    const newPlatoons = { ...gameState.platoons, [newPlatoon.tag]: newPlatoon };
    if (newPlatoon.units.length === 0) {
        delete newPlatoons[platoon.tag];
    }
    return {
        ...gameState, 
        platoons: newPlatoons, 
        nodes: {
            ...gameState.nodes, 
            [town.tag]: town
        }
    };
}
