import weakMemoize from "../utils/weakMemoize";
import _ from "lodash";
import { PlatoonPosition } from "./Platoon";
import { TagMap } from "../utils/utils";

export interface Edge {
    tag: string,
    nodes: [string, string];
    length: number;
}

export interface HalfEdge {
    source: string;
    target: string;
    length: number;
    edgeTag: string;
    direction: 1 | -1
}

export type AdjacencyMap = { readonly [node in string]?: readonly HalfEdge[] }

export const getAdjacencyMap = weakMemoize(computeAdjacencyMap);

function computeAdjacencyMap(edges: TagMap<Edge>): AdjacencyMap {
    const adjacencyMap: { [node in string]?: HalfEdge[] } = {};
    const addHalfEdge = (halfEdge: HalfEdge) => {
        const list = adjacencyMap[halfEdge.source];
        if (list === undefined)
            adjacencyMap[halfEdge.source] = [halfEdge];

        else
            list.push(halfEdge);
    };
    for (const { nodes: [a, b], length, tag } of Object.values(edges)) {
        addHalfEdge({ source: a, target: b, length, edgeTag: tag, direction: +1 });
        addHalfEdge({ source: b, target: a, length, edgeTag: tag, direction: -1 });
    }

    for (const array of Object.values(adjacencyMap)) {
        Object.freeze(array);
    }
    return Object.freeze(adjacencyMap);
}

interface AStarNode {
    node: string;
    predecessor?: AStarNode;
    costs: number;
}

export function dijkstra(edges: TagMap<Edge>, start: PlatoonPosition, end: string | ((node: string) => boolean)): string[] | undefined {
    const isEnd = typeof end === "function" ? end : (x: string) => x === end;
    if (start.type === "onEdge") {
        const edge = edges[start.edge];
        const [a, b] = edge.nodes;
        const startingNodes = [
            { node: a, costs: start.position * edge.length },
            { node: b, costs: (1 - start.position) * edge.length }
        ];
        return dijkstraInternal(edges, startingNodes, isEnd);
    }
    else if (start.type === "onNode") {
        return dijkstraInternal(edges, [{ node: start.node, costs: 0 }], isEnd);
    }
}

function dijkstraInternal(edges: TagMap<Edge>, start: { node: string; costs: number; }[], isEnd: ((node: string) => boolean)): string[] | undefined {
    const open = new Map<string, AStarNode>();
    for (const startNode of start) {
        open.set(startNode.node, startNode);
    }

    const closed = new Set();
    const adjacencyMap = getAdjacencyMap(edges);
    while (open.size > 0) {
        const [currentKey, current] = _.minBy([...open.entries()], ([k, v]) => v)!;
        open.delete(currentKey);

        if (isEnd(currentKey)) {
            const result = [];
            let predecessor: AStarNode | undefined = current;
            while (predecessor !== undefined) {
                result.push(predecessor.node);
                predecessor = predecessor.predecessor;
            }
            return result.reverse();
        }

        for (const { target, length } of adjacencyMap[currentKey] ?? []) {
            if (closed.has(target))
                continue;
            const costs = current.costs + length;
            const oldCosts = open.get(target)?.costs;
            if (oldCosts === undefined || costs < oldCosts)
                open.set(target, { node: target, predecessor: current, costs });
        }
    }
    return undefined;
}

