import React from 'react';
import { Box, Button, Checkbox, Icon, IconButton, LinearProgress, Paper, Popper, TextField, Typography } from '@material-ui/core';
import { Platoon, requiredXp } from '../logic/Platoon';
import { useGameState } from './useGameState';
import InfoBox from './InfoBox';
import { isTown } from '../logic/Node';
import { Cancel, Check, CheckBoxOutlined } from '@material-ui/icons';
import "./PlatoonUi.css"
import { UnitIcon } from './Icons';
import Utils from '../utils';
import { generateDwarfTownName } from '../logic/mapGeneration/generateNames';
import { StatsUi } from './StatsUi';
import { getUnitStats } from '../logic/getUnitStats';
import { SelectContext } from './GameScreen';
import { SubmitCommandContext } from './SaveGame';

const PlatoonInfoBox = new InfoBox<number>();

const getSelectedIndices = (unitSelection: boolean[]) => (unitSelection?.map((x, i) => x ? i : undefined).filter(x => x !== undefined) as number[]) ?? [];
export function PlatoonUi(props: { platoon: Platoon }) {
  const submitCommand = React.useContext(SubmitCommandContext);
  const select = React.useContext(SelectContext);
  const [unitSelection, setUnitSelection] = React.useState<boolean[] | undefined>(undefined);
  return <PlatoonInfoBox.Component
    key={props.platoon.tag}
    selectionUi={unitIndex => <DetailsUi platoon={props.platoon} index={unitIndex} />}
    className={`platoon-box faction-${props.platoon.faction}`}
  >
    <Typography variant="h3" className="platoon-title">{props.platoon.faction === "player" ? "Eigener" : "Gegnerischer"} Trupp</Typography>
    <div className="platoon-units" style={{ display: "grid", gridTemplateColumns: "auto 1fr" }}>
      {unitSelection !== undefined ||
        <Button variant="outlined" color="primary" onClick={() => setUnitSelection(new Array(props.platoon.units.length).fill(false))}>
          <CheckBoxOutlined />
        </Button>
      }
      {unitSelection === undefined || <span>
        {/** Cancel selection button */}
        <Button variant="outlined" color="primary" onClick={() => setUnitSelection(x => undefined)}>
          <Cancel />
        </Button>

        {/** Select All button */}
        <Button variant="outlined" color="primary" onClick={() => {
          const allSelected = !unitSelection.some(x => !x);
          setUnitSelection(unitSelection.map(x => !allSelected));
        }}>
          <Icon className="fas fa-check-square" />
        </Button>

        {/** Split button */}
        <Button variant="outlined" color="primary" onClick={() => {
          const targetPlatoon = Utils.getUnusedTag("p");
          select({ type: "platoon", tag: targetPlatoon });
          submitCommand({
            type: "transferUnitsCommand",
            sourcePlatoon: props.platoon.tag,
            targetPlatoon,
            units: getSelectedIndices(unitSelection)
          });
        }}>
          <Icon className="fa fa-expand-alt" />
        </Button>

        {/** Disband button */}
        <Button variant="outlined" color="primary" onClick={() => {
          setUnitSelection(x => undefined);
          submitCommand({
            type: "disbandCommand",
            platoon: props.platoon.tag,
            unit: getSelectedIndices(unitSelection)
          });
        }}>
          <Icon className="fas fa-users-slash" />
        </Button>
      </span>}
      <Typography variant="h4">Einheiten</Typography>
    </div>
    <div className="platoon-unit-list">
      {
        props.platoon.units.map((unit, index) => <UnitUi key={index}
          platoon={props.platoon}
          index={index}
          checkState={unitSelection === undefined ? undefined : unitSelection[index]}
          setCheckState={checked => unitSelection === undefined || setUnitSelection(Utils.replaceIndexWith(unitSelection, index, checked))}
        />)
      }
    </div>

    {/* TODO: this is debug UI */}
    {/* <br />
      Pfad: {props.platoon.path.join(" -> ")} */}
  </PlatoonInfoBox.Component>;
}

function UnitUi(props: { platoon: Platoon, index: number, checkState: boolean | undefined, setCheckState: (newValue: boolean) => void }) {
  const unit = props.platoon.units[props.index];
  const toggleSelection = React.useContext(PlatoonInfoBox.ToggleLocalSelection);
  const selection = React.useContext(PlatoonInfoBox.LocalSelection);
  const type = useGameState(gs => gs.unitTypes[unit.kind]);
  const stats = useGameState(gs => getUnitStats(gs, unit));

  return <Paper variant={selection === props.index ? "elevation" : "outlined"}>
    <div onClick={() => toggleSelection(props.index)}>
      {props.checkState === undefined || <Checkbox color="primary" checked={props.checkState} onChange={e => props.setCheckState(e.target.checked)} />}
      <UnitIcon unitType={type} />
      {type.name} (<HealthDisplay hp={unit.currentHealth} maxHp={stats.maxHealth} />)
    </div>
  </Paper>
}

function HealthDisplay(props: { hp: number, maxHp: number }) {
  return <span>
    {Math.floor(props.hp)}/{props.maxHp}&nbsp;HP
  </span>
}

function DetailsUi(props: { platoon: Platoon, index: number }) {
  return <UnitDetailsUi platoon={props.platoon} index={props.index} />
}

function UnitDetailsUi(props: { platoon: Platoon, index: number }) {
  const toggleSelection = React.useContext(PlatoonInfoBox.ToggleLocalSelection);
  const unit = props.platoon.units[props.index];
  const type = useGameState(gs => gs.unitTypes[unit.kind]);
  const stats = useGameState(gs => getUnitStats(gs, unit));
  if (unit === undefined) {
    toggleSelection(undefined);
    return <></>
  }
  const additionalChildren = [];
  if (type.features.includes("settler")) {
    additionalChildren.push(<SettleButton {...props} />);
  }
  return <div className="platoon-unit-details">
    <div className="platoon-unit-details-title">
      <UnitIcon unitType={type} />
      <Typography variant="h4">
        {type.name}
      </Typography>
    </div>
    <div>{type.description}</div>
    <div>
      Level {unit.level} <LinearProgress variant="determinate" value={unit.experience / requiredXp(unit.level) * 100} />
    </div>
    <div>
      <StatsUi stats={stats} keys={["meleeAttack", "defense", "moveSpeed"]} />
      <Box display="flex" alignItems="center">
        <Box width="100%"><LinearProgress variant="determinate" value={unit.currentHealth / stats.maxHealth * 100} /></Box>
        <Box><HealthDisplay hp={unit.currentHealth} maxHp={stats.maxHealth} /></Box>
      </Box>
    </div>
    {additionalChildren}
  </div>
}

function SettleButton(props: { platoon: Platoon, index: number }) {
  const enabled = useGameState(gs => {
    const position = props.platoon.position;
    if (position.type !== "onNode")
      return false;
    const node = gs.nodes[position.node];
    if (isTown(node))
      return false;
    return true;
  });

  const [popupOpen, setPopupOpen] = React.useState(false);
  const [anchor, setAnchor] = React.useState<HTMLButtonElement | undefined>();
  const [settlementName, setSettlementName] = React.useState("");
  const submitCommand = React.useContext(SubmitCommandContext);

  return <>
    <Button disabled={!enabled} onClick={e => {
      setAnchor(e.currentTarget);
      setPopupOpen(x => !x);
      setSettlementName(generateDwarfTownName())
    }}>
      Siedeln
    </Button>
    <Popper open={popupOpen} anchorEl={anchor}>
      <Paper>
        <form onSubmit={() => {
          setPopupOpen(false);
          submitCommand({
            type: "settleCommand",
            platoon: props.platoon.tag,
            settlementName: settlementName,
            unit: props.index
          });
        }}>
          <TextField variant="outlined" label="Name der neuen Stadt" defaultValue={settlementName} autoFocus onChange={e => setSettlementName(e.target.value)} error={settlementName.trim().length === 0} />
          <IconButton type="submit">
            <Check />
          </IconButton>
        </form>
      </Paper>
    </Popper>
  </>;
}
