import { GameState } from "../../GameState";
import { ResourceUtils } from "../../Resources";
import { isAvailable } from "../../Technology";
import { produce } from "immer";
import { getSlotStatsFor } from "../../getSlotStats";

export function updateConstructionSites(gameState: GameState) {
    const constructionSites = Object.values(gameState.nodes)
        .flatMap(node => node.buildSlots.map((slot, i) => (slot.site.type === "construction" ? { nodeTag: node.tag, slotIndex: i } : undefined)))
    gameState = produce(gameState, gameState => {
        for (const _site of constructionSites) {
            if (_site === undefined) continue;
            const node = gameState.nodes[_site.nodeTag];
            const slot = node.buildSlots[_site.slotIndex];
            if (slot.site.type !== "construction") continue; // need this guard to make typescript happy :-/
            if (!slot.site.active) continue;
            const buildingType = gameState.buildingTypes[slot.site.kind];
            const stats = getSlotStatsFor(gameState, _site);
            const { buildTime, buildCosts } = stats;

            const gatherResult = ResourceUtils.gather(gameState.resources, slot.site.resources, buildCosts);
            gameState.resources = gatherResult.newSourceStockpile;
            slot.site.resources = gatherResult.newTargetStockpile;

            // TODO: more sophisticated speed
            const availability = isAvailable(buildingType.requiredTech, gameState.research);
            if (availability !== "available") // stall construction if technology is unavailable
                continue;
            const maxProgress = ResourceUtils.maxProgress(slot.site.resources, buildCosts);
            const newProgress = Math.min(slot.site.progress + 1 / buildTime, maxProgress);
            if (newProgress >= 1)
                slot.site = { type: "building", kind: slot.site.kind, active: true, assignedJobs: 0, priority: 0 };
            else
                slot.site.progress = newProgress;
        }
    });
    return gameState;
}
