import _ from "lodash";
import { applyModifiers, ModifierWithSource, reduceAdd, reduceMultiply, StatInfo } from "./Modifiers";
import { Resources } from "./Resources";


export type StatDefaults = { [key in string]: number | Resources };
export type Stats<T extends StatDefaults> = {
    +readonly [key in keyof T]: T[key];
} & {
    getDetails(stat: (keyof T & string)): StatInfo<keyof T & string>;
};
type StatsBuilder<T extends StatDefaults> = Stats<T> & {
    freeze(): Stats<T>;
    addModifier(mod: ModifierWithSource<(keyof T & string)>): void;
    addModifiers(mod: ModifierWithSource<(keyof T & string)>[]): void;
}

export default function makeStatsBuilder<T extends StatDefaults>(defaults: T) {
    class StatsProvider {
        readonly #mods = new Map<string, ModifierWithSource<(keyof T & string)>[]>();
        readonly #details = new Map<string, StatInfo<(keyof T & string)>>();
        #type: Partial<T> | undefined;
        constructor(unitType: Partial<T> | undefined) {
            this.#type = unitType;
        }
        public addModifier(mod: ModifierWithSource<keyof T & string>) {
            if(this.#details.has(mod.property))
                throw new Error(`Modifiers for ${mod.property} have already been accessed, cannot alter them.`);
            const list = this.#mods.get(mod.property);
            
            if (list === undefined)
                this.#mods.set(mod.property, [mod])
            else
                list.push(mod);
        }
        public addModifiers(mods: ModifierWithSource<keyof T & string>[]) {
            for (const mod of mods) {
                this.addModifier(mod);
            }
        }

        public get(stat: (keyof T & string)): Resources | number {
            const details = this.getDetails(stat);
            const aggregated = {
                flat: reduceAdd(details.flatBoni),
                percentage: reduceAdd(details.percentBoni),
                multiplier: reduceMultiply(details.multiplierBoni),
            }
            return applyModifiers(details.base as any, aggregated);
        }
        public getDetails(stat: (keyof T & string)): StatInfo<keyof T & string> {
            const details = this.#details.get(stat);
            if(details !== undefined) return details;

            const mods = this.#mods.get(stat);
            const grouped = _.groupBy(mods, x => x.type);
            return {
                isDefault: this.#type?.[stat] === undefined,
                base: this.#type?.[stat] ?? defaults[stat],
                flatBoni: grouped.flat ?? [],
                percentBoni: grouped.percentage ?? [],
                multiplierBoni: grouped.multiplier ?? [],
            }
        }
        public freeze() { 
            this.addModifier = () => console.log("Cannot add modifier to frozen stat object!"); 
            return this;
        }
    }
    for (const key of Object.keys(defaults)) {
        Object.defineProperty(StatsProvider.prototype, key, {
            // eslint-disable-next-line no-new-func
            get: new Function(`return this.${key} = this.get("${key}");`) as () => any
        });
    }
    return (type: Partial<T> | undefined) => new StatsProvider(type) as unknown as StatsBuilder<T>;
}
