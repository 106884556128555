import _, { slice } from "lodash";
import { random } from "lodash";
import { Edge } from "../Edge";
import { GameMap } from "../GameState";
import { generateBuildslots } from "./generateBuildslots";
import { generateDelaunayEdges } from "./generateDelaunayEdges";
import { generatePlatoons } from "./generatePlatoons";
import { AbandonedStructures, Biome, Junction, building, Node, Town, emptySlot } from "../Node";
import { Platoon } from "../Platoon";
import { generateOrcTownName } from "./generateNames";
import Utils from "../../utils";

export interface OrkifiedJunction extends Junction {
    orcification: number;
}

export function generateMap(seed: number): GameMap {
    //TODO: use seed
    let playerPosition: [number,number] = [-50, 800]
    let biomeCenters = generateBiomeCenters(4, 2000, 2000, 3, 3, 3, 3);
    console.log(biomeCenters);
    let junctions = generateNodesViaGrid(20, 50, 110, 1);
    junctions = calculateNodeFeatures(junctions, 500, biomeCenters, playerPosition, 300);
    generateSpecialNodes(junctions,4,4,4);
    generateBuildslots(junctions);
    let platoons: Platoon[] = generatePlatoons(junctions);
    // Add starting city
    let nodes: Node[] = generateOrcSettlements(junctions);
    nodes.push(
        {
            tag: "hb",
            name: "Hoyborg",
            description: "Die Letzte Zuflucht der Menschen und Zwerge",
            founded: 5327 * 365,
            population: 70,
            position: playerPosition,
            biome: Biome.Standard,
            buildSlots: [
                building("house"),
                building("house"),
                building("house"),
                building("barracks"),
                building("farm"),
                building("farm"),
                building("blacksmith"),
                building("workshop"),
                building("fishery",["lake"]),
                building("apothecary"),
                { features: [], site: { type: "rubble", resources: { stone: 100 } } },
                { features: [], site: { type: "rubble", resources: { stone: 100 } } },
                { features: [], site: { type: "rubble", resources: { stone: 100 } } },
                { features: [], site: { type: "rubble", resources: { stone: 100 } } },
                { features: [], site: { type: "rubble", resources: { stone: 50, iron: 50 } } },
                { features: ["hardRock"], site: { type: "rubble", resources: { stone: 100 } } },
                { features: ["hardRock"], site: { type: "rubble", resources: { stone: 100 } } },
                { features: ["hardRock"], site: { type: "rubble", resources: { stone: 50, iron: 50 } } },                
                { features: ["veryHardRock"], site: { type: "rubble", resources: { stone: 100 } } },
                { features: ["veryHardRock"], site: { type: "rubble", resources: { stone: 100 } } },
                { features: ["veryHardRock"], site: { type: "rubble", resources: { stone: 50, iron: 50 } } },
            ],
            faction: "player",
            features: {}
        });
    nodes.push(
        {
            tag: "ghb",
            name: "Die Tore von Hoyborg",
            description: "Alleine hier werden gelegentlich gefährliche Expeditionen zur Oberfläche gewagt.",
            founded: 5327 * 365,
            population: 30,
            position: [-150, 800],
            biome: Biome.Standard,
            buildSlots: [
                building("lumberHut"),
                building("lumberHut"),
                building("huntersHut"),
                building("huntersHut"),
                building("huntersHut"),
                building("charcoalBurnery"),
                building("charcoalBurnery"),
                building("house"),
                building("house"),
                building("house"),
            ],
            faction: "player",
            features: { "exit": {} }
        });
    nodes.push(
        {
            tag: "s1",
            position: [30, 740],
            biome: Biome.Standard,
            buildSlots: [
                { features: [], site: { type: "rubble", resources: { stone: 100 } } },
                { features: [], site: { type: "rubble", resources: { stone: 100 } } },
                { features: [], site: { type: "rubble", resources: { stone: 100 } } },
                { features: [], site: { type: "rubble", resources: { stone: 100 } } },
                { features: [], site: { type: "rubble", resources: { stone: 50, iron: 50 } } },
                { features: [], site: { type: "rubble", resources: { stone: 500, iron: 50 } } },
                { features: [], site: { type: "rubble", resources: { stone: 100 } } },
                { features: [], site: { type: "rubble", resources: { stone: 100 } } }
            ],
            features: {}
        });
    nodes.push(
        {
            tag: "s2",
            position: [20, 870],
            biome: Biome.Standard,
            buildSlots: [
                { features: [], site: { type: "rubble", resources: { stone: 100 } } },
                { features: [], site: { type: "rubble", resources: { stone: 100 } } },
                { features: [], site: { type: "rubble", resources: { stone: 100 } } },
                { features: [], site: { type: "rubble", resources: { stone: 100 } } },
                { features: [], site: { type: "rubble", resources: { stone: 50, iron: 50 } } },
                { features: [], site: { type: "rubble", resources: { stone: 100 } } },
                { features: [], site: { type: "rubble", resources: { stone: 100 } } }
            ],
            features: {}
        });
    nodes.push(
        {
            tag: "s3",
            position: [90, 810],
            biome: Biome.Standard,
            buildSlots: [
                { features: [], site: { type: "rubble", resources: { stone: 100 } } },
                { features: [], site: { type: "rubble", resources: { stone: 100 } } },
                { features: [], site: { type: "rubble", resources: { stone: 100, iron: 100 } } },
                { features: [], site: { type: "rubble", resources: { stone: 100 } } },
                { features: [], site: { type: "rubble", resources: { stone: 50, iron: 50 } } },
                { features: [], site: { type: "rubble", resources: { stone: 100, iron: 50 } } },
                { features: [], site: { type: "rubble", resources: { stone: 100 } } }
            ],
            features: {}
        });
    nodes.push(
        {
            tag: "s4",
            position: [100, 710],
            biome: Biome.Standard,
            buildSlots: [
                { features: [], site: { type: "rubble", resources: { stone: 100 } } },
                { features: [], site: { type: "rubble", resources: { stone: 100 } } },
                { features: [], site: { type: "rubble", resources: { stone: 100, iron: 100 } } },
                { features: [], site: { type: "rubble", resources: { stone: 100 } } },
                { features: [], site: { type: "rubble", resources: { stone: 50, iron: 50 } } },
                { features: [], site: { type: "rubble", resources: { stone: 100, iron: 50 } } },
                { features: [], site: { type: "rubble", resources: { stone: 100 } } }
            ],
            features: {}
        });
    nodes.push(
        {
            tag: "s5",
            position: [90, 910],
            biome: Biome.Standard,
            buildSlots: [
                { features: [], site: { type: "rubble", resources: { stone: 100 } } },
                { features: [], site: { type: "rubble", resources: { stone: 100 } } },
                { features: [], site: { type: "rubble", resources: { stone: 100, iron: 100 } } },
                { features: [], site: { type: "rubble", resources: { stone: 100 } } },
                { features: [], site: { type: "rubble", resources: { stone: 50, iron: 50 } } },
                { features: [], site: { type: "rubble", resources: { stone: 100, iron: 50 } } },
                { features: [], site: { type: "rubble", resources: { stone: 100 } } }
            ],
            features: {}
        });
    const rawNodePositions = nodes.map(node => node.position);
    let edges: Edge[];
    // edges = generateTotalEdges(initialnodeArray);
    edges = generateDelaunayEdges(rawNodePositions, nodes);
    let minTreeEdges = generateMinimalTree(nodes, edges);
    minTreeEdges = addRandomEdges(minTreeEdges, edges, 0.1, 300);
    return {
        nodes: Utils.tagify(nodes),
        edges: Utils.tagify(minTreeEdges),
        platoons: Utils.tagify(platoons),
        battles: {},
        heroes: {}
    }
}

function generateOrcSettlements(nodes: OrkifiedJunction[]) {
    return nodes.map((node): OrkifiedJunction | Town => {
        if (node.orcification < 1) return node;
        return {
            ...node,
            name: generateOrcTownName(),
            description: "",
            faction: "enemy",
            founded: _.random(3655, 5327, true) * 365,
            population: node.orcification * _.random(15, 25, true),
        }
    });
}

function generateMinimalTree(nodes: Node[], edges: Edge[]): Edge[] {
    let sortedEdgeArray = _.sortBy(edges, e => e.length);
    let minTree: Edge[] = [];
    let nodeMarkingMap: { [nodeTag: string]: boolean } = {};
    nodes.forEach(element => {
        nodeMarkingMap[element.tag] = false;
    });
    let i: number = 0;
    while (i < sortedEdgeArray.length) {
        if (!(nodeMarkingMap[sortedEdgeArray[i].nodes[0]] && nodeMarkingMap[sortedEdgeArray[i].nodes[1]])) {
            nodeMarkingMap[sortedEdgeArray[i].nodes[0]] = true;
            nodeMarkingMap[sortedEdgeArray[i].nodes[1]] = true;
            minTree.push(sortedEdgeArray[i]);
        } else {
            if (!edgeWouldCyclifyGraph(sortedEdgeArray[i], minTree)) {
                minTree.push(sortedEdgeArray[i]);
            }
        }
        i++;
    }
    return minTree;
}

export function generateLinkedGraphFromEdges(edges: Edge[]): { [nodeTag: string]: string[] } {
    let linkedGraph: { [nodeTag: string]: string[] } = {};
    for (const edge of edges) {
        linkedGraph[edge.nodes[0]] = [];
        linkedGraph[edge.nodes[1]] = [];
    }
    for (const edge of edges) {
        linkedGraph[edge.nodes[0]].push(edge.nodes[1]);
        linkedGraph[edge.nodes[1]].push(edge.nodes[0]);
    }
    return linkedGraph;
}

/** returns true if node is part of a component containing a cycle */
export function isInCyclicalComponent(linkedGraph: { [nodeTag: string]: string[] }, currentNode: string, visitedNodes?: Set<string>, parent?: string): boolean {
    visitedNodes = visitedNodes ?? new Set();
    if (visitedNodes.has(currentNode)) {
        return true;
    }
    visitedNodes.add(currentNode);
    for (const neighbour of linkedGraph[currentNode]) {
        if (!(neighbour === parent)) {
            if (isInCyclicalComponent(linkedGraph, neighbour, visitedNodes, currentNode)) {
                return true;
            }
        }
    }
    return false;
}

//returns true if, after adding the edge to the graph, the graph component containing the edge contains a cycle
function edgeWouldCyclifyGraph(edge: Edge, graph: Edge[]): boolean {
    graph = [...graph, edge]
    let linkedGraph = generateLinkedGraphFromEdges(graph);
    let b = isInCyclicalComponent(linkedGraph, edge.nodes[0]);

    return b;
}

function addRandomEdges(necessaryEdges: Edge[], potentialEdges: Edge[], edgeChance: number, maxDistance: number): Edge[] {
    for (const potentialEdge of potentialEdges) {
        if (!necessaryEdges.includes(potentialEdge)) {
            let rando = Math.random();
            if (rando < edgeChance) {
                if (potentialEdge.length < maxDistance) {
                    necessaryEdges.push(potentialEdge);
                }
            }
        }
    }
    return necessaryEdges;
}

/** Generates a grid of gridSize x (gridSize*gridAspectRatio) nodes. The position of nodes within a grid cell is random.
 * The minumum distance between nodes is determined by gridCellBoundarySize */
function generateNodesViaGrid(gridSize: number, gridcellBoundarySize: number, gridcellSize: number, gridAspectRatio: number): OrkifiedJunction[] {
    let habitableGridCellSize: number = gridcellSize - gridcellBoundarySize;
    let MapZeroX: number = 100;
    let MapZeroY: number = 50;
    let nodes: OrkifiedJunction[] = [];
    let rows: number = gridSize;
    let columns: number = gridSize * gridAspectRatio;
    let currentRow = 0;
    while (currentRow < rows) {
        let currentColumn: number = 0
        while (currentColumn < columns) {
            let randoX: number = Math.random();
            let randoY: number = Math.random();
            let posX: number = MapZeroX + (currentColumn * gridcellSize) + gridcellBoundarySize + (randoX * habitableGridCellSize);
            let posy: number = MapZeroY + (currentRow * gridcellSize) + gridcellBoundarySize + (randoY * habitableGridCellSize);
            let nodeTag: string = "c" + currentColumn + "r" + currentRow
            nodes.push({ tag: nodeTag, position: [posX, posy], buildSlots: [], biome: Biome.Standard, orcification: 0, features: {} });
            currentColumn++;
        }
        currentRow++;
    }
    return nodes;
}

export interface BiomeCenters {
    VolcanicCenters: [number, number][],
    AquaticCenters: [number, number][],
    PopulationCenters: [number, number][],
    OrcCenters: [number, number][]
}

function generateBiomeCenters(size: number, mapLength: number, mapHeight: number, volcanicCenters: number, aquaticCenters: number, populationCenters: number, orcCenters: number): BiomeCenters {
    let biomeCenters: BiomeCenters = { VolcanicCenters: [], AquaticCenters: [], PopulationCenters: [], OrcCenters: [] };

    //generate VolcanicCenters
    let PotentialBiomeCenters: [number, number][] = generatePotentialBiomeCenters(size, mapLength, mapHeight);
    PotentialBiomeCenters = _.shuffle(PotentialBiomeCenters);

    biomeCenters.VolcanicCenters = PotentialBiomeCenters.splice(0, volcanicCenters);
    biomeCenters.AquaticCenters = PotentialBiomeCenters.splice(0, aquaticCenters);

    PotentialBiomeCenters = generatePotentialBiomeCenters(size, mapLength, mapHeight);
    PotentialBiomeCenters = _.shuffle(PotentialBiomeCenters);

    biomeCenters.PopulationCenters = PotentialBiomeCenters.splice(0, populationCenters);
    biomeCenters.OrcCenters = PotentialBiomeCenters.splice(0, orcCenters);

    return biomeCenters;
}

function generatePotentialBiomeCenters(size: number, mapLength: number, mapHeight: number): [number, number][] {
    let PotentialBiomeCenters: [number, number][] = [];
    //generate grid Corners
    for (let x = 0; x < size; x++) {
        for (let y = 0; y < size; y++) {
            const cornerX = (x / size) * mapLength;
            const cornerY = (y / size) * mapHeight;
            let randoX: number = Math.random();
            let randoY: number = Math.random();
            PotentialBiomeCenters.push([cornerX + (randoX * mapLength / size), cornerY + (randoY * mapHeight / size)]);
        }
    }
    return PotentialBiomeCenters;
}

function calculateNodeFeatures(nodes: OrkifiedJunction[], biomeRadius: number, biomeCenters: { VolcanicCenters: [number, number][], AquaticCenters: [number, number][], PopulationCenters: [number, number][], OrcCenters: [number, number][] }, playerPosition: [number,number], safeDistance : number): OrkifiedJunction[] {
    for (const node of nodes) {
        node.biome = calculateNodeBiome(node, biomeRadius, biomeCenters);
        node.abandonedStructure = calculateNodeAbandonedStructures(node, biomeRadius, biomeCenters);
        node.orcification = calculateNodeOrcification(node, biomeRadius, biomeCenters, playerPosition, safeDistance);
    }
    return nodes
}

function calculateNodeBiome(node: OrkifiedJunction, biomeRadius: number, biomeCenters: { VolcanicCenters: [number, number][], AquaticCenters: [number, number][], PopulationCenters: [number, number][], OrcCenters: [number, number][] }): Biome {
    //calculate distance to closest Biome Centers of each type
    const volcanicDistance: number = _.min(biomeCenters.VolcanicCenters.map(position => getDistanceBetweenPositions(node.position, position)))!;
    const aquaticDistance: number = _.min(biomeCenters.AquaticCenters.map(position => getDistanceBetweenPositions(node.position, position)))!;
    //determine Biomes
    if (volcanicDistance < biomeRadius) {
        if (volcanicDistance < (biomeRadius / 2)) {
            return Biome.VolcanicExtreme;
        }
        if (aquaticDistance < biomeRadius) {
            return Biome.ThermalSprings;
        }
        return Biome.Volcanic;
    }
    if (aquaticDistance < biomeRadius) {
        return Biome.Lakes;
    }
    return Biome.Standard;
}

function calculateNodeAbandonedStructures(node: OrkifiedJunction, biomeRadius: number, biomeCenters: BiomeCenters): AbandonedStructures | undefined {
    let popDistance: number = _.min(biomeCenters.PopulationCenters.map(position => getDistanceBetweenPositions(node.position, position)))!;
    let cityChance: number = 0;
    let townChance: number = 1;
    let mineChance: number = 2;
    let bastionChance: number = 2;
    let emptyChance: number = 45;
    if (popDistance < biomeRadius) {
        cityChance += 3;
        townChance += 4;
        mineChance += 10;
        bastionChance += 5;
        if (popDistance < (biomeRadius / 2)) {
            cityChance += 10;
            townChance += 10;
            bastionChance += 5
        }
    }

    switch (node.biome) {
        case Biome.ThermalSprings:
            mineChance -= 2;
            cityChance += 2;
            townChance += 3;
            break;
        case Biome.Lakes:
            mineChance -= 2;
            cityChance += 1;
            townChance += 1;
            break;
        case Biome.Volcanic:
            mineChance += 1;
            townChance -= 1;
            break;
        case Biome.VolcanicExtreme:
            mineChance -= 2;
            cityChance = 0;
            cityChance = 0;
            break;
        default:
            break;
    }

    //randomizeBiome:
    let totalChance: number = mineChance + cityChance + townChance + bastionChance + emptyChance;
    let rando: number = random(totalChance);
    if (rando < mineChance) {
        return AbandonedStructures.Mine;
    }
    rando -= mineChance;

    if (rando < townChance) {
        return AbandonedStructures.Town;
    }
    rando -= townChance;

    if (rando < cityChance) {
        return AbandonedStructures.City;
    }
    rando -= cityChance;

    if (rando < bastionChance) {
        return AbandonedStructures.Bastion;
    }
    rando -= bastionChance;

    return undefined;
}

function calculateNodeOrcification(node: OrkifiedJunction, biomeRadius: number, biomeCenters: { VolcanicCenters: [number, number][], AquaticCenters: [number, number][], PopulationCenters: [number, number][], OrcCenters: [number, number][] }, playerPosition: [number,number], safeDistance : number): number {
    let playerDistance : number = getDistanceBetweenPositions(playerPosition, node.position);
    if(playerDistance < safeDistance){
        return 0;
    }
    let orcDistance: number = _.min(biomeCenters.OrcCenters.map(position => getDistanceBetweenPositions(node.position, position)))!;
    let orcChance: number = 5;
    let emptyChance: number = 80;
    if (orcDistance < biomeRadius) {
        orcChance += 5;
        if (orcDistance < (biomeRadius / 2)) {
            orcChance += 5;
        }
    }

    orcChance += (playerDistance/safeDistance) * 8;

    switch (node.biome) {
        case Biome.ThermalSprings:
            orcChance += 6;
            break;
        case Biome.Lakes:
            orcChance += 2;
            break;
        case Biome.Volcanic:
            orcChance += 4;
            break;
        case Biome.VolcanicExtreme:
            orcChance += 1;
            break;
        default:
            break;
    }

    switch (node.abandonedStructure) {
        case AbandonedStructures.Bastion:
            orcChance += 6;
            break;
        case AbandonedStructures.City:
            orcChance += 10;
            break;
        case AbandonedStructures.Town:
            orcChance += 8;
            break;
        case AbandonedStructures.Mine:
            orcChance += 4;
            break;
        default:
            break;
    }

    let rando: number = random((orcChance + emptyChance));

    if (rando < (orcChance / 8)) {
        return 4;
    }

    if (rando < (orcChance / 4)) {
        return 3;
    }

    if (rando < (orcChance / 2)) {
        return 2;
    }

    if (rando < orcChance) {
        return 1;
    }

    return 0;
}

function getDistanceBetweenPositions(pos1: [number, number], pos2: [number, number]): number {
    let distanceX: number = pos1[0] - pos2[0]
    let distanceY: number = pos1[1] - pos2[1]
    return Math.sqrt((distanceX * distanceX) + (distanceY * distanceY));
}

export function isOrcified(node: Node): node is OrkifiedJunction {
    return (node as OrkifiedJunction).orcification !== undefined;
}

function generateSpecialNodes(nodes: OrkifiedJunction[],forges: number, valleys: number, baths: number){
    console.log("addingSpecialNodes");
    let volcanicExtremeNodes: OrkifiedJunction[] = nodes.filter(x => x.biome === Biome.VolcanicExtreme);
    let ThermalSpringsNodes: OrkifiedJunction[] = nodes.filter(x => x.biome === Biome.ThermalSprings);
    let standardNodes: OrkifiedJunction[] = nodes.filter(x => x.biome === Biome.Standard);
    let lakesNodes: OrkifiedJunction[] = nodes.filter(x => x.biome === Biome.Lakes);
    for (let i = 0; i < forges; i++) {
        if(volcanicExtremeNodes.length !== 0){
            let specialIndex: number = random(0,volcanicExtremeNodes.length-1);
            volcanicExtremeNodes[specialIndex].features["forgeOfTheAncients"] = {};
            console.log("forgeAdded");
            let tempvolcanicExtremeNodes : OrkifiedJunction[] = volcanicExtremeNodes.slice(0,i);
            tempvolcanicExtremeNodes.push(...volcanicExtremeNodes.slice(i+1,volcanicExtremeNodes.length));
            volcanicExtremeNodes = tempvolcanicExtremeNodes;
        }
        
    }
    for (let i = 0; i < baths; i++) {
        if(ThermalSpringsNodes.length !== 0){
            let specialIndex: number = random(0,ThermalSpringsNodes.length-1);
            ThermalSpringsNodes[specialIndex].features["greatBath"] = {};
            let tempThermalSpringsNodes : OrkifiedJunction[] = ThermalSpringsNodes.slice(0,i);
            tempThermalSpringsNodes.push(...ThermalSpringsNodes.slice(i+1,ThermalSpringsNodes.length));
            ThermalSpringsNodes = tempThermalSpringsNodes;
        }
        else{
            if(lakesNodes.length !== 0){
                let specialIndex: number = random(0,lakesNodes.length-1);
                lakesNodes[specialIndex].features["greatBath"] = {};
                let tempThermalSpringsNodes : OrkifiedJunction[] = lakesNodes.slice(0,i);
                tempThermalSpringsNodes.push(...lakesNodes.slice(i+1,ThermalSpringsNodes.length));
                lakesNodes = tempThermalSpringsNodes;
            }
        }
        
    }
    for (let i = 0; i < valleys; i++) {
        if(standardNodes.length !== 0){
            let specialIndex: number = random(0,standardNodes.length-1);
            standardNodes[specialIndex].features["protectedValley"] = {};
            let tempvolcanicExtremeNodes : OrkifiedJunction[] = standardNodes.slice(0,i);
            tempvolcanicExtremeNodes.push(...standardNodes.slice(i+1,standardNodes.length));
            standardNodes = tempvolcanicExtremeNodes;
        }
        
    }
}
