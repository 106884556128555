import SimpleAi from "../logic/ai/SimpleAi";
import { applyCommand } from "../logic/commands/_Command";
import { filterFogOfWar } from "../logic/fogOfWar";
import getInitialGameState from "../logic/initialGameState";
import Utils from "../utils";
import { GameStorage } from "./GameStorage";
import { SaveGameRef, SaveGame } from "./SaveGame";

export type GameSource = ({ type: "saveGame"; } & SaveGameRef) |
{ type: "newGame"; seed: string; };

export async function loadOrCreateGame(source: GameSource): Promise<SaveGame> {
  if (source.type === "saveGame") {
    return await GameStorage.load(source);
  }
  else {
    const { seed } = source;
    let gameState = getInitialGameState(seed === "testing" ? seed : Utils.stringHash(seed));
    const aiState = SimpleAi.initialize(gameState, "enemy", command => { gameState = applyCommand(gameState, command, "enemy"); });
    return {
      gameState,
      seed,
      visibleGameStates: {
        "player": filterFogOfWar(gameState, "player"),
        "enemy": filterFogOfWar(gameState, "enemy")
      },
      aiState
    };
  }
}
