import { GameState } from "./GameState";
export function getEmptyGameState(): GameState {
    return {
        resources: {},
        resourceUsage: {
            entryName: "root",
            usagePerTurn: {},
        },
        battles: {},
        buildingTypes: {},
        edges: {},
        heroes: {},
        nodes: {},
        platoons: {},
        research: {},
        researchQueue: [],
        technologies: {},
        time: 0,
        unitTypes: {},
        slotFeatures: {},
        nodeFeatures: {},
    };
}
