import React from 'react';
import { TimeDisplay } from './TimeDisplay';
import { Screen } from '../App';
import "./LoadGameMenu.css";
import { GameStorage } from './GameStorage';

function humanize(date: Date) {
  const now = new Date();
  const delta = now.getTime() - date.getTime();
  const oneSecond = 1000;
  const oneMinute = 60 * oneSecond;
  const oneHour = 60 * oneMinute;
  const oneDay = 24 * oneHour;
  if (delta < oneMinute) {
    return "Vor wenigen Sekunden";
  }
  if (delta < 2 * oneHour) {
    return `Vor ${Math.floor(delta / oneMinute)} Minuten`;
  }
  if (now.getDate() === date.getDate() && delta < 2 * oneDay) {
    return `Heute um ${date.getHours()}:${date.getMinutes().toLocaleString(undefined, { minimumIntegerDigits: 2 })}`;
  }
  const yesterday = new Date();
  yesterday.setTime(now.getTime() - oneDay);
  yesterday.setHours(0, 0, 0, 0);
  if (yesterday.getTime() < date.getTime()) {
    return `Gestern um ${date.getHours()}:${date.getMinutes()}`;
  }
  return `Vor ${Math.floor(delta / oneDay)} Tagen`;
}

export function LoadGameMenu(props: { setScreen: (screen: Screen) => void; goBack: () => void }) {
  const [data, setData] = React.useState(GameStorage.all);
  const update = () => setData(GameStorage.all);
  const [areYouSure, setAreYouSure] = React.useState<JSX.Element | undefined>();
  const root = React.useRef<HTMLDivElement>(null);
  const confirm = (text: string, parent: HTMLElement) => new Promise(accept => {
    if (areYouSure !== undefined) {
      setAreYouSure(undefined);
      accept(false);
      return;
    }
    const rect = parent.getClientRects()[0];
    setAreYouSure(<div className="are-you-sure" style={{ left: rect.right, top: rect.top + rect.height / 2 }}>
      <div>{text}</div>
      <div className="button" onClick={() => { setAreYouSure(undefined); accept(true); }}>Ja</div>
      <div className="button" onClick={() => { setAreYouSure(undefined); accept(false); }}>Nein</div>
    </div>)
  });

  const hasGames = Object.values(data).length !== 0;

  return <div ref={root}>
    <div>
      <div style={{ textAlign: 'left' }}>
        <div className="button" style={{ display: "inline-block" }} onClick={() => props.goBack()}>
          <span className="fa fa-arrow-left" />
          &nbsp;
          Zurück
        </div>
      </div>
      <h1>Spiel laden</h1>
    </div>
    <div className="savegames">
      {data
        .sort((a, b) => b.realTime - a.realTime)
        .map(meta => {
          return <div className="savegame">
            <div className={"load-savegame button" + (meta.isAutosave ? " autosave" : "")}
              onClick={() => props.setScreen({ type: "inGame", source: { type: "saveGame", ...meta } })}
            >
              <div className="fas fa-save" />
              <div className="name">
                {meta.name}
              </div>
              <div className="seed">
                {meta.seed}
              </div>
              <div className="ingame-time">
                <TimeDisplay time={meta.gameTime} />
              </div>
              <div className="real-time">
                {humanize(new Date(meta.realTime))}
              </div>
            </div>
            <div className="delete-savegame button" onClick={async e => {
              if (await confirm("Wirklich löschen?", e.currentTarget)) {
                GameStorage.delete(meta);
                update();
              }
            }}>
              <div className="fa fa-trash" />
            </div>
          </div>;
        })}
      {!hasGames && <div className="placeholder-no-savegames">(Keine gespeicherten Spiele vorhanden.)</div>}
    </div>
    <div className="delete-all-savegames">
      <div className="button " aria-disabled={!hasGames} onClick={async e => {
        if (await confirm("Sind Sie sicher, dass Sie alle Spiele löschen wollen?", e.currentTarget)) {
          GameStorage.clear();
          update();
        }
      }}>
        <span className="fa fa-trash" />&nbsp;
        Alle löschen
      </div>
    </div>
    {areYouSure}
  </div>;
}
