import { flat } from "../Modifiers";
import { Technology } from "../Technology";

export const defaultTechnologies: { [key: string]: Technology; } = {
    "reconquest": {
        tag: "reconquest",
        name: "Rückeroberung",
        dependencies: [],
        description: "Wir haben uns Lange genug in Hoyborg versteckt. Es ist an der Zeit, das Zwergenreich zurückzuerobern.",
        researchTime: 45,
        techTreePosition: [100, 20]
    },
    "standingArmy": {
        tag: "standingArmy",
        name: "Stehendes Heer",
        dependencies: ["reconquest"],
        description: "Wenn wir unser Reich zurückerobern wollen, werden wir ein gut ausgebildetes stehendes Heer brauchen.",
        researchTime: 30,
        techTreePosition: [100, 80]
    },
    "strongRecon": {
        tag: "strongRecon",
        name: "Starke Aufklärung",
        dependencies: ["standingArmy"],
        description: "Wenn wir den Feind besiegen wollen, dann müssen wir wissen wo er ist. Dafür müssen wir unsere Späher besser ausrüsten",
        researchTime: 30,
        techTreePosition: [100, 140]
    },
    "blackPowder": {
        tag: "blackPowder",
        name: "Schwarzpulver",
        dependencies: [],
        description: "Eine Mischung aus Kohle, Schwefel und Salpeter mit enormem Zerstörungspotential.",
        researchTime: 30,
        techTreePosition: [350, 20],
        modifiers: [flat("miningSpeed", 10)]
    },
    "matchlock": {
        tag: "matchlock",
        name: "Luntschloss",
        dependencies: ["blackPowder"],
        description: "Wenn wir Schwarzpulver in einer tragbaren Waffe nutzbar machen wollen, brauchen wir einen praktischen Mechanismus, um dieses zu entzünden.",
        researchTime: 50,
        techTreePosition: [450, 80]
    },
    "rifling": {
        tag: "rifling",
        name: "Drall",
        dependencies: ["matchlock"],
        description: "Projektile fliegen deutlich stabiler, wenn sie sich um die eigene Achse drehen — das wissen wir schon von Pfeilen und Armbrustbolzen. "
            + "Jetzt müssen wir uns nur noch überlegen, wie wir unseren Musketenkugeln so eine Drehung verpassen.",
        researchTime: 50,
        techTreePosition: [550, 150],
        modifiers: [
        {
            property: "rangedRange",
            type: "flat",
            value: 5,
            unitType: "musketeer"
        }]
    },
    "minieBall": {
        tag: "minieBall",
        name: "Minié-Geschoss",
        dependencies: ["rifling"],
        description: "Wenn wir die Rückseite unserer Musketenkugeln hohl gestalten würden, dann würden sie sich wohl durch den Druck in der Muskete ausweiten und an den Lauf anpressen. "
            + "Das könnte zu einem geringeren Energieverlust durch Lücken zwischen Lauf und Geschoss sorgen, sowie für eine bessere übertragung des Dralls.",
        researchTime: 50,
        techTreePosition: [550, 220],
        modifiers: [{
            property: "rangedAttack",
            type: "flat",
            value: 5,
            unitType: "musketeer"
        },
        {
            property: "rangedRange",
            type: "flat",
            value: 3,
            unitType: "musketeer"
        }]
    },
    "flintlock": {
        tag: "flintlock",
        name: "Steinschloss",
        dependencies: ["matchlock", "blackPowder2"],
        description: "Wenn wir das Pulver in unseren Musketen mit einem Funken zünden würden, anstatt eine glühenden Lunte zu verwenden, dann könnten wir deutlich schneller nachladen. "
            + "Außerdem würde das Anzünden der Lunten am Anfang jedes Kampfes entfallen. Das würde aber auch einiges an mechanischer Feinarbeit erfordern.",
        researchTime: 200,
        techTreePosition: [450, 290],
        modifiers: [{
            property: "rangedReloadTime",
            type: "flat",
            value: -2,
            unitType: "musketeer"
        },
        {
            property: "rangedStartupTime",
            type: "flat",
            value: -3,
            unitType: "musketeer"
        }]
    },
    "pistols": {
        tag: "pistols",
        name: "Pistolen",
        dependencies: ["flintlock"],
        description: "Mit dem Steinschlossmechanismus werden auch kleinere Feuerwaffen praktisch. Mit diesen leichten Pistolen könnten wir dann unsere Späher ausrüsten",
        researchTime: 50,
        techTreePosition: [450, 340]
    },
    "explosives": {
        tag: "explosives",
        name: "Sprengstoff",
        dependencies: ["blackPowder"],
        description: "Mit Schwarzpulver lassen sich sehr zerstörerische Sprengkörper bauen, die uns im Bergbau von großem Nutzen sein können.",
        researchTime: 50,
        techTreePosition: [230, 80],
        modifiers: [flat("miningSpeed", 20)]
    },
    "grenades": {
        tag: "grenades",
        name: "Handgranaten",
        dependencies: ["explosives"],
        description: "Wenn wir Schwarzpulver in metallene Kugeln füllen und mit Lunten versehen, könnten wir sehr effektive werfbare Sprengwaffen erhalten.",
        researchTime: 50,
        techTreePosition: [230, 150]
    },
    "blackPowder2": {
        tag: "blackPowder2",
        name: "Verbessertes Schwarzpulver",
        dependencies: ["blackPowder"],
        description: "Die Formel für Schwarzpulver hat viele Stellschrauben an denen wir drehen können, was die Verhältnisse der Zutaten betrifft.",
        researchTime: 100,
        techTreePosition: [350, 150],
        modifiers: [{
            property: "rangedAttack",
            type: "percentage",
            value: 10,
            features: ["gunpowder"] 
        }]
    },
    "blackPowder3": {
        tag: "blackPowder3",
        name: "Gekörntes Schwarzpulver",
        dependencies: ["blackPowder2"],
        description: "TODO: Beschreibung.",
        researchTime: 200,
        techTreePosition: [350, 220],
        modifiers: [{
            property: "rangedAttack",
            type: "percentage",
            value: 10,
            features: ["gunpowder"] 
        }]
    },
    "blackPowder4": {
        tag: "blackPowder4",
        name: "Perfektioniertes Schwarzpulver",
        dependencies: ["blackPowder3"],
        description: "Unser heutiges Schwarzpulver funktioniert schon sehr gut, einige unserer Alchemisten meinen, sie hätten noch ein paar Tricks auf Lager.",
        researchTime: 400,
        techTreePosition: [350, 290],
        modifiers: [{
            property: "rangedAttack",
            type: "percentage",
            value: 10,
            features: ["gunpowder"] 
        }]
    },
    "betterGears": {
        tag: "betterGears",
        name: "Bessere Getriebe",
        dependencies: [],
        description: "Wenn wir unsere Getriebe optimieren, dann werden unsere Maschinen besser laufen, und komplexere Konstruktionen werden möglich.",
        researchTime: 30,
        techTreePosition: [800, 20],
        modifiers: [{
            property: "rangedReloadTime",
            type: "flat",
            value: -1,
            unitType: "ballista"
        },
        {
            property: "rangedStartupTime",
            type: "flat",
            value: -1,
            unitType: "ballista"
        }]
    },
    "safety": {
        tag: "safety",
        name: "Sicherung",
        dependencies: ["betterGears"],
        description: "TODO: Beschreibung.",
        researchTime: 30,
        techTreePosition: [800, 80],
        modifiers: [{
            property: "rangedStartupTime",
            type: "flat",
            value: -4,
            unitType: "crossbow"
        },
        {
            property: "rangedStartupTime",
            type: "flat",
            value: -8,
            unitType: "ballista"
        }]
    },
    "poweredMachinery": {
        tag: "poweredMachinery",
        name: "Mechanisierte Werkzeuge",
        dependencies: ["betterGears"],
        description: "Mit unseren verbesserten Getrieben können wir Kräfte von natürlichen Quellen, wie Wasserrädern, auf Hämmer und Drehbänke übertragen",
        researchTime: 100,
        techTreePosition: [1000, 80]
    },
    "steamEngine": {
        tag: "steamEngine",
        name: "Dampfmaschine",
        dependencies: ["poweredMachinery"],
        description: "Wenn Wasser verkocht, verwandelt es sich in deutlich voluminöseren Dampf. Wenn wir diese Ausdehnung nutzen könnten um Kolben zu bewegen, könnten wir aus Kohle mechanische Enrgie erzeugen",
        researchTime: 200,
        techTreePosition: [1200, 150]
    },
    "mechanicalAbacus": {
        tag: "mechanicalAbacus",
        name: "Mechanischer Abacus",
        dependencies: ["betterGears"],
        description: "Wenn wir ein Gerät entwerfen könnten, welches uns das Rechnen abnimmt, könnten wir deutlich schneller forschen.",
        researchTime: 100,
        techTreePosition: [600, 80],
        modifiers: [] //TODO: research speed
    },
    "handCrankedHammerDrill": {
        tag: "handCrankedHammerDrill",
        name: "Handbetriebener Schlagbohrer",
        dependencies: ["poweredMachinery"],
        description: "Mit Hilfe einer Kurbel lässt sich mechanische Energie in einer Feder sammeln, welche dann auf einen Schlag entladen lässt um einen Bolzen ins Gestein zu treiben.",
        researchTime: 200,
        techTreePosition: [1000, 150],
        modifiers: [flat("miningSpeed", 20)]
    },
    "pressureSystems": {
        tag: "pressureSystems",
        name: "Drucksysteme",
        dependencies: ["steamEngine"],
        description: "Wenn wir bessere Ventile und Dichtungen entwerfen, können wir unsere Dampfmaschinen effizienter machen.",
        researchTime: 200,
        techTreePosition: [1200, 220],
        modifiers: [] //TODO: less coal consumption for steampowered buildings
    },
    "airPoweredHammerDrill": {
        tag: "airPoweredHammerDrill",
        name: "Druckluftbetriebener Schlagbohrer",
        dependencies: ["handCrankedHammerDrill","pressureSystems"],
        description: "Dank unserer verbesserten Drucksysteme können wir nun Energie in Form von Druck speichern, und diese im Bergbau nutzen.",
        researchTime: 200,
        techTreePosition: [1000, 290],
        modifiers: [flat("miningSpeed", 20)]
    },
    "improvedArmour": {
        tag: "improvedArmour",
        name: "Verbesserte Rüstung",
        dependencies: [],
        description: "Um zu siegen müssen wir unsere Soldaten besser schützen.",
        researchTime: 200,
        techTreePosition: [1600, 20],
        modifiers: [{
            property: "defense",
            type: "flat",
            value: 2,
        }]
    },
    "innovativeArmour": {
        tag: "innovativeArmour",
        name: "Verbesserte Rüstung",
        dependencies: ["improvedArmour"],
        description: "Wir werden uns innovative möglichkeiten suchen um unsere soldaten noch besser zu schützen",
        researchTime: 400,
        techTreePosition: [1600, 80],
        modifiers: [{
            property: "defense",
            type: "flat",
            value: 3,
        }]
    }

};
