import { GameState } from "../GameState";
import { Faction } from "../Faction";
import { BuildCommand, applyBuildCommand } from "./BuildCommand";
import { MoveCommand, applyMoveCommand } from "./MoveCommand";
import { TickCommand, applyTickCommand } from "./TickCommand";
import { TrainUnitCommand, applyTrainUnitCommand } from "./TrainUnitCommand";
import { CancelTrainingCommand, applyCancelTrainingCommand } from "./CancelTrainingCommand";
import { SettleCommand, applySettleCommand } from "./SettleCommand";
import { EnqueueResearchCommand, DequeueResearchCommand, applyEnqueueResearchCommand, applyDequeueResearchCommand } from "./QueueResearchCommand";
import { TransferUnitsCommand, applyTransferUnitsCommand } from "./TransferUnitsCommand";
import { ChangeBuildingActivationCommand, applyBuildingActivationCommand } from "./ChangeBuildingActivationCommand";
import { SetPriorityCommand, applySetPriorityCommand } from "./SetPriorityCommand";
import { DisbandCommand, applyDisbandCommand } from "./DisbandCommand";

export type Command =
    | BuildCommand
    | TickCommand
    | MoveCommand
    | TrainUnitCommand
    | CancelTrainingCommand
    | SettleCommand
    | EnqueueResearchCommand
    | DequeueResearchCommand
    | TransferUnitsCommand
    | ChangeBuildingActivationCommand
    | SetPriorityCommand
    | DisbandCommand

export function applyCommand(gameState: GameState, command: Command, sender: Faction): GameState {
    const reduce = getReducer(command.type);
    return reduce(gameState, command, sender);
}

type reducer = (gameState: GameState, command: Command, sender: Faction) => GameState;
function getReducer(type: Command["type"]): reducer {
    switch (type) {
        case "buildCommand":
            return applyBuildCommand as reducer;
        case "tickCommand":
            return applyTickCommand as reducer;
        case "moveCommand":
            return applyMoveCommand as reducer;
        case "trainUnitCommand":
            return applyTrainUnitCommand as reducer;
        case "cancelTrainingCommand":
            return applyCancelTrainingCommand as reducer;
        case "settleCommand":
            return applySettleCommand as reducer;
        case "enqueueResearchCommand":
            return applyEnqueueResearchCommand as reducer;
        case "dequeueResearchCommand":
            return applyDequeueResearchCommand as reducer;
        case "transferUnitsCommand":
            return applyTransferUnitsCommand as reducer;
        case "changeBuildingActivationCommand":
            return applyBuildingActivationCommand as reducer;
        case "setPriorityCommand":
            return applySetPriorityCommand as reducer;
        case "disbandCommand":
            return applyDisbandCommand as reducer;
    }
}
