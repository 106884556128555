import React from 'react';
import { MainMenu } from './ui/MainMenu';
import { GameSource } from './ui/GameSource';
import { LoadGameMenu } from './ui/LoadGameMenu';
import { SinglePlayerGameScreen } from './ui/SinglePlayerGameScreen';
import Utils from './utils';
import { HostComponent } from './net/HostComponent';
import { ClientComponent } from './net/ClientComponent';

export type Screen =
  | { type: "inGame"; source: GameSource; }
  | { type: "mainMenu"; }
  | { type: "selectGame" }
  | { type: "host", roomId: string, source: GameSource }
  | { type: "client", roomId: string, clientId: string }
export function App() {
  const [screen, setScreen] = React.useState<Screen>(() => ({ type: "mainMenu" }));
  const key = React.useMemo(() => Utils.stringHash(JSON.stringify(screen)), [screen])
  switch (screen.type) {
    case "inGame":
      return <SinglePlayerGameScreen key={key} source={screen.source} setScreen={setScreen} />;
    case "mainMenu":
      return <MainMenu setScreen={setScreen} />;
    case "selectGame":
      return <div className="main-menu">
        <LoadGameMenu setScreen={setScreen} goBack={() => setScreen({ type: "mainMenu" })} />
      </div>
    case "host":
      return <HostComponent roomId={screen.roomId} source={screen.source} />
    case "client":
      return <ClientComponent roomId={screen.roomId} clientId={screen.clientId} />
  }
}
