import { produce } from "immer";
import { Draft } from "immer";
import _ from "lodash";
import Utils from "../../utils";
import { TagMap } from "../../utils/utils";
import weakMemoize from "../../utils/weakMemoize";
import { Command } from "../commands/_Command";
import { dijkstra, getAdjacencyMap } from "../Edge";
import { Faction } from "../Faction";
import { GameState } from "../GameState";
import { BuildSlot, isTown, Town } from "../Node";
import { getPlatoonsByPosition, Platoon } from "../Platoon";
import { Ai } from "./interface";

interface AiState {
    faction: Faction;
    townStates: TagMap<TownState>;
    /** Maps the platoons to their associated towns. */
    platoonAssociations: { [platoon in string]?: string }
}

interface TownState {
    tag: string;
    scout?: ScoutingState;
    plannedAttack?: AttackState;
}

interface ScoutingState {
    /** We're using the same tag as the platoon tag here. */
    tag: string;
    homeNode: string;
    tabuList: ReadonlySet<string>;
    startTime: number;
}

interface AttackState {
    sourceNode: string;
    targetNode: string;
    planStartTime: number;
}

interface AiContext {
    aiState: Draft<AiState>;
    gameState: GameState;
    submitCommand: (command: Command) => void;
}

const SimpleAi: Ai<AiState> = {
    initialize(gameState, faction, submitCommand) {
        const platoonAssociationList = Utils.values(gameState.platoons)
            .filter(x => x.faction === faction)
            .map((x): [string, string | undefined] => {
                if (x.position.type === "onNode") {
                    const node = gameState.nodes[x.position.node];
                    if (isTown(node) && node.faction === faction) {
                        return [x.tag, node.tag];
                    }
                }
                // TODO: search closest viable node
                // for now, don't assign this node
                return [x.tag, undefined];
            });
        const platoonAssociations = Object.fromEntries(platoonAssociationList);
        const townStates = Object.values(gameState.nodes)
            .filter(node => isTown(node) && node.faction === faction)
            .map((town): TownState => {
                return {
                    tag: town.tag,
                    scout: undefined
                };
            });

        let aiState: AiState = {
            faction,
            townStates: Object.fromEntries(townStates.map(x => [x.tag, x])),
            platoonAssociations
        };

        return aiState;
    },
    update(aiState, gameState, submitCommand) {

        aiState = removeVanishedPlatoons(aiState, gameState);

        aiState = assignNewPlatoons(gameState, aiState);

        aiState = produce(aiState, aiState => {
            for (const [townState, town] of Utils.resolveExtensionDraft(aiState.townStates, gameState.nodes)) {
                if (!isTown(town)) {
                    delete aiState.townStates[townState.tag];
                    continue;
                }

                // update the scout
                updateScout(townState, gameState, aiState, submitCommand, town);

                trainGuard(town, gameState, submitCommand);

                if (townState.plannedAttack !== undefined) {
                    const attack = townState.plannedAttack;
                    const remainingDays = 60 - (gameState.time - attack.planStartTime)
                    console.log(`${attack.sourceNode} will be attacking ${attack.targetNode} in ${remainingDays} days!`);
                    if (remainingDays <= 0) {
                        townState.plannedAttack = undefined;
                        carryOutAttack(gameState, aiState, attack, submitCommand);
                    }
                }
            }
        });

        return aiState;
    }
}

const getInvertedAssociations = weakMemoize((platoonAssociations: { [platoon in string]?: string }) => Utils.invertMapping(platoonAssociations));

function carryOutAttack(gameState: GameState, aiState: Draft<AiState>, attack: Draft<AttackState>, submitCommand: (command: Command) => void) {
    const attackingPlatoons = Utils.values(gameState.platoons)
        .filter(p => p.faction === aiState.faction
            && p.path.length === 0
            && p.position.type === "onNode"
            && p.position.node === attack.sourceNode);
    const primaryAttackingPlatoon = attackingPlatoons[0];
    if (primaryAttackingPlatoon === undefined) {
        console.log("Aborting the attack since no units are available to carry it out.");
        return;
    }
    for (const platoon of attackingPlatoons.slice(1)) {
        submitCommand({
            type: "transferUnitsCommand",
            sourcePlatoon: platoon.tag,
            targetPlatoon: primaryAttackingPlatoon.tag,
            units: "all"
        });
    }
    submitCommand({
        type: "moveCommand",
        platoon: primaryAttackingPlatoon.tag,
        targetNode: attack.targetNode
    });
}

function assignNewPlatoons(gameState: GameState, aiState: AiState) {
    const newPlatoons = Utils.values(gameState.platoons).filter(x => x.faction === aiState.faction && aiState.platoonAssociations[x.tag] === undefined);
    aiState = produce(aiState, aiState => {
        for (const platoon of newPlatoons) {
            const closestCity = dijkstra(gameState.edges, platoon.position, tag => {
                const node = gameState.nodes[tag];
                return isTown(node) && node.faction === aiState.faction;
            })?.pop();
            aiState.platoonAssociations[platoon.tag] = closestCity;
        }
    });
    return aiState;
}

function removeVanishedPlatoons(aiState: AiState, gameState: GameState) {
    const vanishedPlatoons = Utils.resolve(Object.keys(aiState.platoonAssociations), gameState.platoons).removedTags;
    aiState = produce(aiState, aiState => {
        for (const platoon of vanishedPlatoons) {
            delete aiState.platoonAssociations[platoon];
        }
    });
    return aiState;
}

function updateScout(townState: Draft<TownState>, gameState: GameState, aiState: Draft<AiState>, submitCommand: (command: Command) => void, town: Town) {
    if (townState.scout === undefined) {
        const available = getInvertedAssociations(aiState.platoonAssociations).get(townState.tag) ?? [];
        const { resolved } = Utils.resolve(available, gameState.platoons);
        const units = resolved.flatMap(platoon => platoon.units.map((unit, unitIndex) => ({ platoon, unit, unitIndex })))
        const fastest = _.maxBy(units, x => gameState.unitTypes[x.unit.kind].moveSpeed);
        if (fastest !== undefined) {
            const scoutTag = Utils.getUnusedTag(`${aiState.faction}-scout-`);
            submitCommand({
                type: "transferUnitsCommand",
                sourcePlatoon: fastest.platoon.tag,
                targetPlatoon: scoutTag,
                units: [fastest.unitIndex]
            })
            townState.scout = {
                tag: scoutTag,
                homeNode: townState.tag,
                startTime: gameState.time,
                tabuList: new Set(),
            }
            return;
        }
        else return;
    }
    const platoon = gameState.platoons[townState.scout.tag];
    if (platoon === undefined) {
        townState.scout = undefined;
        return;
    }
    else {
        updateScoutMovement({ aiState, gameState, submitCommand }, townState.scout, platoon);

        if (platoon.position.type === "onNode" && townState.plannedAttack === undefined) {
            const adjacencyMap = getAdjacencyMap(gameState.edges);
            const discoveries = adjacencyMap[platoon.position.node]
                ?.map(x => gameState.nodes[x.target])
                .filter(x => isTown(x) && x.faction !== aiState.faction);
            if (discoveries !== undefined && discoveries.length !== 0) {
                const targetNode = discoveries[0];
                console.log(`${town.tag} has discovered ${targetNode}!`);
                townState.plannedAttack = {
                    planStartTime: gameState.time,
                    sourceNode: townState.scout.homeNode,
                    targetNode: targetNode.tag
                };
            }
        }
    }
}

const getExpectedUnits = weakMemoize((buildSlots: BuildSlot[]) => {
    let expectedUnits = buildSlots.flatMap(slot =>
        slot.site.type === "building" ?
            slot.site.kind === "orkified blacksmith" ?
                ["heavy orc"] :
                ["orc", "goblin", "goblin"] :
            []);
    expectedUnits.push("goblin");
    return {
        total: expectedUnits.length,
        unitTypes: _.countBy(expectedUnits)
    };
});

function trainGuard(town: Town, gameState: GameState, submitCommand: (command: Command) => void) {
    if ((town.trainingQueue?.length ?? 0) === 0) {
        const expectedUnits = getExpectedUnits(town.buildSlots);
        const targetNumGuardUnits = expectedUnits.total;
        const localUnits = getPlatoonsByPosition(gameState.platoons).onNode.at(town.tag).flatMap(x => x.units);
        if (localUnits.length < targetNumGuardUnits) {
            const localUnitCounts = _.countBy(localUnits, x => x.kind);
            const missing = _.mapValues(expectedUnits.unitTypes, (expected, key) => expected - (localUnitCounts[key] ?? 0));
            const [mostMissing] = _.maxBy(Object.entries(missing), ([unit, count]) => count)!;
            submitCommand({ type: "trainUnitCommand", node: town.tag, unitType: mostMissing });
        }
    }
}

function updateScoutMovement(context: AiContext, scout: Draft<ScoutingState>, platoon: Platoon) {
    const goHome = () => {
        context.submitCommand({ type: "moveCommand", platoon: scout.tag, targetNode: scout.homeNode });
        scout.startTime = context.gameState.time;
        scout.tabuList.clear();
        scout.tabuList.add(scout.homeNode);
    }
    if (platoon.position.type === "onNode" && platoon.path.length === 0) {
        if (context.gameState.time - scout.startTime > 60) {
            // go home after being scouting for too long
            return goHome();
        }
        const viableNextNodes = getAdjacencyMap(context.gameState.edges)[platoon.position.node]
            ?.map(x => x.target)
            ?.filter(x => !scout.tabuList.has(x));
        if (viableNextNodes === undefined || viableNextNodes.length === 0) {
            // can't scout any further -- let's go home instead
            return goHome();
        }
        const targetNode = Utils.pickRandomly(viableNextNodes);
        if (targetNode !== undefined) {
            scout.tabuList.add(targetNode);
            context.submitCommand({ type: "moveCommand", platoon: platoon.tag, targetNode });
        }
    }
}


export default SimpleAi as Ai<any>;