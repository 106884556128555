import React, { useEffect } from "react"
import { GameSource, loadOrCreateGame } from "../ui/GameSource";
import { RoomMessage } from "./command_proxy/types";
import { create } from "zustand";
import { ClientComponent, TransferredState } from "./ClientComponent";
import { ExtendedCommand, SaveGame, SpeedState, applyTick, speeds } from "../ui/SaveGame";
import { applyCommand } from "../logic/commands/_Command";
import { updateAllVision } from "../logic/fogOfWar";
import { compare } from "fast-json-patch";
import { produce } from "immer";
import { getProxyServerUrl } from "./getProxyServerUrl";

interface ClientConnectionState {
    lastSyncedState: TransferredState
}

interface ServerState {
    isReady: boolean,
    clients: { [name in string]?: ClientConnectionState },
    saveGame: SaveGame

    speedState: SpeedState

    initialize(source: GameSource): Promise<void>
    receiveMessage(message: RoomMessage): void
    sendMessage?(recepient: string, message: any): void;
    updateGameState(newSaveGame: SaveGame): void;
    tick(): void;
    sync(): void;
}

const useServerStore = create<ServerState>((set, get) => ({
    isReady: false,
    clients: {},
    saveGame: null!,
    speedState: { paused: true, speed: 0 },

    async initialize(source) {
        set({ saveGame: await loadOrCreateGame(source) });
    },

    receiveMessage(message: RoomMessage) {
        console.log("Host received:", message);
        const { saveGame, clients, speedState, sync } = get();

        switch (message.type) {
            case "ready":
                set({ isReady: true });
                break;
            case "connect": {
                set({
                    clients: produce(clients, clients => {
                        clients[message.source] = {
                            lastSyncedState: {} as any
                        }
                    })
                });
                break;
            }
            case "disconnect":
                set({ clients: produce(clients, clients => { delete clients[message.source] }) });
                break;
            case "message": {
                const command: ExtendedCommand = JSON.parse(message.msg);
                switch (command.type) {
                    case "setPausedCommand":
                        set({ speedState: { ...speedState, paused: command.paused } });
                        break;
                    case "setSpeedCommand":
                        set({ speedState: { ...speedState, speed: command.speed } });
                        break;
                    case "setSavegameNameCommand":
                        set({
                            saveGame: {
                                ...saveGame,
                                ref: { name: command.name, isAutosave: false }
                            }
                        });
                        break;
                    default:
                        const newGameState = applyCommand(saveGame.gameState, command, "player");
                        const newSaveGame = {
                            ...saveGame,
                            gameState: newGameState,
                            visibleGameStates: updateAllVision(newGameState, saveGame.visibleGameStates),
                        };
                        set({ saveGame: newSaveGame });
                        break;
                }
                break;
            }
        }
        sync();
    },

    updateGameState(newSaveGame: SaveGame) {
        set({ saveGame: newSaveGame });
        get().sync();
    },

    sync() {
        const { clients, saveGame, speedState, sendMessage } = get();
        const newClients = { ...clients };
        for (const client in clients) {
            console.log(client, speedState);
            const targetState: TransferredState = {
                visibleGameState: saveGame.visibleGameStates["player"],
                speedState
            };
            const diffs = compare(clients[client]!.lastSyncedState, targetState);
            sendMessage?.(client, diffs);
            newClients[client] = { ...clients[client], lastSyncedState: targetState };
        }
        set({ clients: newClients })
    },

    tick() {
        get().updateGameState(applyTick(get().saveGame));
    }
}))

export function HostComponent(props: { roomId: string, source: GameSource }) {
    const { isReady, clients } = useServerStore();
    useEffect(() => {
        let ws: WebSocket | undefined;
        let mounted = true;
        init();
        return () => {
            console.log("Unmounting", ws);
            mounted = false;
            ws?.close(4001, "Host component unmounting");
        }

        async function init() {
            await useServerStore.getState().initialize(props.source);
            if (!mounted) return;

            ws = new WebSocket(`${await getProxyServerUrl()}/host/${props.roomId}/`);
            ws.onmessage = rawMsg => {
                const message: RoomMessage = JSON.parse(rawMsg.data);
                useServerStore.getState().receiveMessage(message);
            }
            ws.onclose = () => {
                useServerStore.setState({ isReady: false, clients: {}, sendMessage: undefined });
            }

            useServerStore.setState({
                sendMessage: (recepient, msg) =>
                    ws?.send(JSON.stringify({
                        target: recepient,
                        msg: JSON.stringify(msg),
                    }))
            })
        }
    }, [props]);

    return <div style={{ color: "white" }}>
        <ServerTicker />
        Host-Status: {isReady ? "Bereit" : "Initialisiert..."}
        <br />
        Clients: {Object.keys(clients).join(", ")}

        {isReady && <ClientComponent roomId={props.roomId} clientId="host" />}

    </div>
}

function ServerTicker() {
    const { paused, speed } = useServerStore(s => s.speedState);
    React.useEffect(() => {
        if (paused) return;
        const ms = speeds[speed];
        const handle = setInterval(() => useServerStore.getState().tick(), ms);
        return () => clearInterval(handle);
    }, [paused, speed]);

    return undefined;
}
