import { GameState } from './GameState';
import { Faction } from './Faction';
import _ from 'lodash';
import Utils from '../utils';
import { isTown, Node } from './Node';
import { InBattle, OnEdge, OnNode } from './Platoon';
import { Edge, getAdjacencyMap } from './Edge';
import { TagMap } from '../utils/utils';

export type VisibleNode = Node & {
  lastSeen: number,
  knowsAdjacentNodes: boolean
};
export type VisibleEdge = Edge & {
  lastSeen: number
};

export interface VisibleGameState extends GameState {
  nodes: TagMap<VisibleNode>,
  edges: TagMap<VisibleEdge>
}

export function updateAllVision(gameState: GameState, vision: { [key in Faction]: VisibleGameState }): { [key in Faction]: VisibleGameState } {
  const newVision = { ...vision }
  for (const player of ["player", "enemy"] as Faction[]) {
    newVision[player] = updateVision(newVision[player], gameState, "player");
  }
  return newVision;
}

export function totalVision(gameState: GameState): VisibleGameState {
  return {
    ...gameState,
    nodes: Utils.tagify(Object.values(gameState.nodes).map(node => ({
      ...node,
      lastSeen: gameState.time,
      knowsAdjacentNodes: true
    }))),
    edges: Utils.tagify(Object.values(gameState.edges).map(edge => ({
      ...edge,
      lastSeen: gameState.time
    }))),
  }
}

export function updateVision(oldObservedState: VisibleGameState, currentState: GameState, faction: Faction): VisibleGameState {
  const currentObservedState = filterFogOfWar(currentState, faction);
  const currentlyObservedNodes = Object.values(currentObservedState.nodes).map(x => ({
    ...x,
    knowsAdjacentNodes: x.knowsAdjacentNodes || (oldObservedState.nodes[x.tag]?.knowsAdjacentNodes ?? false)
  }));
  return {
    ...oldObservedState,
    ...currentObservedState,
    nodes: {
      ...oldObservedState.nodes,
      ...Utils.tagify(currentlyObservedNodes)
    },
    edges: {
      ...oldObservedState.edges,
      ...currentObservedState.edges
    }
  }
}

export function filterFogOfWar(gameState: GameState, faction: Faction): VisibleGameState {
  const ownNodes = Object.values(gameState.nodes).filter(x => isTown(x) && x.faction === faction);
  const ownPlatoons = Utils.values(gameState.platoons).filter(x => x.faction === faction);
  const platoonPositions = ownPlatoons.map(x => x.position);
  const battlePositions = platoonPositions.filter(x => x.type === "inBattle").map(x => gameState.battles[(x as InBattle).battle]!.position);
  const allPositions = [...platoonPositions.filter(x => x.type !== "inBattle") as (OnNode | OnEdge)[], ...battlePositions];
  const grouped = _.groupBy(allPositions, x => x.type);
  const platoonVisibleEdges = (grouped.onEdge ?? []).map(x => (x as OnEdge).edge);
  const visibleViaEdge = platoonVisibleEdges.flatMap(x => gameState.edges[x].nodes);
  const platoonVisibleNodes = (grouped.onNode ?? []).map(x => (x as OnNode).node);
  const directlyVisibleNodes = [...platoonVisibleNodes, ...ownNodes.map(x => x.tag)];
  const adjacencyMap = getAdjacencyMap(gameState.edges);
  const transitivelyVisibleHalfEdges = directlyVisibleNodes.flatMap(x => adjacencyMap[x] ?? []);
  const transitivelyVisibleNodes = transitivelyVisibleHalfEdges.map(x => x.target);

  const directlyVisibleTags = new Set(directlyVisibleNodes);
  const visibleNodeTags = new Set([...directlyVisibleNodes, ...transitivelyVisibleNodes, ...visibleViaEdge]);
  const allVisibleNodes = [...visibleNodeTags].map(x => ({
    ...gameState.nodes[x],
    lastSeen: gameState.time,
    knowsAdjacentNodes: directlyVisibleTags.has(x)
  }));

  const visibleEdgeTags = new Set([...transitivelyVisibleHalfEdges.map(x => x.edgeTag), ...platoonVisibleEdges])
  const allVisibleEdges = Object.values(gameState.edges)
    .filter(x => visibleEdgeTags.has(x.tag))
    .map(x => ({ ...x, lastSeen: gameState.time }));

  const visibleBattles = Utils.values(gameState.battles).filter((x): boolean => {  // eslint-disable-line array-callback-return
    switch (x.position.type) {
      case "onEdge":
        return visibleEdgeTags.has(x.position.edge);
      case "onNode":
        return visibleNodeTags.has(x.position.node);
    }
  });
  const visibleBattleTags = new Set(visibleBattles.map(x => x.tag));

  const visiblePlatoons = Utils.values(gameState.platoons).filter((x): boolean => { // eslint-disable-line array-callback-return
    switch (x.position.type) {
      case "onEdge":
        return visibleEdgeTags.has(x.position.edge);
      case "onNode":
        return visibleNodeTags.has(x.position.node);
      case "inBattle":
        return visibleBattleTags.has(x.position.battle);
    }
  });


  return {
    battles: Utils.tagify(visibleBattles),
    buildingTypes: gameState.buildingTypes,
    edges: Utils.tagify(allVisibleEdges),
    heroes: gameState.heroes,
    nodes: Utils.tagify(allVisibleNodes),
    platoons: Utils.tagify(visiblePlatoons),
    research: gameState.research,
    researchQueue: gameState.researchQueue,
    resources: gameState.resources,
    resourceUsage: gameState.resourceUsage,
    technologies: gameState.technologies,
    unitTypes: gameState.unitTypes,
    slotFeatures: gameState.slotFeatures,
    nodeFeatures: gameState.nodeFeatures,
    time: gameState.time,
  };
}
