import { useEffect } from 'react';
import { GameState } from '../logic/GameState';
import * as zustand from "zustand";
import { devtools } from 'zustand/middleware'
import { getEmptyGameState } from '../logic/GameState.testUtils';

export const useGameState = zustand.create(devtools<GameState>((set) => getEmptyGameState()));

export const useProvideGameState = (gameState: GameState) => {
    useEffect(() => {
        useGameState.setState(gameState);
    }, [gameState])
}
