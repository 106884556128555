import Utils from "../../../utils";
import { GameState } from "../../GameState";
import { isTown } from "../../Node";
import { Platoon, Unit } from "../../Platoon";
import { ResourceUtils } from "../../Resources";
import { isAvailable } from "../../Technology";
import { getUnitTypeStatsFor } from "../../getUnitStats";
import { produce } from "immer";
import { Faction } from "../../Faction";

export function updateTraining(gameState: GameState): GameState {
    let platoons = { ...gameState.platoons };
    let resources = gameState.resources;
    const spawnedUnits: { unit: Unit, node: string, faction: Faction }[] = [];
    const newNodes = Utils.mapObject(gameState.nodes, node => {
        if (!isTown(node))
            return node;
        if (node.trainingQueue === undefined || node.trainingQueue.length === 0)
            return node;
        let unit = node.trainingQueue[0];
        const stats = getUnitTypeStatsFor(gameState, unit.kind);
        const unitType = gameState.unitTypes[unit.kind];
        if (node.faction !== "enemy") {
            const trainability = isAvailable(unitType.requiredTech, gameState.research);
            if (trainability !== "available") {
                console.warn(`Training ${unit.kind} in ${node.tag} failed, since technology ${unitType.requiredTech} is not available (${trainability}).`);
                return {
                    ...node,
                    trainingQueue: node.trainingQueue.slice(1)
                };
            }
        }
        if (unitType.requiredBuilding !== undefined
            && !node.buildSlots.some(x => x.site.type === "building" && x.site.kind === unitType.requiredBuilding)) {
            console.warn(`Training ${unit.kind} in ${node.tag} failed, since no building of type ${unitType.requiredBuilding} was found.`);
            return {
                ...node,
                trainingQueue: node.trainingQueue.slice(1)
            };
        }

        // the AI can build for free for now
        const trainCosts = node.faction === "player" ? stats.trainCosts : {};
        const gatherResult = ResourceUtils.gather(resources, unit.deliveredResources, trainCosts);
        const missingManpower = stats.manpower - unit.conscriptedManpower
        let newManpower = unit.conscriptedManpower;
        let newPopulation = node.population;
        if (missingManpower > 0) {
            if (missingManpower < node.population) {
                newPopulation -= missingManpower;
                newManpower = stats.manpower;
            }
            else {
                const conscripted = Math.floor(node.population);
                newPopulation -= conscripted;
                newManpower += conscripted;
            }
        }
        resources = gatherResult.newSourceStockpile;
        const maxResourceProgress = ResourceUtils.maxProgress(gatherResult.newTargetStockpile, trainCosts);
        const maxManpowerProgress = newManpower / stats.manpower;
        const maxProgress = Math.min(maxManpowerProgress, maxResourceProgress);
        const newProgress = Math.min(unit.progress + 1 / stats.trainTime, maxProgress);
        if (newProgress >= 1) {
            // spawn the actual unit
            const newUnit: Unit = {
                kind: unit.kind,
                currentHealth: stats.maxHealth,
                level: 0,
                experience: 0
            };
            spawnedUnits.push({ unit: newUnit, node: node.tag, faction: node.faction })
            return { ...node, trainingQueue: node.trainingQueue.slice(1) };
        }
        unit = { ...unit, deliveredResources: gatherResult.newTargetStockpile, progress: newProgress, conscriptedManpower: newManpower };
        return { ...node, trainingQueue: Utils.replaceIndexWith(node.trainingQueue, 0, unit), population: newPopulation };
    });

    gameState = { ...gameState, nodes: newNodes, platoons, resources };

    gameState = produce(gameState, gameState => {
        for (const { unit, node, faction } of spawnedUnits) {
            // Search for a platoon on this node to add it to.
            // If no platoon exists, create a new one.
            // If there's a battle on this location, look for platoons within it as well.
            let existingPlatoon: Platoon | undefined;
            const localBattle = Utils.values(gameState.battles).find(x =>
                x.position.type === "onNode"
                && x.position.node === node);
            if (localBattle !== undefined) {
                // try to find a unit within that battle
                const participants = localBattle.participants.map(x => gameState.platoons[x]);
                existingPlatoon = participants.find(x => x?.faction === faction);
            }
            let platoon: Platoon;
            const localPlatoons = Utils.values(gameState.platoons).filter(x =>
                x.position.type === "onNode"
                && x.position.node === node);
            if (existingPlatoon === undefined)
                existingPlatoon = localPlatoons.find(x => x.faction === faction);
            if (existingPlatoon === undefined) {
                const newPlatoonTag: string = Utils.getUnusedTag("p");
                platoon = {
                    tag: newPlatoonTag,
                    path: [],
                    position: { type: "onNode", node },
                    units: [unit],
                    faction
                };
                // If there are other platoons on this node, then start a battle.
                if (localPlatoons.length !== 0) {
                    const battleTag = Utils.getUnusedTag("b");
                    gameState.battles[battleTag] = {
                        tag: battleTag,
                        participants: [...localPlatoons.map(x => x.tag), newPlatoonTag],
                        position: { type: "onNode", node },
                        started: gameState.time
                    }
                    platoon.position = { type: "inBattle", battle: battleTag };
                    for (const enemy of localPlatoons) {
                        enemy.position = { type: "inBattle", battle: battleTag };
                    }
                }
            }
            else {
                platoon = {
                    ...existingPlatoon,
                    units: [
                        ...existingPlatoon.units,
                        unit
                    ]
                };
            }
            gameState.platoons[platoon.tag] = platoon;
        }
    });

    return gameState;
}
