import React from 'react';
import { ResourceUi } from './ResourceUi';
import { Modifier, ModifierSource, ModifierWithSource } from '../logic/Modifiers';
import { Stat, statDetails } from '../logic/StatDetails';
import { useGameState } from './useGameState';

export function ModifierWithSourceDisplay<T extends keyof Stat>(props: { modifier: ModifierWithSource<T> }) {
  return <div>
    <SourceDescription source={props.modifier.source} />:&nbsp;
    <RawModifierDisplay modifier={props.modifier} />
  </div>
}
function SourceDescription(props: { source: ModifierSource }) {
  const content = useGameState(gs => {
    switch (props.source.type) {
      case "fromSlotFeature":
        return <>{gs.slotFeatures[props.source.feature].name}</>
      case "fromTechnology":
        return <>{gs.technologies[props.source.technology].name}</>
      case "fromBuilding":
        return <>{gs.buildingTypes[props.source.buildingType].name} ({props.source.slot + 1})</>
      case "fromNodeFeature":
        return <>{gs.nodeFeatures[props.source.feature].name}</>
      case "fromJobs":
        return <>Durch Jobs ({props.source.assignedJobs}/{props.source.maxJobs})</>
      case "fromOtherStat":
        return <>Durch {statDetails[props.source.stat as keyof Stat].name}:</>
      case "fromUnitLevel":
        return <>Durch Level</>
    }
  });
  return content;
}
export function ModifierDisplay<T extends keyof Stat>(props: { modifier: Modifier<T> }) {
  return <div>
    {statDetails[props.modifier.property].name}: <RawModifierDisplay modifier={props.modifier} />
  </div>;
}
export function RawModifierDisplay<T extends keyof Stat>(props: { modifier: Modifier<T> }) {
  switch (props.modifier.type) {
    case "flat":
      if (typeof props.modifier.value === "object")
        return <ResourceUi resources={props.modifier.value} />;
      else
        return <>{signed(props.modifier.value)}</>;
    case "percentage":
      if (typeof props.modifier.value === "object")
        return <ResourceUi
          resources={props.modifier.value}
          AmountUi={({ amount }) => amount === undefined ? <>-</> : <>{signed(amount)} %</>} />
      else
        return <>{signed(props.modifier.value)} %</>;
    case "multiplier":
      if (typeof props.modifier.value === "object")
        return <ResourceUi
          resources={props.modifier.value}
          AmountUi={({ amount }) => amount === undefined ? <>-</> : <>×{amount}%</>} />
      else
        return <>×{rounded(props.modifier.value)}</>;
  }
}
function signed(n: number) {
  n *= 10;
  n = Math.round(n);
  n /= 10;
  if (n < 0)
    return n.toString();
  if (n > 0)
    return `+${n}`;
  return "0";
}
function rounded(x: number) {
  const digits = Math.log10(x);

  let multiplier = 10 ** (-Math.floor(digits) + 1);
  multiplier = Math.max(multiplier, 1)
  x *= multiplier;
  x = Math.round(x);
  x /= multiplier;
  return x.toString();
}
