import React from 'react';
import { Button, LinearProgress, Typography } from '@material-ui/core';
import { Building, SlotStats, BuildSlot, BuildSlotIdentifier, Construction, isTown, Mine, Node, Rubble, hasJobs, BuildingType } from '../../logic/Node';
import { useGameState } from '../useGameState';
import { ResourceDeliveryUi, ResourceUi } from '../ResourceUi';
import Utils from '../../utils';
import { ResourceUtils } from '../../logic/Resources';
import { isAvailable } from '../../logic/Technology';
import { BuildingIcon } from '../Icons';
import "./BuildSlotDetailsUi.css"
import "./purchaseSelection.css"
import { getSlotStats, getSlotStatsFor } from '../../logic/getSlotStats';
import Tooltip from '../Tooltip';
import { SlotFeatureIcon } from './FeatureIcon';
import { StatsValue } from '../StatsUi';
import { ModifierDisplay } from '../ModifierDisplay';
import { Stats } from '../../logic/makeStatsBuilder';
import { SubmitCommandContext } from '../SaveGame';

export function BuildSlotDetailsUi(props: { node: Node; slot: number; }) {
  const slot = props.node.buildSlots[props.slot];
  const site = slot.site;
  const slotId = { nodeTag: props.node.tag, slotIndex: props.slot };
  const buildingTypes = useGameState(gs => gs.buildingTypes);
  const stats = useGameState(gs => getSlotStatsFor(gs, { nodeTag: props.node.tag, slotIndex: props.slot }));
  let buildingName: string;
  let mainUi: JSX.Element | undefined;
  let iconUi: JSX.Element = <span></span>
  switch (site.type) {
    case "building":
      mainUi = <>
        <BuildingDetailsUi building={site} stats={stats} />
        <ToggleActivationButton slot={slotId} site={site} />
      </>;
      buildingName = buildingTypes[site.kind].name;
      iconUi = <BuildingIcon buildingType={buildingTypes[site.kind]} />
      break;
    case "construction":
      mainUi = <>
        <ConstructionDetailsUi construction={site} stats={stats} />
        <ToggleActivationButton slot={slotId} site={site} />
      </>;
      buildingName = buildingTypes[site.kind].name + " (im Bau)";
      iconUi = <BuildingIcon buildingType={buildingTypes[site.kind]} />
      break;
    case "rubble":
      mainUi = <RubbleDetailsUi rubble={site} />;
      buildingName = "(Geröll)";
      break;
    case "mine":
      mainUi = <MineDetailsUi mine={site} stats={stats} />;
      buildingName = "(Mine)";
      if (site.plannedBuildingType !== undefined)
        iconUi = <BuildingIcon buildingType={buildingTypes[site.plannedBuildingType]} />
      break;
    case "empty":
      mainUi = <></>
      buildingName = "<leer>";
  }
  return <div className="build-slot-details">
    <div className="build-slot-details-title">
      {iconUi}

      <Typography display="inline" variant="h4">{buildingName}</Typography>
      <div>
        <Tooltip tooltip="Der Index dieses Bauplatzes.">
          <div className="build-slot-details-title-index">
            {props.slot + 1}
          </div>
        </Tooltip>
        <Tooltip tooltip={<>
          <p>Die Priorität dieses Bauwerks. Gebäuden mit höherer Priorität werden zuerst Arbeiter zugeteilt.</p>
          <p>Linksklicken erhöht, rechtsklicken verringert die Priorität.</p>
        </>}>
          {hasJobs(slot.site) &&
            <PriorityUi slot={slotId} priority={slot.site.priority} />}
        </Tooltip>
      </div>
    </div>
    <div>
      <div>
        {slot.features.map(feature => <SlotFeatureIcon feature={feature} />)}
      </div>
      {mainUi}
    </div>
    {isTown(props.node) && props.node.faction === "player" && (site.type === "building" || site.type === "construction" || site.type === "rubble")
      && <DemolishButton node={props.node} slot={slotId} />}
    {isTown(props.node) && props.node.faction === "player" && ((site.type === "mine" || site.type === "empty" || site.type === "building") &&
      <ConstructionSelectionUi node={props.node} slot={slotId} />)}
  </div>;
}
function PriorityUi(props: { slot: BuildSlotIdentifier, priority: number }) {
  const submitCommand = React.useContext(SubmitCommandContext);
  const events = {
    onClick: () => submitCommand({ type: "setPriorityCommand", slot: props.slot, priority: Math.min(props.priority + 1, 2) }),
    onContextMenu: (e: React.MouseEvent) => {
      submitCommand({ type: "setPriorityCommand", slot: props.slot, priority: Math.max(props.priority - 1, -2) });
      e.preventDefault();
      e.stopPropagation();
    }
  }
  switch (props.priority) {
    case 2:
      return <div className="priority-display fa fa-angle-double-up" style={{ color: "lightgreen" }} {...events} />
    case 1:
      return <div className="priority-display fa fa-angle-up" style={{ color: "green" }} {...events} />
    case 0:
      return <div className="priority-display" style={{ color: "gray" }} {...events}>•</div>
    case -1:
      return <div className="priority-display fa fa-angle-down" style={{ color: "orange" }} {...events} />
    case -2:
      return <div className="priority-display fa fa-angle-double-down" style={{ color: "darkred" }} {...events} />
    default:
      return <div className="priority-display" {...events}>{props.priority}</div>;
  }
}
function ToggleActivationButton(props: { slot: BuildSlotIdentifier, site: Building | Construction }) {
  const submitCommand = React.useContext(SubmitCommandContext);
  return <div className="button-activate">
    <Button variant="contained" color="primary"
      onClick={() => submitCommand({
        type: "changeBuildingActivationCommand",
        slot: props.slot,
        active: !props.site.active
      })}
    >
      {props.site.active ? "Deaktivieren" : "Aktivieren"}
    </Button>
  </div>;
}

function BuildingDetailsUi(props: { building: Building, stats: Stats<SlotStats> }) {
  const type = useGameState(gs => gs.buildingTypes[props.building.kind]);
  const children = [
    <p key="desc">{type.description}</p>
  ]

  if (Object.keys(props.stats.productionPerTurn).length !== 0)
    children.push(<span key="prod">
      Produktion pro Runde: <StatsValue stats={props.stats} stat={"productionPerTurn"} />
    </span>);
  if (type.nodeModifiers !== undefined) {
    children.push(...type.nodeModifiers.map((mod, i) => <ModifierDisplay key={i} modifier={mod} />));
  }

  return <div className="building-details">{children}</div>;
}
function RubbleDetailsUi(props: { rubble: Rubble; }) {
  return <ResourceUi resources={props.rubble.resources} />;
}
function MineDetailsUi(props: { mine: Mine, stats: Stats<SlotStats> }) {
  const plannedType = useGameState(gs =>
    props.mine.plannedBuildingType && gs.buildingTypes[props.mine.plannedBuildingType]);
  return <>
    <div>Verbleibend: <ResourceUi resources={props.mine.resources} /></div>
    {plannedType && <div>Im Anschluss gebaut: {plannedType.name}</div>}
  </>;
}
function ConstructionDetailsUi(props: { construction: Construction, stats: Stats<SlotStats> }) {
  const remainingDays = Math.round((1 - props.construction.progress) * props.stats.buildTime);
  const progressPercent = Math.round(props.construction.progress * 100);
  return <div>
    <LinearProgress variant="buffer" value={progressPercent} valueBuffer={ResourceUtils.maxProgress(props.construction.resources, props.stats.buildCosts) * 100} />
    Zu {progressPercent} % fertiggestellt ({remainingDays} Tage verbleibend)
    <br />
    Geliefert: <ResourceDeliveryUi targetResources={props.stats.buildCosts} deliveredResources={props.construction.resources} />
  </div>;
}
function ConstructionSelectionUi(props: { node: Node, slot: BuildSlotIdentifier }) {
  const buildingTypes = useGameState(gs => gs.buildingTypes);
  const slot = props.node.buildSlots[props.slot.slotIndex];
  const site = slot.site;
  const existingType = (site.type === "building" || site.type === "construction") ? site.kind : undefined;
  return <div className="purchase-selection">
    <div>{existingType === undefined ? "Baue:" : "Ersetze durch:"}</div>
    <div className="purchase-selection-list">
      {Utils.mapObjectToList(buildingTypes, buildingType =>
        buildingType.tag !== existingType &&
        <ConstructionSelectionItem buildingType={buildingType} {...props} />
      )}
    </div>
  </div>
}

function ConstructionSelectionItem(props: {
  node: Node,
  slot: BuildSlotIdentifier,
  buildingType: BuildingType
}) {
  const submitCommand = React.useContext(SubmitCommandContext);
  const { node, buildingType } = props;
  const stats = useGameState(gs => {
    const slot = node.buildSlots[props.slot.slotIndex];
    if (isAvailable(buildingType.requiredTech, gs.research) !== "available")
      return undefined;
    if (buildingType.requiredFeature !== undefined) {
      if (props.node.features[buildingType.requiredFeature] === undefined
        && !slot.features.includes(buildingType.requiredFeature))
        return undefined;
    }

    if (slot.site.type === "building") {
      if (buildingType.upgradesFrom !== slot.site.kind)
        return undefined;
    }
    else if (buildingType.upgradesFrom !== undefined) {
      return undefined;
    }

    const hypotheticalSlot: BuildSlot = {
      ...slot, site: {
        active: true,
        assignedJobs: 0,
        type: "building",
        kind: buildingType.tag,
        priority: 0
      }
    };
    return getSlotStats(gs, hypotheticalSlot, node, true)
  });

  if (!stats) return undefined;

  return <div
    key={buildingType.tag}
    className="purchase-selection-item"
    onClick={() => {
      submitCommand({
        type: "buildCommand",
        slot: props.slot,
        target: buildingType.tag
      });
    }}>
    <BuildingIcon buildingType={buildingType} />
    <div>
      <div className="purchase-name">
        {buildingType.name}
      </div>
      <div>
        <div className="purchase-description">{buildingType.description}</div>
        {Object.keys(stats.productionPerTurn).length > 0 && <div>
          Produziert: <StatsValue stats={stats} stat={"productionPerTurn"} />
        </div>}
        <div>
          <div className="far fa-hourglass" />&nbsp;<StatsValue stats={stats} stat={"buildTime"} />
          &nbsp;
          <StatsValue stats={stats} stat={"buildCosts"} />
        </div>
      </div>
    </div>
  </div>
}

function DemolishButton(props: { node: Node, slot: BuildSlotIdentifier }) {
  const siteType = props.node.buildSlots[props.slot.slotIndex].site.type;
  const text = siteType === "building" ? "Abreißen" :
    siteType === "construction" ? "Abbrechen" :
      siteType === "rubble" ? "Abbauen" :
        "<ungültig>"
  const submitCommand = React.useContext(SubmitCommandContext);
  const onClick = () => submitCommand({ type: "buildCommand", slot: props.slot });
  return <div>
    <Button variant="contained" color="primary" onClick={onClick}>
      {text}
    </Button>
  </div>;
}
