import { GameState } from "../GameState";
import { Faction } from "../Faction";
import { produce } from "immer";
import { getUnitStats } from "../getUnitStats";
import _ from "lodash";
import { Unit } from "../Platoon";
import { isTown } from "../Node";
import { ResourceUtils } from "../Resources";

export interface DisbandCommand {
    type: "disbandCommand";
    platoon: string;
    unit?: number | number[];
}
export function applyDisbandCommand(gameState: GameState, command: DisbandCommand, sender: Faction): GameState {
    return produce(gameState, gameState => {
        const platoon = gameState.platoons[command.platoon];
        if (platoon === undefined) {
            console.warn(`Invalid command: Platoon ${command.platoon} does not exist`);
            return;
        }
        if (platoon.faction !== sender) {
            console.warn(`Invalid command: Platoon ${command.platoon} does not belong to faction ${sender}`);
            return;
        }

        let removedUnits: Unit[];
        if (command.unit === undefined) {
            removedUnits = platoon.units;
            platoon.units = []
        }
        else if (typeof command.unit === "number") {
            removedUnits = platoon.units.splice(command.unit, 1);
        }
        else {
            removedUnits = command.unit.map(i => platoon.units[i]);
            const removedIndices = new Set(command.unit);
            platoon.units = platoon.units.filter((u, i) => !removedIndices.has(i))
        }
        if (platoon.position.type === "onNode") {
            const node = gameState.nodes[platoon.position.node];
            if (isTown(node) && node.faction === sender) {
                const manpower = removedUnits.map(u => getUnitStats(gameState, u).manpower).reduce(_.add, 0);
                node.population += manpower;
            }
        }
        if (platoon.units.length === 0) {
            delete gameState.platoons[command.platoon];
        }
        const totalCosts = removedUnits.map(u => getUnitStats(gameState, u).trainCosts).reduce(ResourceUtils.add, {});
        gameState.resources = ResourceUtils.add(gameState.resources, ResourceUtils.scale(totalCosts, 0.5));
    });
}
