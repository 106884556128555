import React from 'react';
import { Button, Icon, Paper } from '@material-ui/core';
import { PlatoonUi } from './PlatoonUi';
import _ from 'lodash';
import { Platoon } from '../logic/Platoon';
import "./MultiPlatoonUi.css"
import { SubmitCommandContext } from './SaveGame';

export function MultiPlatoonUi(props: { platoons: Platoon[]; }) {
  const submitCommand = React.useContext(SubmitCommandContext);
  let positions = props.platoons.map(x => x.position.type === 'onNode' && x.position.node);
  positions = _.uniq(positions);
  const canMerge = positions.length === 1 && positions[0] !== false;
  return <div className="multi-platoon-ui">
    <Paper>
      <Button
        variant="contained"
        color="primary"
        disabled={!canMerge}
        onClick={canMerge ? (() => props.platoons.slice(1).forEach(other => submitCommand({ type: "transferUnitsCommand", targetPlatoon: props.platoons[0].tag, sourcePlatoon: other.tag, units: "all" })))
          : undefined}>
        <Icon className="fa fa-compress-alt" />
      </Button>
    </Paper>
    {props.platoons.map(platoon => <PlatoonUi key={platoon.tag} platoon={platoon} />)}
  </div>;
}
