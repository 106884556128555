export async function getProxyServerUrl() {
    if (process.env.NODE_ENV === "development") {
        // In development, try connecting to the local server. Else use the remote one
        const localServerAvailable = await fetch("http://localhost:3001/ready").then(x => x.ok).catch(() => false);
        if (localServerAvailable)
            return "ws://localhost:3001";
        else
            return "wss://dwarf-fortresses.wcke.de"
    }
    return window.location.origin.replace(/^http/, "ws");
}
