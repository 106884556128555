import React from 'react';
import Tooltip from './Tooltip';
import { ResourceUsage, Resources } from '../logic/Resources';
import Utils from '../utils';
import "./ResourceUi.css"

export function ResourceUi(props: { resources: Resources, AmountUi?: (props: { amount: number | undefined }) => JSX.Element }) {
  const AMOUNT_UI = props.AmountUi ?? AmountUi;
  return <div className="resources">
    {Utils.mapObjectToList(props.resources, (amount, resource) => <span key={resource} className="resource">
      <ResourceIcon resource={resource as string} />
      <AMOUNT_UI amount={amount} />
    </span>, "key")}
  </div>
}

export function ResourceDeliveryUi(props: { targetResources: Resources, deliveredResources: Resources }) {
  return <>{Utils.mapObjectToList(props.targetResources, (targetAmount, name) => <React.Fragment key={name}>
    <span className="resource">
      <ResourceIcon resource={name as string} />
      {props.deliveredResources[name] ?? 0}/{targetAmount}
    </span>
  </React.Fragment>)}</>;
}

export function ResourceUiWithUsages(props: { resources: Resources, usages: ResourceUsage }) {
  return <div className="resources">
    {Utils.mapObjectToList(props.resources, (amount, resource) => <span key={resource} className="resource">
      <Tooltip tooltip={<ResourceUsageTooltip resource={resource} {...props} />}>
        <ResourceIcon resource={resource as string} />
        <AmountUi amount={amount} />
      </Tooltip>
    </span>, "key")}
  </div>
}

function ResourceUsageTooltip(props: { resource: keyof Resources, usages: ResourceUsage }) {
  const _perDay = props.usages.usagePerTurn[props.resource];
  const perDay = _perDay ?? 0;
  const hasUsage = _perDay !== undefined;
  return <>
    <h3>{resourceName(props.resource)}</h3>
    {hasUsage ?
      <>
        <p>
          Veränderung: <AmountUi amount={perDay} /> pro Tag<br />
          <small>
            = <AmountUi amount={perDay * 30} /> pro Monat<br />
            = <AmountUi amount={perDay * 365} /> pro Jahr
          </small>
        </p>
        <div>
          Erzeuger und Verbraucher:
          <ResourceUsageEntries resource={props.resource} entries={props.usages.subEntries} />
        </div>
      </>
      : "Keine Veränderung."}
  </>
}

function ResourceUsageEntries(props: { resource: keyof Resources, entries: ResourceUsage[] | undefined }) {
  if (props.entries === undefined) return <></>
  return <ul>
    {props.entries.map(entry => {
      const usage = entry.usagePerTurn[props.resource];
      if (usage === undefined) return undefined;
      return <li key={entry.entryName}>
        {entry.entryName}: <AmountUi amount={usage} /> pro Tag
        <ResourceUsageEntries resource={props.resource} entries={entry.subEntries} />
      </li>
    })}
  </ul>
}

function resourceName(resource: keyof Resources) {
  switch (resource) {
    case "coal": return "Kohle"
    case "equipment": return "Ausrüstung"
    case "iron": return "Eisen"
    case "food": return "Nahrung"
    case "mechanicalComponents": return "Mechanische Komponenten"
    case "stone": return "Stein"
    case "sulphur": return "Schwefel"
    case "wood": return "Holz"
    default: return resource
  }
}

export function AmountUi(props: { amount: number | undefined }) {
  const { amount } = props;
  if (amount === undefined)
    return <>0</>
  const absAmount = Math.abs(amount);
  if (absAmount < 1000)
    return <>{amount.toFixed()}</>
  if (absAmount < 10000)
    return <Tooltip tooltip={amount.toFixed()}>
      <span>{(amount / 1000).toFixed(2)}k</span>
    </Tooltip>
  return <Tooltip tooltip={Math.trunc(amount)}>
    <span>{(amount / 1000).toFixed(1)}k</span>
  </Tooltip>

}
export const ResourceIcon = React.memo((props: { resource: string; }): JSX.Element => {
  switch (props.resource) {
    case "food":
      return <div className="resource-icon fa fa-apple-alt" style={{ color: "green" }} />;
    case "iron":
      return <div className="resource-icon fas fa-layer-group" style={{ color: "gray" }} />;
    case "stone":
      return <div className="resource-icon fas fa-mountain" style={{ color: "brown" }} />;
    default:
      return <img
        className="resource-icon"
        src={`${process.env.PUBLIC_URL}/icons/resources/${props.resource}.png`}
        alt={props.resource} />
  }
});
