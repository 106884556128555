import { Table, TableBody, TableCell, TableContainer, TableRow } from '@material-ui/core';
import { Town } from '../../logic/Node';
import { TimeDisplay } from '../TimeDisplay';
import { getNodeStats } from '../../logic/getNodeStats';
import { useGameState } from '../useGameState';
import { StatsValue } from '../StatsUi';

export function TownGeneralDetailsUi(props: { node: Town; }) {
  const stats = useGameState(gs => getNodeStats(gs, props.node));
  return <TableContainer>
    <Table size="small">
      <TableBody>
        <TableRow>
          <TableCell align="left">
            Gegründet
          </TableCell>
          <TableCell align="right">
            <TimeDisplay time={props.node.founded} />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell align="left">
            Bevölkerung
          </TableCell>
          <TableCell align="right">
            {Math.floor(props.node.population)}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell align="left">
            Annehmlichkeiten
          </TableCell>
          <TableCell align="right">
            <StatsValue stats={stats} stat="amenities" />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell align="left">
            Wohnraum
          </TableCell>
          <TableCell align="right">
            <StatsValue stats={stats} stat="housing" />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell align="left">
            Bevölkerungswachstum
          </TableCell>
          <TableCell align="right">
            <StatsValue stats={stats} stat="popGrowth" /> % p.a.
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell align="left">
            Nahrungsmittelverbrauch
          </TableCell>
          <TableCell align="right">
            <StatsValue stats={stats} stat="foodUse" />
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  </TableContainer>;
}
