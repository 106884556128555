import React from 'react';
import { Button, LinearProgress, Typography } from '@material-ui/core';
import { ResearchStatus, Technology } from '../logic/Technology';
import Utils from '../utils';
import "./ResearchUi.css"
import InfoBox from './InfoBox';
import { useGameState } from './useGameState';
import { UnitIcon, BuildingIcon } from './Icons';
import _ from 'lodash';
import { ModifierDisplay } from './ModifierDisplay';
import { SubmitCommandContext } from './SaveGame';

const ResearchInfoBox = new InfoBox<string>();


export function ResearchUi(props: { research: { [key: string]: ResearchStatus; }; researchQueue: string[]; technologies: { [key: string]: Technology; }; }) {
  return <ResearchInfoBox.Component
    selectionUi={selection => <ResearchDetailsUi
      technology={props.technologies[selection]}
      researchQueue={props.researchQueue}
    />}>
    <Typography variant="h3">Forschung</Typography>

    <TechTree research={props.research} researchQueue={props.researchQueue} technologies={props.technologies} />
  </ResearchInfoBox.Component>;
}

function TechTree(props: { research: { [key: string]: ResearchStatus; }; researchQueue: string[]; technologies: { [key: string]: Technology; }; }) {
  const queueMap = new Map(props.researchQueue.map((x, i) => [x, i]));
  const toggleSelection = React.useContext(ResearchInfoBox.ToggleLocalSelection);
  const positions = Utils.mapObjectToList(props.technologies, x => x.techTreePosition);
  const maxX = _.max(positions.map(([x, y]) => x)) ?? 0;
  const maxY = _.max(positions.map(([x, y]) => y)) ?? 0;
  return <div className="tech-tree" >
    <div className="tech-tree-items">
      <svg className="tech-tree-lines" style={{ height: maxY + 5, width: maxX + 5 }}>
        {Utils.mapObjectToList(props.technologies, tech =>
          tech.dependencies.map(tag => {
            const dependency = props.technologies[tag]
            const [x1, y1] = tech.techTreePosition;
            const [x2, y2] = dependency.techTreePosition;
            return <line key={tag} id="line1" x1={x1} x2={x2} y1={y1} y2={y2} stroke="gray" />
          })
        )}
      </svg>
      {Utils.mapObjectToList(props.technologies, tech => {
        const [left, top] = tech.techTreePosition;
        const status = props.research[tech.tag];
        const queuePos = queueMap.get(tech.tag);
        const isEnqueued = queuePos !== undefined;
        const isAvailable = tech.dependencies.map(x => (props.research[x] === "finished" || queueMap.has(x))).reduce((a, b) => a && b, true);
        const state = status === "finished" ? "finished" : isEnqueued ? "queued" : isAvailable ? "available" : "unavailable";
        return <div
          key={tech.tag}
          className={`tech-tree-item tech-tree-item-${state}`}
          style={{ left, top }}
          onClick={() => toggleSelection(tech.tag)}
        >
          {tech.name}
          {queuePos === undefined || (" " + (queuePos + 1))}
          {typeof status !== "number" || <LinearProgress variant="determinate" value={100 * status / tech.researchTime} />}
        </div>;
      })}
    </div>
  </div>
}

function ResearchDetailsUi(props: { technology: Technology, researchQueue: string[] }) {
  const submitCommand = React.useContext(SubmitCommandContext);
  const status = useGameState(gs => {
    if (gs.research[props.technology.tag] === "finished")
      return "finished" as const;
    else if (Utils.indexOf(props.researchQueue, props.technology.tag) !== undefined)
      return "queued" as const;
    else if (props.technology.dependencies.map(x => gs.research[x] === "finished" || Utils.indexOf(props.researchQueue, x) !== undefined).reduce((a, b) => a && b, true))
      return "available" as const;
    else
      return "unavailable" as const;
  })
  const progress = useGameState(gs => gs.research[props.technology.tag]);
  return <>
    <Typography variant="h4">{props.technology.name}</Typography>
    <Typography variant="body1" className="tech-details" component="div">
      <p>{props.technology.description}</p>
      <p>Erforschungszeit: {props.technology.researchTime} Tage</p>
    </Typography>
    {
      status === "available" && <Button
        variant="contained"
        onClick={() => submitCommand({ type: "enqueueResearchCommand", technology: props.technology.tag })}
      >
        Erforschen
      </Button>
    }
    {
      status === "queued" && <Button
        variant="contained"
        onClick={() => submitCommand({ type: "dequeueResearchCommand", technology: props.technology.tag })}
      >
        Forschung abbrechen
      </Button>
    }
    {
      status === "finished" && <Button
        variant="contained"
        disabled
      >
        Forschung abgeschlossen
      </Button>
    }
    {
      status === "unavailable" && <Button
        variant="contained"
        disabled
      >
        Voraussetzungen nicht erfüllt
      </Button>
    }
    {
      progress !== undefined && <div className="tech-details-progress">
        <LinearProgress
          variant="determinate"
          value={progress !== "finished" ? 100 * progress / props.technology.researchTime : 100} />
      </div>
    }
    <UnlockUi technology={props.technology} />
  </>
}

function UnlockUi(props: { technology: Technology }) {
  const { unlockedBuildings, unlockedUnits } = useGameState(gs => ({
    unlockedBuildings: Utils.mapObjectToList(gs.buildingTypes, x => x).filter(x => x.requiredTech === props.technology.tag),
    unlockedUnits: Utils.mapObjectToList(gs.unitTypes, x => x).filter(x => x.requiredTech === props.technology.tag),
  }));

  if (unlockedBuildings.length === 0 && unlockedUnits.length === 0 && !props.technology.modifiers?.length)
    return <></>

  return <div className="tech-details-unlocks">
    <Typography variant="h5">Ermöglicht:</Typography>
    <div className="tech-details-unlocks-container">
      {
        unlockedBuildings.map(x => <div className="tech-details-unlocks-building" key={x.tag}>
          <BuildingIcon buildingType={x} />
          {x.name}
        </div>)
      }
      {
        unlockedUnits.map(x => <div className="tech-details-unlocks-unit" key={x.tag}>
          <UnitIcon unitType={x} />{x.name}
        </div>)
      }
      {
        props.technology.modifiers?.map((x, i) => <div className="tech-details-unlocks-modifier" key={i}>
          <ModifierDisplay modifier={x} />
        </div >)
      }
    </div>
  </div>
}
