import { GameState } from "../GameState";
import { Faction } from "../Faction";
import { produce } from "immer";
import { BuildSlotIdentifier } from "../Node";
import validateSiteHasJobs from "./_validation/validateSiteHasJobs";
import { recalculateJobAssignments } from "./TickCommand/updateJobs";

export interface ChangeBuildingActivationCommand {
    type: "changeBuildingActivationCommand";
    slot: BuildSlotIdentifier;
    active: boolean;
}
export function applyBuildingActivationCommand(gameState: GameState, command: ChangeBuildingActivationCommand, sender: Faction): GameState {
    return produce(gameState, gameState => {
        const site = validateSiteHasJobs(gameState, command.slot, sender);
        if (site === undefined) return;
        site.active = command.active;
        const node = gameState.nodes[command.slot.nodeTag];
        gameState.nodes[command.slot.nodeTag] = recalculateJobAssignments(gameState, node);
    });
}
