import { Draft } from "immer";
import _ from "lodash";

export function mapObjectToList<TObject extends object, TResult>(object: TObject, mapping: (value: TObject[keyof TObject], key: keyof TObject) => TResult, sortBy?: "key" | "value" | ((value: TObject[keyof TObject], key: keyof TObject) => any)): TResult[] {
    let entries = Object.entries(object);
    if (sortBy === "key")
        entries = entries.sort(([key_a,], [key_b,]) => (key_a < key_b) ? -1 : 1);
    else if (sortBy === "value")
        entries = entries.sort(([, val_a], [, val_b]) => (val_a < val_b) ? -1 : 1);
    else if (sortBy !== undefined)
        entries = entries.sort(([key_a, val_a], [key_b, val_b]) => (sortBy(val_a, key_a as keyof TObject) < sortBy(val_a, key_b as keyof TObject)) ? -1 : 1);
    return entries.map(([key, value]) => mapping(value, key as keyof TObject));
}

export function mapObject<TValue>(oldMap: { [key: string]: TValue }, transform: (oldValue: TValue, key: string) => TValue): { [key: string]: TValue } {
    const newMap: typeof oldMap = {}
    for (const key in oldMap) {
        if (Object.prototype.hasOwnProperty.call(oldMap, key)) {
            newMap[key] = transform(oldMap[key], key);
        }
    }
    return newMap
}


/**
 * A tagged removable map is used to represent game objects that can vanish.
 * Use `Utils.values`, `Utils.resolve` and `Utils.resolveDraft` to comfortably work with instances of this type.
 */
export type RemovableTagMap<T extends { tag: string }> = {
    [tag: string]: T | undefined
}
/** Tag maps are used to store regular game objects that can be assumed to be permanently available. */
export type TagMap<T extends { tag: string }> = {
    [tag: string]: T
}

/**
 * Extracts the values from a TagMap. It is assumed that there are no undefined values *explicitely written* into it.
 * 
 * In development, this method also checks whether there are any undefined values in the tag map 
 * and crashes immediately if that is the case.
 */
export function values<T extends { tag: string }>(map: RemovableTagMap<T>) {
    const result = Object.values(map);
    if (process.env.NODE_ENV === "development" && result.some(x => x === undefined))
        throw new Error("Object contains explicitely undefined values");
    return Object.values(map) as T[];
}
/**
 * Resolves a list of tags againts a tagmap, i.e. returns the value from the map for each key in `tags`. 
 * If the key does not exist in the map, it will not be returned. The result contains three things:
 * - `resolved`: the resolved values
 * - `cleanedTags`: all tags that were able to be resolved
 * - `hasChanges`: a bool that indicates whether any tag could not be resolved, i.e. whether `cleanedTags` is equal to `tags`.
 * @param tags The tags to resolve.
 * @param map The tag map against which to resolve.
 */
export function resolve<T extends { tag: string }>(tags: string[], map: RemovableTagMap<T>): { resolved: T[], resolvedTags: string[], removedTags: string[], hasChanges: boolean } {
    const resolved = [];
    const resolvedTags = [];
    const removedTags = [];
    for (const tag of tags) {
        const result = map[tag];
        if (result === undefined) {
            removedTags.push(tag);
        }
        else {
            resolved.push(result);
            resolvedTags.push(tag);
        }
    }
    return {
        resolved,
        resolvedTags: removedTags.length > 0 ? resolvedTags : tags,
        hasChanges: removedTags.length > 0,
        removedTags
    }
}
/**
 * Resolves a list of tags against a tagmap, similar to `resolve`. 
 * However, it also removes un-resolvable tags from the list directly.
 * @param tags The list of tags that should be resolved. This list will be mutated; all non-existing entries will be removed.
 * @param map The map to resovle the tags against.
 */
export function resolveDraft<T extends { tag: string }>(
    tags: Draft<string[]>,
    map: RemovableTagMap<T>): T[] {
    const { resolved, resolvedTags, hasChanges } = resolve(tags, map);
    if (hasChanges) {
        tags.length = 0;
        tags.push(...resolvedTags);
    }
    return resolved;
}
/**
 * Resolves one tag map against another one, returns a pair of tuples. 
 * Similar to an SQL "Join" operation on the tags.
 * 
 * Entries from the extension map that are not present in the source map will be removed from the extension map.
 * 
 * @param extension The extension tag map. Tags that are not present in the source tag map will be removed from it.
 * @param source The source tag map against which to resolve.
 */
export function resolveExtensionDraft<T1 extends { tag: string }, T2 extends { tag: string }>(
    extension: Draft<RemovableTagMap<T1>>,
    source: RemovableTagMap<T2>
): [Draft<T1>, T2][] {
    const extensionValues = values(extension);
    const { hasChanges, removedTags, resolved } = resolve(extensionValues.map(x => x.tag), source);
    if (hasChanges) {
        for (const key of removedTags) {
            delete extension[key];
        }
    }
    return resolved.map((p, i) => [extensionValues[i], p] as [Draft<T1>, T2]);
}

/**
 * Joins two sets of tagged objects on their tag. 
 */
export function innerJoin<T1 extends { tag: string }, T2 extends { tag: string }>(
    a: (RemovableTagMap<T1> | T1[]), b: RemovableTagMap<T2>): [T1, T2][] {
    const _a = Array.isArray(a) ? a : values(a);
    const { resolved } = resolve(_a.map(x => x.tag), b);
    return resolved.map((bItem, i) => [_a[i], bItem] as [T1, T2]);
}

export function removeUndefined<T>(source: (T | undefined)[]): T[] {
    return source.filter(x => x !== undefined) as T[];
}

export function tagify<T extends { tag: string }>(source: T[]): TagMap<T> {
    return Object.fromEntries(source.map(x => [x.tag, x]));
}

export function isSubset<T>(a: T[] | undefined, b: T[]) {
    if (a === undefined)
        return true;
    if (a.length > b.length)
        return false;
    if (a.length === 0)
        return true;
    if (b.length === 1)
        return a[0] === b[0];
    const setB = new Set(b);
    return !a.some(x => !setB.has(x));
}

export function mapFilterObject<TValue>(oldMap: { [key: string]: TValue }, transform: (oldValue: TValue, key: string) => TValue | null): { [key: string]: TValue } {
    const newMap: typeof oldMap = {}
    for (const key in oldMap) {
        if (Object.prototype.hasOwnProperty.call(oldMap, key)) {
            const transformed = transform(oldMap[key], key);
            if (transformed !== null)
                newMap[key] = transformed;
        }
    }
    return newMap
}

export function replaceIndex<T>(data: T[], index: number, replacement: (item: T) => T): T[] {
    const result = [...data];
    result[index] = replacement(result[index]);
    return result;
}

export function removeIndex<T>(data: T[], index: number): T[] {
    return [...data.slice(0, index), ...data.slice(index + 1)]
}
export function replaceIndexWith<T>(data: T[], index: number, replacement: T): T[] {
    const result = [...data];
    result[index] = replacement;
    return result;
}

const lowestUnusedTagNumberPerPrefix = new Map<string, number>();
export function getUnusedTag(prefix: string) {
    const suffix = lowestUnusedTagNumberPerPrefix.get(prefix) ?? 0
    lowestUnusedTagNumberPerPrefix.set(prefix, suffix + 1)
    return prefix + suffix;
}

export function indexOf<T>(array: T[], item: T): number | undefined {
    const result = array.indexOf(item);
    if (result === -1) return undefined;
    else return result;
}

/**
 * Inverts a mapping, i.e. returns a map from values to their keys.
 *  
 * `_.groupBy(...)` sometimes does not do the job, since it converts all keys to strings. 
 * */
export function invertMapping<TKey extends (string | number | symbol), TValue>(source: { [key in TKey]: TValue }): Map<TValue, TKey[]> {
    const result = new Map();
    for (const [k, v] of Object.entries(source)) {
        const list = result.get(v);
        if (list !== undefined)
            list.push(k);
        else
            result.set(v, [k]);
    }
    return result;
}

export function pickRandomly<T>(array: T[]): T
export function pickRandomly<T>(array: T[] | undefined): T | undefined {
    if (array === undefined || array.length === 0)
        return undefined;
    return array[_.random(0, array.length - 1)];
}

export function stringHash(s: string) {
    // djb2 hash algorithm, http://www.cse.yorku.ca/~oz/hash.html
    let hash = 5381;
    for (let i = 0; i < s.length; i++) {
        const c = s.charCodeAt(i);
        hash = ((hash << 5) + hash) ^ c;
    }
    return hash;
}
