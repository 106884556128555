import { ModifierWithSource } from "./Modifiers";
import { getTechModifiers, isUnitTechModifier } from "./Technology";
import { defaultUnitStats, Unit, UnitStats, UnitType } from "./Platoon";
import { GameState } from "./GameState";
import makeStatsBuilder from "./makeStatsBuilder";
import Utils from "../utils";

const _makeUnitStats = makeStatsBuilder(defaultUnitStats);
function makeUnitStats(gameState: GameState, unitType: UnitType) {
    const builder = _makeUnitStats(unitType);

    // add tech modifiers
    builder.addModifiers(getTechModifiers(gameState.technologies, gameState.research)
        .filter(x => isUnitTechModifier(x)
            && (x.unitType === undefined || x.unitType === unitType?.tag)
            && (Utils.isSubset(x.features, unitType.features))
        ) as ModifierWithSource<keyof UnitStats>[]);

    return builder;
}

export function getUnitStats(gameState: GameState, unit: Unit) {
    const builder = makeUnitStats(gameState, gameState.unitTypes[unit.kind]);

    // add unit level modifiers
    builder.addModifiers([
        {
            property: "meleeAttack",
            type: "flat",
            value: unit.level/2,
            source: {type: "fromUnitLevel"}
        } as ModifierWithSource<"meleeAttack">,
        {
            property: "maxHealth",
            type: "flat",
            value: unit.level*2,
            source: {type: "fromUnitLevel"}
        } as ModifierWithSource<"maxHealth">
        
    ]);

    return builder.freeze();
}

export function getUnitTypeStats(
    gameState: GameState,
    unitType: UnitType
) {
    return makeUnitStats(gameState, unitType).freeze();
}

export function getUnitTypeStatsFor(gameState: GameState, unitType: string): UnitStats {
    return getUnitTypeStats(gameState, gameState.unitTypes[unitType]);
}
