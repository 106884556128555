import { useEffect, useRef } from "react";
import { create } from "zustand";
import { CommandSubmitter, SpeedContext, SpeedState, SubmitCommandContext } from '../ui/SaveGame';
import { VisibleGameState } from "../logic/fogOfWar";
import GameScreen from "../ui/GameScreen";
import { applyPatch } from "fast-json-patch";
import { produce } from "immer";
import { getProxyServerUrl } from "./getProxyServerUrl";

interface ClientState {
    isConnected: boolean,
    /** Timestamp of the next connection attempt */
    nextConnectionAttempt: number,

    receivedState: TransferredState | null
}

export interface TransferredState {
    visibleGameState: VisibleGameState
    speedState: SpeedState
}

const useClientStore = create<ClientState>((set, get) => ({
    isConnected: false,
    nextConnectionAttempt: 0,
    receivedState: null!,
}))

export function ClientComponent(props: { roomId: string, clientId: string }) {
    const { roomId, clientId } = props;
    const { isConnected, receivedState } = useClientStore();
    const nextConnectionAttempt = useClientStore(s => s.nextConnectionAttempt);

    const submitCommand = useRef<CommandSubmitter>();

    useEffect(() => {
        let ws: WebSocket | undefined;
        const timeout = setTimeout(connect, nextConnectionAttempt - Date.now());
        return () => {
            ws?.close();
            clearTimeout(timeout);
        }

        async function connect() {
            ws = new WebSocket(`${await getProxyServerUrl()}/client/${roomId}/`);
            ws.onmessage = rawMsg => {
                const message = JSON.parse(rawMsg.data);

                const newState: any = produce(useClientStore.getState().receivedState ?? {},
                    draft => {
                        applyPatch(draft, message)
                    });

                useClientStore.setState({ receivedState: newState });
            };
            ws.onopen = () => {
                ws!.send(JSON.stringify({ source: clientId }));

                useClientStore.setState({ isConnected: true });
            };
            ws.onclose = () => {
                useClientStore.setState({
                    isConnected: false,
                    nextConnectionAttempt: Date.now() + 1000,
                });
                submitCommand.current = undefined;
            }
            submitCommand.current = cmd => ws!.send(JSON.stringify(cmd));
        }

    }, [roomId, clientId, nextConnectionAttempt]);

    return <div style={{ color: "white" }}>
        Client {isConnected ? "connected" : "disconnected"}

        <SubmitCommandContext.Provider value={command => submitCommand.current?.(command)}>
            {receivedState && <SpeedContext.Provider value={receivedState.speedState}>
                <GameScreen
                    ingameMenu={() => <></>}
                    gameState={receivedState.visibleGameState}
                />
            </SpeedContext.Provider>}
        </SubmitCommandContext.Provider>
    </div>
}