import { TagMap } from "../../utils/utils";
import { flat, multiplier } from "../Modifiers";
import { NodeFeature } from "../Node";

export const defaultNodeFeatures: TagMap<NodeFeature> = {
    "faintLight": {
        tag: "faintLight",
        name: "Schwaches Licht",
        description: "Durch ein kleines Loch in der Decke scheint etwas Licht hinein.",
        slotModifiers: [
            {
                building: "farm",
                property: "productionPerTurn",
                type: "multiplier",
                value: 1.5
            }
        ],
        nodeModifiers: [
            flat("amenities", 2)
        ]
    },
    "visibleSky": {
        tag: "visibleSky",
        name: "Sichtbarer Himmel",
        description: "Ein Loch in der Decke ist so groß, dass man deutlich den Himmel sehen kann.",
        slotModifiers: [
            {
                building: "farm",
                property: "productionPerTurn",
                type: "multiplier",
                value: 3
            }
        ],
        nodeModifiers: [
            flat("amenities", 5)
        ]
    },
    "exit": {
        tag: "exit",
        name: "Ausgang zur Oberwelt",
        description: "Hier befindet sich ein Ausgang zur Oberwelt. Kann gefährlich werden, aber viele wichtige Ressourcen sind unter Tage nicht zu finden."
    },
    "protectedValley": {
        tag: "protectedValley",
        name: "Geschütztes Tal",
        description: "Hier befindet sich ein Ausgang zu einem lieblichen, unberührten Tal. Das umliegende Gebirge ist so hoch, dass die Orks es auf dem Weg niemals erreichen werden.",
        slotModifiers: [
            {
                building: "farm",
                property: "productionPerTurn",
                type: "multiplier",
                value: 9
            }
        ],
        nodeModifiers: [
            flat("amenities", 15)
        ]
    },
    "greatBath": {
        tag: "greatBath",
        name: "Großes Bad",
        description: "Hier wurden vor Jahrhunderten auf heißen Quellen großartige Badehäuser errichtet, denen heilende Kräfte zugeschrieben werden."
    },
    "forgeOfTheAncients": {
        tag: "forgeOfTheAncients",
        name: "Schmiede der Alten",
        description: "Hierher haben sich vor Jahrhunderten die größten Schmiede des Zwergenreiches zurückgezogen, um, ohne die Ablenkungen der Gesellschaft anderer, ihrer mittlerweile längst vergessenen Kunst nachzugehen."
    },
    "malnourished": {
        tag: "malnourished",
        name: "Unterernährt",
        description: "Die Bevölkerung dieser Stadt ist unterernährt und leidet darunter.",
        nodeModifiers: [
            flat("popGrowth", -50),
            multiplier("healing", 0.2)
        ],
        slotModifiers: [
            multiplier("buildTime", 5),
            multiplier("miningSpeed", 0.2)
        ],
        decayTime: 100
    }
};
