import React from 'react';
import { Paper, Typography } from '@material-ui/core';
import { Biome, hasJobs, isTown, Junction, Node, Town } from '../../logic/Node';
import { TrainingQueueOverviewUi, TrainUnitUi } from './TrainUnitUi';
import { BuildSlotDetailsUi } from './BuildSlotDetailsUi';
import { TownGeneralDetailsUi } from './TownGeneralDetailsUi';
import { BuildSlotsUi } from './BuildSlotsUi';
import InfoBox from '../InfoBox';
import "./index.css"
import { NodeFeatureIcon } from './FeatureIcon';
import { useGameState } from '../useGameState';
import _ from 'lodash';
import { getBiomeColor } from '../../visual/NodeOnCanvas';

type NodeUiSelection = { slot: number } | "TrainUnit";

const expandableUi = new InfoBox<NodeUiSelection>();
export const LocalSelection = expandableUi.LocalSelection;
export const ToggleLocalSelection = expandableUi.ToggleLocalSelection;

export function NodeUi(props: { node: Node }) {
  return <expandableUi.Component key={props.node.tag} selectionUi={selection => <SelectionUi node={props.node} selection={selection} />}>
    <NodeMainUi node={props.node} />
  </expandableUi.Component>;
}
function NodeMainUi(props: { node: Node }) {
  if (isTown(props.node))
    return <TownUi node={props.node} />;
  else
    return <JunctionUi node={props.node} />;
}
export function JunctionUi(props: { node: Junction }) {
  return <>
    <Typography variant="h3">(Kreuzung)</Typography>
    <NodeFeatures node={props.node} />
    <BiomeIcon biome={props.node.biome} />
    {
      props.node.buildSlots.length > 0 ? <>
        <br />
        <Typography variant="h4">Bauplätze</Typography>
        <BuildSlotsUi node={props.node} />
      </> : undefined
    }
  </>;
}

function BiomeIcon(props: { biome: Biome }) {
  return <div style={{ background: getBiomeColor(props.biome), color: "white" }}>
    Biom: {props.biome}
  </div>
}

export function TownUi(props: { node: Town }) {
  const assignedWorkers = props.node.buildSlots.map(x => hasJobs(x.site) ? x.site.assignedJobs : 0).reduce(_.add);
  const unassignedWorkers = props.node.population - assignedWorkers;
  return <div className={`faction-${props.node.faction}`}>
    <Typography variant="h3" className="node-title">{props.node.name}</Typography>
    <BiomeIcon biome={props.node.biome} />
    <Paper variant="elevation" elevation={5}>
      <p>{props.node.description}</p>
    </Paper>

    <NodeFeatures node={props.node} />

    <TownGeneralDetailsUi node={props.node} />

    <br />
    <Typography variant="h4">Bauplätze</Typography>
    <BuildSlotsUi node={props.node} />
    <div className="unassigned-workers">Untätig: {Math.floor(unassignedWorkers)}</div>

    <br />
    <Typography variant="h4">Ausbildung</Typography>
    <TrainingQueueOverviewUi node={props.node} />
  </div>;
}
function NodeFeatures(props: { node: Node; }) {
  const nodeFeatures = useGameState(gs => gs.nodeFeatures);
  return <div>
    {Object.entries(props.node.features).map(([x, props]) => <NodeFeatureIcon key={x} feature={nodeFeatures[x]} props={props} />)}
  </div>;
}

function SelectionUi(props: { node: Node, selection: NodeUiSelection }) {
  const toggleSelection = React.useContext(ToggleLocalSelection);
  if (props.selection === "TrainUnit") {
    if (!isTown(props.node)) {
      console.error("Tried training units outside of town!");
      toggleSelection(undefined);
      return <></>
    }
    else
      return <TrainUnitUi node={props.node} />
  }
  else
    return <BuildSlotDetailsUi node={props.node} slot={props.selection.slot} />
}

