import _ from "lodash";
import { GameState } from "../../GameState";
import { getSlotStatsFor } from "../../getSlotStats";
import { Building, isTown } from "../../Node";
import { ResourceUsage, ResourceUtils } from "../../Resources";
import { mapObjectToList } from "../../../utils/utils";

export function gatherResources(gameState: GameState): GameState {
    // get resources from buildings
    const allBuildSlots = Object.values(gameState.nodes)
        .filter(node => isTown(node) && node.faction === "player")
        .flatMap(town => town.buildSlots.map((slot, i) => ({ nodeTag: town.tag, slotIndex: i, site: slot.site })));
    const activeBuildings = allBuildSlots.filter(slot => slot.site.type === "building" && slot.site.active);
    const production = activeBuildings.map(slot => ({
        kind: (slot.site as Building).kind,
        productionPerTurn: getSlotStatsFor(gameState, slot).productionPerTurn
    }));
    const productionByKind = mapObjectToList(_.groupBy(production, "kind"), (group, kind) =>
        [kind as string, group.map(x => x.productionPerTurn).reduce(ResourceUtils.add)] as const);

    let resources = gameState.resources;
    let usages: ResourceUsage[] = [];
    for (const [buildingKind, totalProduction] of productionByKind) {
        const { newStockpile, effectiveDelta } = ResourceUtils.produceAndConsume(resources, totalProduction);
        resources = newStockpile;
        usages.push({ entryName: gameState.buildingTypes[buildingKind].name, usagePerTurn: effectiveDelta });
    }

    return {
        ...gameState,
        resources,
        resourceUsage: ResourceUtils.addUsages(gameState.resourceUsage, "Gebäude", usages),
    };
}
