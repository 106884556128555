import React from 'react';
import { Button, ButtonGroup, Icon } from '@material-ui/core';
import { useHotkey } from './Hotkey';
import Tooltip from './Tooltip';
import { SpeedContext, SubmitCommandContext } from './SaveGame';

const speedIcons = [
  "fa fa-angle-right",
  "fa fa-angle-double-right",
  "fa fa-fighter-jet",
]
export function TimeControlUi() {
  const submitCommand = React.useContext(SubmitCommandContext);
  const { speed: speedIndex, paused } = React.useContext(SpeedContext);

  const setSpeedIndex = (speed: number) => submitCommand({ type: "setSpeedCommand", speed })
  const setPaused = (paused: boolean) => submitCommand({ type: "setPausedCommand", paused })

  useHotkey(() => setPaused(!paused), " ");
  useHotkey(() => setSpeedIndex(0), "1");
  useHotkey(() => setSpeedIndex(1), "2");
  useHotkey(() => setSpeedIndex(2), "3");

  const buttons = speedIcons.map((icon, i) => {
    return <Button key={i}
      onClick={() => setSpeedIndex(i)}
      variant={speedIndex === i ? "contained" : "outlined"}>
      <Tooltip tooltip={`Hotkey: ${i + 1}`}>
        <Icon className={icon} />
      </Tooltip>
    </Button>
  });

  return <div>
    <ButtonGroup orientation="horizontal" size="small" color="primary">
      <Button onClick={() => setPaused(!paused)} variant={paused ? "contained" : "outlined"}>
        <Tooltip tooltip={`Hotkey: Leertaste`}>
          <Icon className="fa fa-pause" />
        </Tooltip>
      </Button>
    </ButtonGroup>
    &nbsp;
    <ButtonGroup orientation="horizontal" size="small" color="primary">
      {buttons}
    </ButtonGroup>
  </div>;
}

export default React.memo(TimeControlUi);
