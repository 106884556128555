import Utils from "../utils";
import { addModifierSource, ModifierSource, ModifierWithSource } from "./Modifiers";
import { GameState } from "./GameState";
import { SlotStats, BuildingType, BuildSlot, BuildSlotIdentifier, defaultSlotStats, resolveBuildSlot, scalesWithJobs, Node } from "./Node";
import { getTechModifiers, isSlotTechModifier } from "./Technology";
import makeStatsBuilder from "./makeStatsBuilder";

const makeBuildingStats = makeStatsBuilder(defaultSlotStats);

export function getSlotStats(
    gs: GameState,
    slot: BuildSlot,
    node: Node,
    ignoreJobs?: boolean) {

    let buildingType: BuildingType | undefined;
    if (slot.site.type !== "rubble"
        && slot.site.type !== "mine"
        && slot.site.type !== "empty") {
        buildingType = gs.buildingTypes[slot.site.kind];
    }
    const builder = makeBuildingStats(buildingType);


    // add tech modifiers
    builder.addModifiers(getTechModifiers(gs.technologies, gs.research)
        .filter(x => isSlotTechModifier(x)
            && (x.building === undefined || x.building === buildingType?.tag)
            && Utils.isSubset(x.features, slot.features)) as ModifierWithSource<keyof SlotStats>[])

    // add slot feature modifiers
    builder.addModifiers(slot.features
        .flatMap(feature => gs.slotFeatures[feature].modifiers.map(mod => ({
            ...mod,
            source: { type: "fromSlotFeature", feature } as ModifierSource
        })))
        .filter(x => x.building === undefined || x.building === buildingType?.tag))

    // add node feature modifiers
    builder.addModifiers(Object.entries(node.features).flatMap(([feature, props]) => addModifierSource(
        gs.nodeFeatures[feature].slotModifiers,
        { type: "fromNodeFeature", feature },
        props.strength)))

    // add job scaling modifiers
    function addJobScalingModifiers(assignedJobs: number, maxJobs: number) {
        if (assignedJobs === maxJobs || ignoreJobs) return;
        const scaling = assignedJobs / maxJobs;
        builder.addModifiers(Object.keys(scalesWithJobs)
            .map((stat): ModifierWithSource<keyof SlotStats> => ({
                property: stat as any,
                source: { type: "fromJobs", assignedJobs, maxJobs },
                type: "multiplier",
                value: scaling
            })))
    }
    switch (slot.site.type) {
        case "construction":
            addJobScalingModifiers(slot.site.assignedJobs, builder.constructionJobs)
            break;
        case "building":
            addJobScalingModifiers(slot.site.assignedJobs, builder.maxWorkerJobs)
            break;
        case "mine":
            addJobScalingModifiers(slot.site.assignedJobs, builder.miningJobs)
            break;
    }

    return builder.freeze();
}
export function getSlotStatsFor(gameState: GameState, slotIdentifier: BuildSlotIdentifier) {
    const { slot, node } = resolveBuildSlot(gameState, slotIdentifier);
    return getSlotStats(
        gameState,
        slot,
        node
    );
}
