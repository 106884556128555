import Utils from "../utils";

export interface Resources {
    [key: string]: number | undefined;
}

interface InsufficientResources {
    type: "InsufficientResources",
    missing: Resources
}

interface UsedStockpile {
    type: "UsedStockpile",
    newStockpile: Resources
}

interface ResourceGatherResult {
    newSourceStockpile: Resources,
    newTargetStockpile: Resources,
    deliveryComplete: boolean
}

export interface ResourceUsage {
    entryName: string
    usagePerTurn: Resources
    subEntries?: ResourceUsage[]
}

export class ResourceUtils {
    private constructor() { throw new Error("This class is just intended to be a namespace."); }
    public static add(a: Resources | undefined, b: Resources | undefined): Resources {
        if (a === undefined)
            return {};
        if (b === undefined)
            return a;

        const result = { ...a };
        for (const key in b) {
            result[key] = (a[key] ?? 0) + (b[key] ?? 0)
        }
        return result;
    }
    public static diff(a: Resources, b: Resources) {
        const result: Resources = {};
        for (const key in a) {
            result[key] = a[key] ?? 0
        }
        for (const key in b) {
            result[key] = (result[key] ?? 0) - (b[key] ?? 0);
        }
        return result;
    }
    /** 
     * Flushes all resources to zero that are less than the specified epsilon. 
     * Returns undefined when no resources are left. 
     **/
    public static flushToZero(resources: Resources, epsilon: number = 1e-5) {
        const result: Resources = {};
        let hasResources = false;
        for (const key in resources) {
            const value = resources[key];
            if (value === undefined) continue;
            if (Math.abs(value) < epsilon) continue;
            result[key] = resources[key];
            hasResources = true;
        }
        return hasResources ? result : undefined;
    }
    public static addUsages(resourceUsages: ResourceUsage, name: string, amountOrEntries: Resources | ResourceUsage[]): ResourceUsage {
        let totalAmount: Resources;
        let subEntries: ResourceUsage[] | undefined;
        if (Array.isArray(amountOrEntries)) {
            subEntries = amountOrEntries;
            totalAmount = subEntries.map(x => x.usagePerTurn).reduce(ResourceUtils.add, {});
        }
        else {
            totalAmount = amountOrEntries;
        }
        const newEntry = {
            entryName: name,
            usagePerTurn: totalAmount,
            subEntries: subEntries
        };
        return {
            entryName: resourceUsages.entryName,
            usagePerTurn: ResourceUtils.add(resourceUsages.usagePerTurn, totalAmount),
            subEntries: [...(resourceUsages.subEntries ?? []), newEntry]
        }
    }
    public static emptyUsage(entryName: string): ResourceUsage {
        return { entryName, usagePerTurn: {} }
    }
    public static produceAndConsume(stockpile: Resources, delta: Resources): { newStockpile: Resources, efficiency: number, effectiveDelta: Resources } {
        let efficiency = 1;
        for (const key in delta) {
            const deltaResource = delta[key]!;
            if (deltaResource >= 0) continue;
            const available = stockpile[key] ?? 0;
            const efficiencyResource = available / -deltaResource;
            efficiency = Math.min(efficiency, efficiencyResource);
            if (efficiency === 0) {
                return { newStockpile: stockpile, efficiency, effectiveDelta: {} };
            }
        }

        const effectiveDelta = ResourceUtils.scale(delta, efficiency);
        const newStockpile = ResourceUtils.add(stockpile, effectiveDelta);
        return { newStockpile, efficiency, effectiveDelta };
    }

    public static use(stockpile: Resources, required: Resources): UsedStockpile | InsufficientResources {
        const newStockpile = { ...stockpile };
        let missing: Resources | null = null

        for (const key in required) {
            const newStock = (stockpile[key] ?? 0) - (required[key] ?? 0)

            if (newStock < 0) {
                missing = missing ?? {};
                missing[key] = -newStock;
            }
            else if (missing === null)
                newStockpile[key] = newStock;
        }
        if (missing !== null)
            return { type: "InsufficientResources", missing }
        else
            return { type: "UsedStockpile", newStockpile };
    }

    public static gather(sourceStockpile: Resources, targetStockpile: Resources, requiredResources: Resources): ResourceGatherResult {
        const newSourceStockpile = { ...sourceStockpile };
        const newTargetStockpile: Resources = {};
        let deliveryComplete = true;

        for (const key in requiredResources) {
            const available = sourceStockpile[key];
            const required = requiredResources[key]!;
            const existing = targetStockpile[key] ?? 0;
            const remaining = required - existing;

            if (available === undefined)
                continue;
            if (available >= remaining) {
                newTargetStockpile[key] = required;
                newSourceStockpile[key] = available - remaining;
            }
            else {
                newTargetStockpile[key] = existing + available;
                newSourceStockpile[key] = 0;
                deliveryComplete = false;
            }
        }
        return {
            newSourceStockpile,
            newTargetStockpile,
            deliveryComplete,
        }
    }

    /** Resources can be scored with this method, making them comparable. 
     * This is used to calculate the maximum build progress. */
    public static score(resources: Resources): number {
        return Object.values(resources).reduce<number>((a, b) => (a ?? 0) + (b ?? 0), 0);
    }

    public static maxProgress(delivered: Resources, required: Resources): number {
        const requiredScore = ResourceUtils.score(required);
        if (requiredScore === 0)
            return 1;
        else
            return ResourceUtils.score(delivered) / requiredScore;
    }

    public static doMining(deposit: Resources, mineAmount: number): { extracted: Resources, remaining: Resources | undefined } {
        let remainingToBeMined = mineAmount;
        const extracted: Resources = {}
        const remaining = { ...deposit };
        // Mine the most common resource first.
        const sortedDeposit = Object.entries(deposit).sort(([ka, va], [kb, vb]) => (vb ?? 0) - (va ?? 0));
        for (const [key, depositAmount] of sortedDeposit) {
            if (depositAmount === undefined) continue;
            else if (depositAmount > remainingToBeMined) {
                extracted[key] = remainingToBeMined;
                remaining[key] = depositAmount - remainingToBeMined;
                return { extracted, remaining }
            }
            else {
                extracted[key] = depositAmount;
                remainingToBeMined -= depositAmount;
                delete remaining[key];
            }
        }
        return { extracted, remaining: undefined };
    }

    public static scale(resources: Resources, factor: number) {
        if (factor === 1) return resources;
        return Utils.mapObject(resources, x => (x ?? 0) * factor);
    }
}
