import { UnitType } from "../Platoon";

export const defaultUnitTypes: { [key: string]: UnitType; } = {
    "settler": {
        tag: "settler",
        name: "Siedler",
        description: "Eine Einheit Siedler. Kann nicht kämpfen, aber dafür neue Städte gründen.",
        maxHealth: 100,
        trainCosts: {
            "food": 30
        },
        trainTime: 3,
        features: ["settler"],
        meleeAttack: 0,
        meleeRange: 1,
        defense: 0,
        moveSpeed: 20,
        manpower: 10,
    },
    /*
    "guard": {
        tag: "guard",
        name: "Stadtwache",
        description: "Eine Einheit der Stadtwache. Solide in der Verteidigung.",
        maxHealth: 100,
        trainCosts: {
            "food": 150,
            "iron": 10
        },
        trainTime: 15,
        features: [],
        attack: 10,
        defense: 5,
        moveSpeed: 10,
    },*/

    "scout": {
        tag: "scout",
        name: "Späher",
        description: "Ein Späher, der die verlassenen Stollen erkundet.",
        maxHealth: 100,
        trainCosts: {
            "food": 150,
            "equipment": 5,
            "wood": 10
        },
        trainTime: 5,
        features: [],
        meleeAttack: 5,
        meleeRange: 1,
        defense: 5,
        moveSpeed: 15,
    },

    "heavy guard": {
        tag: "heavy guard",
        name: "Stadtwache",
        description: "Eine Einheit der Stadtwache. Solide in der Verteidigung.",
        maxHealth: 100,
        trainCosts: {
            "food": 150,
            "equipment": 10,
            "wood": 10
        },
        trainTime: 15,
        features: [],
        meleeAttack: 10,
        meleeRange: 2,
        defense: 15,
        moveSpeed: 8,
    },

    "warrior": {
        tag: "warrior",
        name: "Krieger",
        description: "Ein Krieger, der sich freiwillig gemeldet hat um bei der Rückeroberung des Zwergenreiches zu helfen.",
        maxHealth: 110,
        trainCosts: {
            "food": 200,
            "equipment": 20,
            "wood": 5
        },
        trainTime: 15,
        features: [],
        meleeAttack: 15,
        meleeRange: 1,
        defense: 20,
        moveSpeed: 7,
        requiredTech: "reconquest"
    },

    "soldier": {
        tag: "soldier",
        name: "Soldat",
        description: "Ein gut ausgebildeter Berufssoldat, der es sich zur Lebensaufgabe gemacht hat seinen Beitrag zur Rückeroberung des Zwergenreiches zu leisten.",
        maxHealth: 120,
        trainCosts: {
            "food": 250,
            "equipment": 30
        },
        trainTime: 30,
        features: [],
        meleeAttack: 20,
        meleeRange: 1,
        defense: 25,
        moveSpeed: 6,
        requiredTech: "standingArmy"
    },

    "crossbow": {
        tag: "crossbow",
        name: "Armbrustschütze",
        description: "Ein Soldat mit einer Armbrust. Kann gut austeilen, dafür aber wenig einstecken.",
        maxHealth: 100,
        trainCosts: {
            "food": 150,
            "equipment": 5,
            "mechanicalComponents": 5
        },
        trainTime: 15,
        features: [],
        meleeAttack: 5,
        meleeRange: 1,
        rangedAttack: 20,
        rangedRange: 15,
        rangedStartupTime: 3,
        rangedReloadTime: 3,
        defense: 0,
        moveSpeed: 15,
    },

    "musketeer": {
        tag: "musketeer",
        name: "Musketier",
        description: "Ein Soldat mit einer Muskete. Kann kräftig austeilen, dafür aber wenig einstecken.",
        maxHealth: 100,
        trainCosts: {
            "food": 150,
            "equipment": 5,
            "mechanicalComponents": 5,
            "coal": 5,
            "sulphur": 5
        },
        trainTime: 15,
        features: ["gunpowder"],
        meleeAttack: 5,
        meleeRange: 1,
        rangedAttack: 70,
        rangedRange: 20,
        rangedStartupTime: 3,
        rangedReloadTime: 6,
        defense: 5,
        requiredTech: "matchlock",
        moveSpeed: 12,
    },

    "ballista": {
        tag: "ballista",
        name: "Balliste",
        description: "Ein Geschütz welches schwere Bolzen auf unsere Feinde niederregnen lässt",
        maxHealth: 100,
        trainCosts: {
            "food": 150,
            "equipment": 5,
            "mechanicalComponents": 50,
            "wood": 20
        },
        trainTime: 50,
        features: [],
        meleeAttack: 5,
        meleeRange: 1,
        rangedAttack: 150,
        rangedRange: 40,
        rangedStartupTime: 9,
        rangedReloadTime: 9,
        defense: 5,
        moveSpeed: 12,
    },

    "grenadier": {
        tag: "grenadier",
        name: "Grenadier",
        description: "Dieser Soldat wirft mit Handgranaten und fügt seinen Feinden enormen Schaden zu.",
        maxHealth: 100,
        trainCosts: {
            "food": 150,
            "equipment": 5,
            "mechanicalComponents": 5,
            "coal": 10,
            "sulphur": 10
        },
        trainTime: 15,
        features: ["gunpowder"],
        meleeAttack: 5,
        meleeRange: 1,
        rangedAttack: 80,
        rangedRange: 8,
        rangedStartupTime: 3,
        rangedReloadTime: 2,
        defense: 5,
        requiredTech: "grenades",
        moveSpeed: 12,
    },

    "orc": {
        tag: "orc",
        name: "Ork",
        description: "Eine kräftige grünhäutige Kreatur.",
        maxHealth: 130,
        trainCosts: {
            "food": 150,
            "iron": 10
        },
        trainTime: 15,
        features: [],
        meleeAttack: 20,
        meleeRange: 1,
        defense: 5,
        requiredTech: null,
        moveSpeed: 12,
    },

    "heavy orc": {
        tag: "heavy orc",
        name: "Schwerer Ork",
        description: "Ein mit einem kruden Schwert bewaffneter Ork.",
        maxHealth: 150,
        trainCosts: {
            "food": 150,
            "iron": 10
        },
        trainTime: 15,
        features: [],
        meleeAttack: 30,
        meleeRange: 1,
        defense: 10,
        requiredTech: null,
        moveSpeed: 12,
    },

    "goblin": {
        tag: "goblin",
        name: "Kobold",
        description: "Eine kleine, flinke, fiese Kreatur.",
        maxHealth: 60,
        trainCosts: {
            "food": 150,
            "iron": 10
        },
        trainTime: 15,
        features: [],
        meleeAttack: 7,
        meleeRange: 2,
        defense: 1,
        requiredTech: null,
        moveSpeed: 20,
    },

    "goblinAtlatl": {
        tag: "goblinAtlatl",
        name: "Kobold Speerschleuderer",
        description: "Ein kleiner Kobold der Wurfspeere schleudert.",
        maxHealth: 50,
        trainCosts: {
            "food": 150,
            "equipment": 5,
            "mechanicalComponents": 5
        },
        trainTime: 15,
        features: [],
        meleeAttack: 5,
        meleeRange: 1,
        rangedAttack: 6,
        rangedRange: 10,
        rangedStartupTime: 0,
        rangedReloadTime: 0,
        defense: 0,
        moveSpeed: 15,
        requiredTech: null,
    },

    "orcArcher": {
        tag: "orcArcher",
        name: "Orkischer Bogenschütze",
        description: "Ein Ork mit einem Bogen.",
        maxHealth: 110,
        trainCosts: {
            "food": 150,
            "equipment": 5,
            "mechanicalComponents": 5
        },
        trainTime: 15,
        features: [],
        meleeAttack: 10,
        meleeRange: 1,
        rangedAttack: 12,
        rangedRange: 12,
        rangedStartupTime: 0,
        rangedReloadTime: 0,
        defense: 3,
        moveSpeed: 15,
        requiredTech: null,
    }
};
