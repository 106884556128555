import React from 'react';
import './GameScreen.css';
import { Button, Icon, Paper } from '@material-ui/core';
import '@fortawesome/fontawesome-free/css/all.css'
import '@fontsource/roboto/300.css';
import { CanvasElement } from '../visual/Canvas';
import { gameStateOnCanvas } from '../visual/GameStateOnCanvas';
import { NodeUi } from './NodeUi';
import { VisibleGameState } from '../logic/fogOfWar';
import { TimeDisplay } from './TimeDisplay';
import { useGameState, useProvideGameState } from './useGameState';
import { ResourceUiWithUsages } from './ResourceUi';
import { PlatoonUi } from './PlatoonUi';
import { GameObject } from '../logic/GameObject';
import TimeControlUi from './TimeControlUi';
import { ResearchUi } from './ResearchUi';
import { MultiPlatoonUi } from './MultiPlatoonUi';
import { SpeedContext, SubmitCommandContext } from './SaveGame';
import Utils from '../utils';
import Tooltip from './Tooltip';
import { useHotkey } from './Hotkey';

type UiSelection = { type: "cheats" } | { type: "platoons", tags: string[] } | { type: "research" } | GameObject | undefined

export const SelectContext = React.createContext<(newSelection: UiSelection) => void>(() => { });
export default function GameScreen(props: {
  gameState: VisibleGameState;
  ingameMenu: (close: () => void) => JSX.Element;
  cheatUi?: JSX.Element;
}) {
  const { gameState } = props;
  useProvideGameState(gameState);
  const submitCommand = React.useContext(SubmitCommandContext);

  const [selected, select] = React.useState<UiSelection>(undefined);

  const speedState = React.useContext(SpeedContext);

  const [menuOpen, _setMenuOpen] = React.useState(false);
  const [wasPaused, setWasPaused] = React.useState(false);
  const setMenuOpen = React.useCallback((open: boolean) => {
    if (open === menuOpen) return;
    if (open) {
      submitCommand({ type: "setPausedCommand", paused: true })
      setWasPaused(speedState.paused);
    }
    else {
      submitCommand({ type: "setPausedCommand", paused: wasPaused })
    }
    _setMenuOpen(open);
  }, [menuOpen, speedState, submitCommand, setWasPaused, wasPaused]);

  const toggleMenu = React.useCallback(() => setMenuOpen(!menuOpen), [setMenuOpen, menuOpen])
  useHotkey(toggleMenu, "Escape")

  const visualSelection = {
    nodes: new Set<string>(),
    platoons: new Set<string>()
  };
  switch (selected?.type) {
    case "platoon":
      visualSelection.platoons.add(selected.tag);
      break;
    case "node":
      visualSelection.nodes.add(selected.tag);
      break;
    case "platoons":
      selected.tags.forEach(x => visualSelection.platoons.add(x));
      break;
  }

  return (
    <SelectContext.Provider value={select}>
      <div className={"App " + (menuOpen ? "ingame-menu-open" : "")}>
        <CanvasElement
          key="mainCanvas"
          items={gameStateOnCanvas(gameState, visualSelection)}
          onClick={target => select(target)}
          onRightClick={target => {
            if (selected?.type === "platoon"
              && target?.type === "node"
              && gameState.platoons[selected.tag]?.faction === "player") {
              submitCommand({ type: "moveCommand", platoon: selected.tag, targetNode: target.tag });
            }
          }}
          onSelect={selection => {
            if (selection.length === 1)
              select(selection[0]);
            else {
              const platoons = selection.filter(item => item.type === "platoon").map(item => item.tag);
              select({ type: "platoons", tags: platoons });
            }
          }}
        />
        <div className="Popup time-controls">
          <div>
            <TimeDisplay time={gameState.time} />
          </div>
          <TimeControlUi />
        </div>
        <div className="Popup selection-ui">
          <SelectionUi selected={selected} select={select} cheatUi={props.cheatUi} />
        </div>
        <div className="Popup top-bar">
          <Paper style={{ display: "inline-block" }}>
            <ResourceUiWithUsages resources={gameState.resources} usages={gameState.resourceUsage} />
          </Paper>
          &nbsp;
          <Paper style={{ display: "inline-block" }}>
            <Button onClick={() => select(selection => selection?.type === "research" ? undefined : { type: "research" })}
              color={selected?.type === "research" ? "primary" : "default"}
              variant="contained">
              <Icon className="fa fa-flask" /> Forschung
            </Button>
            <Button onClick={() => select(selection => selection?.type === "cheats" ? undefined : { type: "cheats" })}
              color={selected?.type === "cheats" ? "primary" : "default"}
              variant="contained">
              Cheats
            </Button>
          </Paper>
        </div>
        <div className="Popup open-ingame-menu">
          <Tooltip tooltip="Menü öffnen">
            <div className="button fas fa-bars" onClick={() => setMenuOpen(true)} />
          </Tooltip>
        </div>
      </div>
      {menuOpen && <div className="ingame-menu-wrapper">
        {props.ingameMenu(() => setMenuOpen(false))}
      </div>}
    </SelectContext.Provider>
  );
}

function SelectionUi(props: {
  selected: UiSelection,
  select: (selection: UiSelection) => void,
  cheatUi?: JSX.Element
}) {
  const { selected, select } = props;
  const gameState = useGameState();
  switch (selected?.type) {
    case "node":
      return <NodeUi node={gameState.nodes[selected.tag]} key={selected.tag} />;
    case "platoon":
      const platoon = gameState.platoons[selected.tag];
      if (platoon === undefined) {
        select(undefined);
        return <></>
      }
      else
        return <PlatoonUi platoon={platoon} key={selected.tag} />;
    case "platoons":
      const { resolved, resolvedTags, hasChanges } = Utils.resolve(selected.tags, gameState.platoons);
      if (hasChanges || selected.tags.length <= 1) {
        if (resolvedTags.length === 0)
          select(undefined);
        else if (resolvedTags.length === 1)
          select({ type: "platoon", tag: resolvedTags[0] });
        else
          select({ type: "platoons", tags: resolvedTags })
      }
      return <MultiPlatoonUi platoons={resolved} />
    case "research":
      return <ResearchUi research={gameState.research} researchQueue={gameState.researchQueue} technologies={gameState.technologies} />;
    case "cheats":
      return props.cheatUi;
  }
}
