import React from 'react';
import { Building, SlotStats, BuildSlotIdentifier, Construction, Mine, Node, resolveBuildSlot } from '../../logic/Node';
import { useGameState } from '../useGameState';
import { ResourceDeliveryUi, ResourceUi } from '../ResourceUi';
import { Slots } from '../Slots';
import { ToggleLocalSelection, LocalSelection } from './index';
import { BuildingIcon } from '../Icons';
import "./BuildSlotUi.css"
import { getSlotStatsFor } from '../../logic/getSlotStats';
import { Stats } from '../../logic/makeStatsBuilder';
import { SlotFeatureIcon } from './FeatureIcon';
import { SubmitCommandContext } from '../SaveGame';

export function BuildSlotsUi(props: { node: Node; }) {
  return <Slots>{
    props.node.buildSlots.map((slot, i) =>
      <BuildSlotUi slotIdentifier={{ nodeTag: props.node.tag, slotIndex: i }} />
    )}</Slots>;
}

function BuildSlotUi(props: { slotIdentifier: BuildSlotIdentifier }) {
  const toggleSelection = React.useContext(ToggleLocalSelection);
  const selection = React.useContext(LocalSelection);

  const { slotIndex } = props.slotIdentifier;

  const stats = useGameState(gs => getSlotStatsFor(gs, props.slotIdentifier));
  const isSelected = typeof selection === "object" && selection.slot === slotIndex;
  const slot = useGameState(gs => resolveBuildSlot(gs, props.slotIdentifier).slot);
  const site = slot.site;
  return <div
    className={`buildSlot ${isSelected ? "buildSlot-selected" : ""}`}
    style={{ display: "inline-block" }}
    onClick={() => toggleSelection({ slot: slotIndex })}
  >
    <div className="buildSlot-site">
      {site.type === "rubble" ? <ResourceUi resources={site.resources} /> :
        site.type === "mine" ? <MineUi mine={site} stats={stats} slot={props.slotIdentifier} /> :
          site.type === "construction" ? <ConstructionUi construction={site} stats={stats} slot={props.slotIdentifier} /> :
            site.type === "building" ? <BuildingUi building={site} stats={stats} slot={props.slotIdentifier} /> :
              site.type === "empty" ? <div></div> : "warning: unknown site type"}
    </div>
    <div className="buildSlot-features">
      {slot.features.map(x => <SlotFeatureIcon key={x} feature={x} />)}
    </div>
  </div>;
}

function JobsOverlay(props: { assigned: number, max: number }) {
  return <div className="jobs-overlay">{props.assigned}/{props.max}</div>
}
function BuildingUi(props: { building: Building, stats: Stats<SlotStats>, slot: BuildSlotIdentifier }) {
  return <BuildingActivationOverlay active={props.building.active} slot={props.slot} className="building">
    <JobsOverlay assigned={props.building.assignedJobs} max={props.stats.maxWorkerJobs} />
    <BuildingIconUi kind={props.building.kind} />
  </BuildingActivationOverlay>
}
function BuildingActivationOverlay(props: { className: string, active: boolean, slot: BuildSlotIdentifier, children: React.ReactNode }) {
  const submitCommand = React.useContext(SubmitCommandContext);
  return <div className={`${props.className} building-${props.active ? "active" : "inactive"}`}>
    <div className="building-activation-overlay fas fa-ban" onClick={e => { submitCommand({ type: "changeBuildingActivationCommand", slot: props.slot, active: !props.active }); e.stopPropagation(); }} />
    {props.children}
  </div>
}
function BuildingIconUi(props: { kind: string; }) {
  const building = useGameState(gs => gs.buildingTypes[props.kind]);
  return <BuildingIcon buildingType={building} />;
}
function MineUi(props: { mine: Mine, stats: Stats<SlotStats>, slot: BuildSlotIdentifier }) {
  return <BuildingActivationOverlay className="mine" active={props.mine.active} slot={props.slot}>
    {props.mine.plannedBuildingType && <BuildingIconUi kind={props.mine.plannedBuildingType} />}
    <div className="mine-status">{<ResourceUi resources={props.mine.resources} />}</div>
    <JobsOverlay assigned={props.mine.assignedJobs} max={props.stats.miningJobs} />
  </BuildingActivationOverlay>
}
function ConstructionUi(props: { construction: Construction, stats: Stats<SlotStats>, slot: BuildSlotIdentifier }) {
  const progressPercent = Math.round(props.construction.progress * 100);
  return <BuildingActivationOverlay className="construction" active={props.construction.active} slot={props.slot}>
    <BuildingIconUi kind={props.construction.kind} />
    <div className="construction-status">
      ({progressPercent} %)<br />
      <ResourceDeliveryUi targetResources={props.stats.buildCosts} deliveredResources={props.construction.resources} />
    </div>
    <JobsOverlay assigned={props.construction.assignedJobs} max={props.stats.constructionJobs} />
  </BuildingActivationOverlay>
}
