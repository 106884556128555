import { dijkstra } from "../Edge";
import { Faction } from "../Faction";
import { GameState } from "../GameState";

export interface MoveCommand {
    type: "moveCommand";
    platoon: string;
    targetNode: string;
}
export function applyMoveCommand(gameState: GameState, command: MoveCommand, sender: Faction): GameState {
    let platoon = gameState.platoons[command.platoon];
    if(platoon === undefined) {
        console.warn(`Invalid command: The platoon ${platoon} ceased to exist.`);
        return gameState;
    }
    let path : string[] | undefined;
    if(platoon.faction !== sender) {
        console.warn(`Invalid command: Can't issue movement for ${platoon.tag} since it is not friendly (${platoon.faction})`)
        return gameState;
    }
    path = dijkstra(gameState.edges, platoon.position, command.targetNode);
    platoon = {
        ...platoon,
        path: path ?? []
    };
    return {
        ...gameState,
        platoons: {
            ...gameState.platoons,
            [platoon.tag]: platoon
        }
    };
}
