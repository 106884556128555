import { GameState } from "../../GameState";
import { updateBattles } from "./updateBattles";
import { updateResearch } from "./updateResearch";
import { updateTraining } from "./updateTraining";
import { updateConstructionSites } from "./updateConstructionSites";
import { updatePlatoonPositions } from "./updatePlatoonPositions";
import { updateTownDamage } from "./updateTownDamage";
import { gatherResources } from "./gatherResources";
import { updateJobs } from "./updateJobs";
import updateMines from "./updateMines";
import { updatePopulation } from "./updatePopulation";
import { applyHealing } from "./applyHealing";
import { applyFoodConsumption } from "./applyFoodConsumption";
import { updateNodeFeatures } from "./updateNodeFeatures";
import { ResourceUtils } from "../../Resources";
import { produce } from "immer";

export interface TickCommand {
    type: "tickCommand";
}
export function applyTickCommand(gameState: GameState, command: TickCommand): GameState {
    gameState = {
        ...gameState,
        time: gameState.time + 1,
        resourceUsage: {
            entryName: "root",
            usagePerTurn: {},
        }
    };

    const resourcesBefore = gameState.resources;

    gameState = updateJobs(gameState);

    gameState = gatherResources(gameState);

    gameState = applyFoodConsumption(gameState);

    gameState = updateTownDamage(gameState);

    gameState = updatePopulation(gameState);

    gameState = updateConstructionSites(gameState);

    gameState = updateMines(gameState);

    gameState = updateJobs(gameState);

    gameState = updatePlatoonPositions(gameState);

    gameState = updateBattles(gameState);

    gameState = updateTraining(gameState);

    gameState = applyHealing(gameState);

    gameState = updateResearch(gameState);

    gameState = updateNodeFeatures(gameState);

    const totalResourceUsage = ResourceUtils.diff(gameState.resources, resourcesBefore);
    const _resourceUsageRest = ResourceUtils.diff(totalResourceUsage, gameState.resourceUsage.usagePerTurn);
    const resourceUsageRest = ResourceUtils.flushToZero(_resourceUsageRest);
    if (resourceUsageRest !== undefined) {
        gameState = produce(gameState, gameState => {
            gameState.resourceUsage.usagePerTurn = ResourceUtils.flushToZero(totalResourceUsage) ?? {};
            gameState.resourceUsage.subEntries ??= [];
            gameState.resourceUsage.subEntries.push({
                entryName: "Rest",
                usagePerTurn: resourceUsageRest,
            })
        });
    }

    return gameState;
}

