import React from "react";
import SimpleAi from "../logic/ai/SimpleAi";
import { applyCommand, Command } from "../logic/commands/_Command";
import { Faction } from "../logic/Faction";
import { updateAllVision, VisibleGameState } from "../logic/fogOfWar";
import { GameState } from "../logic/GameState";

export type SaveGameRef = {
  isAutosave: boolean;
  name: string;
};

export type SaveGame = {
  ref?: SaveGameRef;
  gameState: GameState;
  visibleGameStates: { [key in Faction]: VisibleGameState };
  seed: string;
  aiState: object;
}

export interface SaveGameMeta extends SaveGameRef {
  gameTime: number;
  realTime: number;
  seed: string;
}

export const SubmitCommandContext = React.createContext<CommandSubmitter>(() => console.warn("No command submitter registered"));
export const SpeedContext = React.createContext<SpeedState>({ speed: 0, paused: true });

export type CommandSubmitter = (command: ExtendedCommand) => void;

export type ExtendedCommand =
  | Command
  | TimeCommand
  | { type: "setSavegameNameCommand", name: string };

export type TimeCommand =
  | { type: "setSpeedCommand"; speed: number; }
  | { type: "setPausedCommand"; paused: boolean; };

export interface SpeedState {
  speed: number;
  paused: boolean;
}

export const speeds = [2000, 300, 50]; // ms per tick

export function applyTick(saveGame: SaveGame, aiInactive?: boolean) {
  let newGameState = saveGame.gameState;
  let newAiState = saveGame.aiState;
  if (!aiInactive) {
    newAiState = SimpleAi.update(saveGame.aiState, newGameState, command => {
      newGameState = applyCommand(newGameState, command, "enemy");
    });
  }
  newGameState = applyCommand(newGameState, { type: "tickCommand" }, "player");
  return {
    ...saveGame,
    gameState: newGameState,
    visibleGameStates: updateAllVision(newGameState, saveGame.visibleGameStates),
    aiState: newAiState
  };
}

