import { GameState } from "../GameState";
import { Faction } from "../Faction";
import { BuildSlotIdentifier } from "../Node";
import { produce } from "immer";
import validateSiteHasJobs from "./_validation/validateSiteHasJobs";
import { recalculateJobAssignments } from "./TickCommand/updateJobs";

export interface SetPriorityCommand {
    type: "setPriorityCommand";
    slot: BuildSlotIdentifier;
    priority: number;
}
export function applySetPriorityCommand(gameState: GameState, command: SetPriorityCommand, sender: Faction): GameState {
    return produce(gameState, gameState => {
        const site = validateSiteHasJobs(gameState, command.slot, sender);
        if (site === undefined)
            return;
        site.priority = command.priority;
        const node = gameState.nodes[command.slot.nodeTag];
        gameState.nodes[command.slot.nodeTag] = recalculateJobAssignments(gameState, node);
    });
}
