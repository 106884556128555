import React from 'react';
import { Checkbox, FormControlLabel, Paper } from '@material-ui/core';

export interface Cheats {
  aiInactive: boolean;
  fullVision: boolean;
}
export function CheatUi(props: { cheats: Cheats; setCheats: (cheats: Cheats) => void; }) {
  return <Paper>
    <FormControlLabel
      control={<Checkbox
        checked={props.cheats.aiInactive}
        onChange={e => props.setCheats({ ...props.cheats, aiInactive: e.target.checked })}
        title="KI deaktivieren"
        style={{ display: "block" }} />}
      label="KI deaktivieren" />
    <FormControlLabel
      control={<Checkbox
        checked={props.cheats.fullVision}
        onChange={e => props.setCheats({ ...props.cheats, fullVision: e.target.checked })}
        title="Alles aufdecken" />}
      label="Alles aufdecken"
      style={{ display: "block" }} />
  </Paper>;
}
