import { Divider, Icon } from "@material-ui/core";
import _ from "lodash";
import React from "react";
import "./InfoBox.css"

/**
 * An info box that shows the main content on the left side and details about the selection on the right.
 * The right part will be hidden if there is no selection.
 * 
 * Usage note: do not create instances of this dynamically. Instead, create them at module level.
 */
class InfoBox<TSelection> {
    public readonly LocalSelection = React.createContext<TSelection | undefined>(undefined);
    public readonly ToggleLocalSelection = React.createContext<(value: TSelection | undefined) => void>(undefined!);

    public readonly Component = (props: { children: React.ReactNode, selectionUi: (selection: TSelection) => React.ReactNode, className?: string }) => {
        // The rules-of-hooks ESLint thingy thinks that this is a class component,
        // and because of this, Hooks are not to be used. But in reality, this is
        // of course a function component, just inside a class.
        //
        // We need it to be inside a class to be able to re-use our context.
        //eslint-disable-next-line react-hooks/rules-of-hooks
        const [selection, setSelection] = React.useState<TSelection | undefined>();
        const toggleSelection = (value: TSelection | undefined) => {
            if (_.isEqual(value, selection))
                setSelection(undefined);
            else
                setSelection(value);
        };

        return <this.ToggleLocalSelection.Provider value={toggleSelection}>
            <this.LocalSelection.Provider value={selection}>
                <div className={`infoBox ${props.className ?? ""}`}>
                    <div className="infoBox-main">
                        {props.children}
                    </div>
                    {selection !== undefined ?
                        <this.SelectionUi>
                            {props.selectionUi(selection)}
                        </this.SelectionUi>
                        : undefined}
                </div>
            </this.LocalSelection.Provider>
        </this.ToggleLocalSelection.Provider>;
    }

    private readonly SelectionUi = (props: { children: React.ReactNode }) => {
        //eslint-disable-next-line react-hooks/rules-of-hooks
        const toggleSelection = React.useContext(this.ToggleLocalSelection);
        return <>
            <div className="infoBox-divider">
                <Divider orientation="vertical" />
                <Icon className="fa fa-chevron-left" onClick={() => toggleSelection(undefined)} />
                <Divider orientation="vertical" />
            </div>
            <div className="infoBox-details">{props.children}</div>
        </>
    }
}

export default InfoBox;
