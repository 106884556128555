import _ from "lodash";
import Utils from "../../utils";

// inspiration:
// https://de.wikipedia.org/wiki/Ortsname
// https://de.wiktionary.org/


const prefixes = ["Ober", "Unter", "Mittel", "Klein", "Groß", "Alt", "Neu", "Hoch", "Nieder", "Hinter", "Vorder"];
const orcInfixes = [
    "Goblin", "Ork", "Troll",
    "Dunkel", "Düster", "Schwarz", "Finster",
    "Blut", "Knochen", "Gift",
    "Dreck", "Schmutz", "Siff", "Kehrichts", "Unrats", "Müll",
    "Schrecken", "Todes", "Angst", "Grauen", "Gräuel", "Unheils", "Schad", "Furcht", "Sorgen", "Schiss", "Leid", "Kummer", "Gram", "Jammer",
    "Roh", "Rau", "Derb", "Arg", "Bös", "Schlimm", "Barsch", "Herb", "Plump", "Übel",
    "Karg", "Knapp", "Öd", "Dürr",
    "Schauer",
];
const dwarfInfixes = [
    "Zwergen",
    "Minen", "Buddel", "Stollen", "Gruben", "Zechen", "Schürf", "Ader", "Lager", "Erz", "Eisen", "Stahl", "Eisenhütten", 
    "Schmied", "Werk",
    "Berg", "Fels", "Stein",
    "Glück", "Wohl", "Wert", "Zweck", "Sinn",
]
const suffixes = [
    "berg",
    "borg",
    "bühl",
    "dorf",
    "eck",
    "fels",
    "fort",
    "hagen",
    "hain",
    "hall",
    "hausen",
    "heim",
    "hof",
    "hofen",
    "holm",
    "horn",
    "hoven",
    "husen",
    "hütte",
    "leben",
    "scheid",
    "stadt",
    "städt",
    "stätten",
    "stedt",
    "stett",
    "tal",
    "thal",
    "torp",
    "trup",
    "weiler",
    "winkel",
];

function generateTownName(infixes: string[]) {
    const usePrefix = _.random(0, 1, true) > 0.5;
    if (usePrefix)
        return Utils.pickRandomly(prefixes) + Utils.pickRandomly(infixes).toLowerCase() + Utils.pickRandomly(suffixes);

    else
        return Utils.pickRandomly(infixes) + Utils.pickRandomly(suffixes);
}
export const generateOrcTownName = () => generateTownName(orcInfixes);
export const generateDwarfTownName = () => generateTownName(dwarfInfixes);

const nordicSyllables1 = [
    "Bjør",
    "Tjør",
    "I",
    "Thors",
    "Bjer",
    "Tjer",
    "Half",
    "Gun"
]
const nordicSyllables2 = [
    "n",
    "dan",
    "den",
    "var",
    "g",
    "nar",
    "ter"
]

export function generateNordicGivenName() : String
{
    return Utils.pickRandomly(nordicSyllables1) + Utils.pickRandomly(nordicSyllables2);
}

export function generateNordicLastName() : String
{
    return Utils.pickRandomly(nordicSyllables1) + Utils.pickRandomly(nordicSyllables2) + "son";
}

export function generateNordicFullName() : String
{
    return generateNordicGivenName + " " + generateNordicLastName;
}


const germanicSyllables1 = [
    "Otto",
    "Sieg",
    "Sig",
    "Will",
    "Fried",
    "Rode",
    "Rich"
]

const germanicSyllables2 = [
    "kar",
    "wald",
    "fried",
    "mar",
    "helm",
    "rich",
    "ard"
]

export function generatGermanicGivenName() : String
{
    return Utils.pickRandomly(germanicSyllables1) + Utils.pickRandomly(germanicSyllables2);
}
