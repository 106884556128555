import { GameState } from "../GameState";

export interface EnqueueResearchCommand {
    type: "enqueueResearchCommand";
    technology: string;
}
export function applyEnqueueResearchCommand(gameState: GameState, command: EnqueueResearchCommand): GameState {
    if (gameState.researchQueue.includes(command.technology)) {
        console.warn(`Invalid command: The technology ${command.technology} is already in the queue.`);
        return gameState;
    }
    return {
        ...gameState,
        researchQueue: [
            ...gameState.researchQueue,
            command.technology
        ]
    };
}
export interface DequeueResearchCommand {
    type: "dequeueResearchCommand";
    technology: string;
}
export function applyDequeueResearchCommand(gameState: GameState, command: DequeueResearchCommand): GameState {
    const newQueue = gameState.researchQueue.filter(x => x !== command.technology);
    if (gameState.researchQueue.length === newQueue.length) {
        console.warn(`Invalid command: The technology ${command.technology} is not present in the queue.`);
        return gameState;
    }
    return {
        ...gameState,
        researchQueue: newQueue
    };
}
