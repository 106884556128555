import React from 'react';
import { Screen } from '../App';
import { LoadGameMenu } from './LoadGameMenu';
import { SaveGame, SaveGameMeta } from './SaveGame';
import { SubmitCommandContext } from './SaveGame';
import "./IngameMenu.css"
import { GameStorage } from './GameStorage';

type MenuState = "ingameMenu" | "load" | "save";
type MenuProps = {
  closeMenu: () => void;
  setScreen: (screen: Screen) => void;
  saveGame: SaveGame;
};
export function IngameMenu(props: MenuProps) {
  const [menuState, setMenuState] = React.useState<MenuState>("ingameMenu");
  switch (menuState) {
    case "load":
      return <LoadGameMenu setScreen={screen => { props.setScreen(screen); props.closeMenu(); }} goBack={() => setMenuState("ingameMenu")} />;
    case "ingameMenu":
      return <div className="ingame-menu">
        <div className="button" onClick={() => setMenuState("save")}>
          <span className="fas fa-save" />&nbsp;Speichern
        </div>
        <div className="button" onClick={() => setMenuState("load")}>
          <span className="fas fa-folder-open" />&nbsp;Laden
        </div>
        <div className="button" onClick={() => props.setScreen({ type: "mainMenu" })}>
          <span className="fas fa-arrow-left" />&nbsp;Zum Hauptmenü
        </div>
        <div className="button" onClick={props.closeMenu}>
          <span className="fas fa-times-circle" />&nbsp;Zurück zum Spiel
        </div>
      </div>;
    case "save":
      return <SaveGameMenu {...props} goBack={() => setMenuState("ingameMenu")} />
  }
}

function SaveGameMenu(props: MenuProps & { goBack: () => void }) {
  const originalName = props.saveGame.ref?.name;
  const [name, setName] = React.useState(originalName ?? "");
  const [existingGame, setExistingGame] = React.useState<SaveGameMeta | undefined>();
  const submitCommand = React.useContext(SubmitCommandContext);
  const submit = async () => {
    if (name.length > 0) {
      const ref = { name, isAutosave: false };
      if (name !== originalName && GameStorage.has(ref)) {
        setExistingGame(GameStorage.loadMeta(ref));
        return;
      }
      await GameStorage.save({ ...props.saveGame, ref });
      submitCommand({ type: "setSavegameNameCommand", name })
      props.closeMenu();
    }
  }
  return <div className="save-as-menu">
    <div>
      <div style={{ textAlign: 'left' }}>
        <div className="button button-back" onClick={props.goBack}>
          <span className="fa fa-arrow-left" />&nbsp;Zurück
        </div>
      </div>
      <h1>Spiel speichern</h1>
    </div>
    <div className="save-as-input">
      <input type="text"
        required
        value={name}
        onChange={e => {
          setName(e.target.value);
          const ref = { name: e.target.value, isAutosave: false };
          setExistingGame(name !== originalName ? GameStorage.loadMeta(ref) : undefined);
        }}
        onKeyDown={e => e.key === "Enter" && submit()}
        minLength={1}
        onSubmit={submit} />
      <div className="button button-ok" onClick={submit}>
        <div className="fa fa-check" />
      </div>
    </div>
    <div className="save-error">
      {existingGame !== undefined && "Es existiert bereits ein Spiel mit diesem Namen."}
    </div>
  </div>;
}
