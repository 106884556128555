import { TagMap } from "../../utils/utils";
import { multiplier } from "../Modifiers";
import { SlotFeature } from "../Node";

export const defaultSlotFeatures: TagMap<SlotFeature> = {
    "stream": {
        tag: "stream",
        name: "Gebirgsbach",
        description: "Ein kleines Gewässer schlängelt sich hier durch das Gestein. Ermöglicht das Ausnutzen von Wasserkraft.",
        modifiers: []
    },
    "lake": {
        tag: "lake",
        name: "Unterirdischer See",
        description: "Hier befindet sich ein stilles Gewässer. Man kann sich gut vorstellen, wie man auf einem kleinen Boot darauf hingleitet.",
        modifiers: [multiplier("buildCosts", 2)]
    },
    "lava": {
        tag: "lava",
        name: "Lava",
        description: "Ein Strom von geschmolzenem Stein, matrialisch im Anblick.",
        modifiers: []
    },
    "thermalSpring": {
        tag: "thermalSpring",
        name: "Heiße Quelle",
        description: "Eine heiße Quelle. Bietet sich sehr für ein romantisches Bad an.",
        modifiers: []
    },
    "thermalVent": {
        tag: "thermalVent",
        name: "Fumarole",
        description: "Hier dringt heißer Dampf aus dem Gestein.",
        modifiers: []
    },
    "hardRock": {
        tag: "hardRock",
        name: "Hartes Gestein",
        description: "Das Gestein ist hier ungewöhnlich hart.",
        modifiers: [
            multiplier("miningSpeed", 0.25)
        ]
    },
    "veryHardRock": {
        tag: "veryHardRock",
        name: "Sehr hartes Gestein",
        description: "Das Gestein ist hier äußerst hart.",
        modifiers: [
            multiplier("miningSpeed", 0.1)
        ]
    }
};
