import { GameState } from "../../GameState";
import { Faction } from "../../Faction";
import { isTown } from "../../Node";

export default function validateIsOwnTown(gameState: GameState, nodeTag: string, sender: Faction) {
    const node = gameState.nodes[nodeTag];
    if (node === undefined) {
        console.warn(`Invalid command: The node '${nodeTag}' does not exist.`);
        return;
    }
    if (!isTown(node)) {
        console.warn(`Invalid command: The node '${nodeTag}' is not a town.`);
        return;
    }
    if (node.faction !== sender) {
        console.warn(`Invalid command: The node '${nodeTag}' does not belong to '${sender}'.`);
        return;
    }
    return node;
}
