import _ from "lodash";
import Utils from "../../../utils";
import { GameState } from "../../GameState";
import { getBattlesByPosition, getPlatoonsByPosition, OnEdge, OnNode, PlatoonPosition } from "../../Platoon";
import { produce } from "immer";
import { getAdjacencyMap } from "../../Edge";

export function updatePlatoonPositions(gameState: GameState) {
    return produce(gameState, gameState => {
        const platoonsAt = getPlatoonsByPosition(gameState.platoons);
        const battlesAt = getBattlesByPosition(gameState.battles);

        const startBattle = (participants: string[], position: OnNode | OnEdge) => {
            const battle = {
                participants,
                started: gameState.time,
                tag: Utils.getUnusedTag("b"),
                position
            };
            for (const platoon of Utils.resolveDraft(battle.participants, gameState.platoons)) {
                if (platoon.position.type === "inBattle") {
                    console.warn(`The platoon ${platoon.tag} was tried to be added to multiple battles at once.`);
                    continue;
                }
                platoon.position = { type: "inBattle", battle: battle.tag };
            }
            gameState.battles[battle.tag] = battle;
        };

        for (const platoon of Utils.values(gameState.platoons)) {
            if (platoon.path.length === 0)
                continue;
            let remainingMovement = _.min(platoon.units.map(x => gameState.unitTypes[x.kind].moveSpeed)) ?? 0;
            while (remainingMovement > 1e-5 && platoon.path.length > 0) {
                const movementResult = updatePosition(gameState, platoon.position, platoon.path[0], remainingMovement);
                if (movementResult === "InvalidPath") {
                    console.warn("Warning: clearing invalid path for", platoon);
                    platoon.path = [];
                    break;
                }
                else if (movementResult === "AlreadyThere") {
                    platoon.path = platoon.path.slice(1);
                }
                else {
                    // check if two platoons met to start a battle
                    if (movementResult.newPosition.type === "onNode") {
                        const otherPlatoons = platoonsAt.onNode.at(movementResult.newPosition.node);
                        const enemies = otherPlatoons.filter(other => other.faction !== platoon.faction);
                        if (enemies.length > 0) {
                            startBattle([platoon, ...enemies].map(p => p.tag), movementResult.newPosition);
                            break;
                        }
                        const battle = battlesAt.onNode.at(movementResult.newPosition.node).at(0)?.tag;
                        if (battle) {
                            gameState.battles[battle]?.participants.push(platoon.tag);
                            platoon.position = { type: "inBattle", battle };
                            break;
                        }
                    }
                    else if (movementResult.newPosition.type === "onEdge" && platoon.position.type === "onEdge") {
                        const otherPlatoons = platoonsAt.onEdge.at(movementResult.newPosition.edge);
                        const minPos = Math.min(movementResult.newPosition.position, platoon.position.position);
                        const maxPos = Math.max(movementResult.newPosition.position, platoon.position.position);

                        const enemies = otherPlatoons.filter(other => {
                            if (other.faction === platoon.faction)
                                return false;
                            const otherPos = other.position.position;
                            return otherPos >= minPos && otherPos <= maxPos;
                        });
                        if (enemies.length > 0) {
                            startBattle([platoon, ...enemies].map(p => p.tag), platoon.position);
                            break;
                        }

                        const battles = battlesAt.onEdge.at(movementResult.newPosition.edge);
                        const passedBattles = battles.filter(battle => {
                            const otherPos = battle.position.position;
                            return otherPos >= minPos && otherPos <= maxPos;
                        })
                        const battle = passedBattles.at(0)?.tag;
                        if (battle) {
                            gameState.battles[battle]?.participants.push(platoon.tag);
                            platoon.position = { type: "inBattle", battle: battle };
                            break;
                        }
                    }

                    platoon.position = movementResult.newPosition;
                    remainingMovement = movementResult.remainingMovement;
                }
            }
        }
    });
}
const saturate = (x: number) => Math.min(Math.max(x, 0), 1)
function updatePosition(gameState: GameState, oldPos: PlatoonPosition, nextNodeTag: string, remainingMovement: number): { newPosition: PlatoonPosition; remainingMovement: number; } | "InvalidPath" | "AlreadyThere" {
    if (oldPos.type === "onEdge") {
        const edge = gameState.edges[oldPos.edge];
        let direction: number;
        if (nextNodeTag === edge.nodes[0])
            direction = -1;
        else if (nextNodeTag === edge.nodes[1])
            direction = 1;
        else
            return "InvalidPath";

        if (direction === 1 && oldPos.position > 1 - 1e-5) {
            return {
                newPosition: { type: "onNode", node: edge.nodes[1] },
                remainingMovement
            }
        }
        else if (direction === -1 && oldPos.position < 1e-5) {
            return {
                newPosition: { type: "onNode", node: edge.nodes[0] },
                remainingMovement
            }
        }
        else {
            const newPos = saturate(oldPos.position + direction * remainingMovement / edge.length);
            return {
                newPosition: {
                    type: "onEdge",
                    edge: oldPos.edge,
                    position: newPos
                },
                remainingMovement: remainingMovement - Math.abs(oldPos.position - newPos) * edge.length
            };
        }
    }
    else if (oldPos.type === "onNode") {
        const currrentNodeTag = oldPos.node;
        if (currrentNodeTag === nextNodeTag)
            return "AlreadyThere";
        // find adjacent edge which leads us to the next node
        const halfEdge = getAdjacencyMap(gameState.edges)[oldPos.node]
            ?.filter(x => x.target === nextNodeTag)
            ?.[0];
        if (halfEdge === undefined)
            return "InvalidPath";
        return {
            newPosition: { type: "onEdge", edge: halfEdge.edgeTag, position: halfEdge.direction === 1 ? 0 : 1 },
            remainingMovement
        };
    }
    else if (oldPos.type === "inBattle")
        return { newPosition: oldPos, remainingMovement: 0 };
    else
        throw new Error("Invalid position!");
}
