import { random } from "lodash";
import { OrkifiedJunction } from "./generateMap"
import { AbandonedStructures, Biome, Building, BuildSlot, building, emptySlot } from "../Node"

export function generateBuildslots(nodes: OrkifiedJunction[]): OrkifiedJunction[] {
    for (const node of nodes) {
        node.buildSlots = generateBuildslotsForNode(node);
    }
    return nodes;
}

function generateBuildslotsForNode(node: OrkifiedJunction): BuildSlot[] {
    let ironChance: number = Math.random();
    node.buildSlots.push(...generateStandardBuildslots(1, 3, ironChance));
    node.buildSlots.push(...generateHardBuildslots(1, 4, ironChance));
    node.buildSlots.push(...generateVeryHardBuildslots(1, 4, (1-ironChance)));
    node.buildSlots.push(...generateBiomeBuildslots(node));
    node.buildSlots.push(...generateAbandonedBuildslots(node));
    node.buildSlots.push(...generateSpecialNodeBuildslots(node));
    node.buildSlots.push(...generateOrcBuildslots(node));
    return node.buildSlots;
}

function generateStandardBuildslots(minSlots: number, maxSlots: number, ironRatio: number): BuildSlot[] {
    let slots: BuildSlot[] = [];
    let slotLimit: number = random(minSlots, maxSlots);
    let i: number = 0;
    while (i < slotLimit) {
        let ironChance: number = Math.random();
        if ((2 * ironChance) < ironRatio) {
            slots.push({ features: [], site: { type: "rubble", resources: { iron: 100 } } });
        } else {
            if (ironChance < ironRatio) {
                slots.push({ features: [], site: { type: "rubble", resources: { stone: 50, iron: 50 } } });
            }
            else {
                slots.push({ features: [], site: { type: "rubble", resources: { stone: 100 } } });
            }
        }
        i++;
    }
    return slots;
}

function generateHardBuildslots(minSlots: number, maxSlots: number, ironRatio: number): BuildSlot[] {
    let slots: BuildSlot[] = [];
    let slotLimit: number = random(minSlots, maxSlots);
    let i: number = 0;
    while (i < slotLimit) {
        let ironChance: number = Math.random();
        if ((2 * ironChance) < ironRatio) {
            slots.push({ features: ["hardRock"], site: { type: "rubble", resources: { iron: 100 } } });
        } else {
            if (ironChance < ironRatio) {
                slots.push({ features: ["hardRock"], site: { type: "rubble", resources: { stone: 50, iron: 50 } } });
            }
            else {
                slots.push({ features: ["hardRock"], site: { type: "rubble", resources: { stone: 100 } } });
            }
        }
        i++;
    }
    return slots;
}

function generateVeryHardBuildslots(minSlots: number, maxSlots: number, crystalRatio: number): BuildSlot[] {
    let slots: BuildSlot[] = [];
    let slotLimit: number = random(minSlots, maxSlots);
    let i: number = 0;
    while (i < slotLimit) {
        let crystalChance: number = Math.random();
        if ((2 * crystalChance) < crystalRatio) {
            slots.push({ features: ["veryHardRock"], site: { type: "rubble", resources: { stone: 90, lightstone : 10 } } });
        } else {
            if (crystalChance < crystalRatio) {
                slots.push({ features: ["veryHardRock"], site: { type: "rubble", resources: { stone: 95, lightstone: 5 } } });
            }
            else {
                slots.push({ features: ["veryHardRock"], site: { type: "rubble", resources: { stone: 100, iron: 50 } } });
            }
        }
        i++;
    }
    return slots;
}

function generateBiomeBuildslots(node: OrkifiedJunction): BuildSlot[] {
    let buildslots: BuildSlot[] = [];
    switch (node.biome) {
        case Biome.Lakes:
            buildslots.push(emptySlot(["lake"]));
            if(Math.random() < 0.5){
                buildslots.push(emptySlot(["lake"]));
            }
            if(Math.random() < 0.5){
                buildslots.push(emptySlot(["stream"]));
            }
            if(Math.random() < 0.5){
                buildslots.push({features: ["stream"], site: {type: "rubble", resources:{stone: 50}}});
            }
            break;
        case Biome.ThermalSprings:
            buildslots.push(emptySlot(["thermalSpring"]));
            if(Math.random() < 0.5){
                buildslots.push({features: [], site: {type: "rubble", resources:{stone: 90, sulphur: 10}}});
            }
            if(Math.random() < 0.5){
                buildslots.push({features: [], site: {type: "rubble", resources:{stone: 70, sulphur: 30}}});
            }
            if(Math.random() < 0.5){
                buildslots.push({features: ["thermalSpring"], site: {type: "rubble", resources:{stone: 90, sulphur: 10}}});
            }
            if(Math.random() < 0.5){
                buildslots.push(emptySlot(["stream"]));
            }
            if(Math.random() < 0.5){
                buildslots.push({features: ["stream"], site: {type: "rubble", resources:{stone: 50}}});
            }
            break;
        case Biome.Volcanic:
            buildslots.push(emptySlot(["lava"]));
            if(Math.random() < 0.5){
                buildslots.push({features: [], site: {type: "rubble", resources:{stone: 90, sulphur: 10}}});
            }
            if(Math.random() < 0.5){
                buildslots.push({features: [], site: {type: "rubble", resources:{stone: 70, sulphur: 30}}});
            }
            if(Math.random() < 0.5){
                buildslots.push({features: [], site: {type: "rubble", resources:{stone: 50, sulphur: 50}}});
            }
            if(Math.random() < 0.5){
                buildslots.push({features: ["thermalVent"], site: {type: "rubble", resources:{stone: 50, sulphur: 50}}});
            }
            if(Math.random() < 0.5){
                buildslots.push({features: ["lava","veryHardRock"], site: {type: "rubble", resources:{stone: 95, lightstone: 5}}});
            }
            break;
        case Biome.VolcanicExtreme:
            buildslots.push(emptySlot(["lava"]));
            if(Math.random() < 0.5){
                buildslots.push({features: ["lava"], site: {type: "rubble", resources:{stone: 90, sulphur: 10}}});
            }
            if(Math.random() < 0.5){
                buildslots.push({features: ["lava"], site: {type: "rubble", resources:{stone: 70, sulphur: 30}}});
            }
            if(Math.random() < 0.5){
                buildslots.push({features: [], site: {type: "rubble", resources:{stone: 50, sulphur: 50}}});
            }
            if(Math.random() < 0.5){
                buildslots.push({features: ["thermalVent"], site: {type: "rubble", resources:{stone: 50, sulphur: 50}}});
            }
            if(Math.random() < 0.5){
                buildslots.push({features: ["lava","veryHardRock"], site: {type: "rubble", resources:{stone: 70, lightstone: 30}}});
            }
            if(Math.random() < 0.5){
                buildslots.push({features: ["lava","veryHardRock"], site: {type: "rubble", resources:{stone: 90, lightstone: 10}}});
            }
            if(Math.random() < 0.5){
                buildslots.push({features: ["lava","veryHardRock"], site: {type: "rubble", resources:{stone: 95, lightstone: 5}}});
            }
            break;
        default:
            if(Math.random() < 0.5){
                buildslots.push({features: [], site: {type: "rubble", resources:{stone: 90, coal: 10}}});
            }
            if(Math.random() < 0.5){
                buildslots.push({features: [], site: {type: "rubble", resources:{stone: 70, coal: 30}}});
            }
            if(Math.random() < 0.5){
                buildslots.push({features: [], site: {type: "rubble", resources:{stone: 50, coal: 50}}});
            }
            if(Math.random() < 0.1){
                buildslots.push({features: [], site: {type: "rubble", resources:{stone: 90, sulphur: 10}}});
            }
            break;
    }
    return buildslots;
}

function generateAbandonedBuildslots(node: OrkifiedJunction): BuildSlot[] {
    let buildslots: BuildSlot[] = [];
    switch (node.abandonedStructure) {
        case AbandonedStructures.Town:
            return [
                building("abandoned blacksmith"),
                building("abandoned house")
            ];
        case AbandonedStructures.City:
            return [
                building("abandoned blacksmith"),
                building("abandoned blacksmith"),
                building("abandoned house"),
                building("abandoned house")
            ];
        default:
            break;
    }
    return buildslots;
}

function generateOrcBuildslots(node: OrkifiedJunction): BuildSlot[] {
    let buildslots: BuildSlot[] = [];
    let i: number = 0;
    if (node.orcification > 0) {
        while (i < node.buildSlots.length) {
            if (node.buildSlots[i].site.type === "building") {
                if ((node.buildSlots[i].site as Building).kind === "abandoned blacksmith") {
                    node.buildSlots[i] = building("orcified blacksmith")
                }
            }
            i++;
        }
    }
    i = 0;
    while (i < node.orcification) {
        buildslots.push(building("orc camp"))
        i++;
    }
    return buildslots;
}

function generateSpecialNodeBuildslots(node: OrkifiedJunction): BuildSlot[] {
    let slots: BuildSlot[] = [];
    if(node.features["protectedValley"] !== undefined){
        slots.push(building("abandoned blacksmith"));
        slots.push(building("abandoned house"));
        slots.push({ features: [], site: { type: "rubble", resources: { wood: 100 } } });
        slots.push({ features: [], site: { type: "rubble", resources: { wood: 100 } } });
        slots.push({ features: [], site: { type: "rubble", resources: { wood: 100 } } });
        slots.push({ features: [], site: { type: "rubble", resources: { wood: 100 } } });
    }
    if(node.features["greatBath"] !== undefined){
        slots.push(building("thermalBath",["thermalSpring"]));
        slots.push(building("abandoned house"));
    }
    if(node.features["forgeOfTheAncients"] !== undefined){
        slots.push(building("abandoned blacksmith"));
        slots.push(building("abandoned house"));
        slots.push(building("abandoned blacksmith"));
        slots.push(building("abandoned house"));
    }
    return slots;
}