import { getPlatoonsByPosition } from "../logic/Platoon";
import Utils from "../utils";
import { CanvasItem } from "./Canvas";
import { edgeOnCanvas } from "./EdgeOnCanvas";
import { nodeOnCanvas } from "./NodeOnCanvas";
import { getWorldPos, platoonOnCanvas } from "./PlatoonOnCanvas";
import _ from "lodash";
import { GameObject } from "../logic/GameObject";
import { battleOnCanvas } from "./battleOnCanvas";
import { VisibleGameState } from "../logic/fogOfWar";
import { VisualSelection } from "./VisualSelection";

export function gameStateOnCanvas(gameState: VisibleGameState, selection: VisualSelection): CanvasItem<GameObject>[] {
    const platoonsAt = getPlatoonsByPosition(gameState.platoons);
    return [
        ...Utils.mapObjectToList(gameState.edges, edge => edgeOnCanvas(gameState, edge)),
        ...Utils.mapObjectToList(gameState.nodes, node => nodeOnCanvas(gameState, node, platoonsAt.onNode.at(node.tag) ?? [], selection)).flat(),
        ...platoonsAt.onEdge.values().map(platoon => platoonOnCanvas(gameState, platoon!, getWorldPos(gameState, platoon!.position), selection.platoons)),
        ..._.flatten(Utils.values(gameState.battles).map(battle => battleOnCanvas(gameState, battle, selection.platoons)))
    ]
}
