import { GameState } from "../logic/GameState";
import { CanvasItem } from "./Canvas";
import { getWorldPos, platoonOnCanvas } from "./PlatoonOnCanvas";
import { GameObject } from "../logic/GameObject";
import { Battle } from "../logic/Battle";
import { mouseOverCircle } from "./VisualUtils";
import Utils from "../utils";

export function battleOnCanvas(gameState: GameState, battle: Battle, platoonSelection: ReadonlySet<string>): CanvasItem<GameObject>[] {
    const [x, y] = battle.position.type === "onEdge" ? getWorldPos(gameState, battle.position) : gameState.nodes[battle.position.node].position;
    return [
        {
            render(context) {
                context.beginPath();
                context.arc(x, y, 10, 0, 2 * Math.PI);
                context.strokeStyle = "rgb(255, 255, 0)";
                context.lineWidth = 2;
                context.fillStyle = "rgba(255, 255, 0, 0.5)";
                context.fill();
                context.stroke();

            },
            mouseOver: mouseOverCircle(x, y, 10),
            payload: { type: "battle", tag: battle.tag }
        },
        ...Utils.resolve(battle.participants, gameState.platoons).resolved.map((platoon, i, all) => {
            const angle = Math.PI * 2 * i / all.length;
            const position: [number, number] = [x + Math.cos(angle) * 10, y + Math.sin(angle) * 10];
            return platoonOnCanvas(gameState, platoon, position, platoonSelection);
        })
    ];
}
