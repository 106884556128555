import { GameMap, GameState } from "../GameState";
import { generateMap } from "../mapGeneration/generateMap";
import { defaultBuildingTypes } from "./buildingTypes";
import { defaultNodeFeatures } from "./nodeFeatures";
import { defaultSlotFeatures } from "./slotFeatures";
import { defaultTechnologies } from "./technologies";
import { testMap } from "./testMap";
import { defaultUnitTypes } from "./unitTypes";

function getInitialGameState(seed: number | "testing"): GameState {
    const generatedMap: GameMap = seed === "testing" ? testMap : generateMap(seed);
    return {
        ...generatedMap,
        unitTypes: defaultUnitTypes,
        buildingTypes: defaultBuildingTypes,
        resources: {
            stone: 50,
            iron: 10,
            food: 100,
            coal: 10,
            sulphur: 5,
            wood: 50,
            equipment: 10,
            mechanicalComponents: 5
        },
        resourceUsage: {
            entryName: "root",
            usagePerTurn: {},
        },
        time: 69,
        technologies: defaultTechnologies,
        research: {},
        researchQueue: [],
        slotFeatures: defaultSlotFeatures,
        nodeFeatures: defaultNodeFeatures
    }
}

export default getInitialGameState;
