import React from "react";

let frameHandle: number | undefined;
const frameCallbacks = new Set<(dt: number) => void>();

function addFrameCallback(callback: (dt: number) => void) {
    frameCallbacks.add(callback);
    if (frameHandle === undefined) {
        let lastFrameTime = performance.now();
        const frameCallback = () => {
            const thisFrameTime = performance.now();
            const dt = thisFrameTime - lastFrameTime;
            lastFrameTime = thisFrameTime;

            for (const callback of frameCallbacks) {
                callback(dt);
            }
            frameHandle = requestAnimationFrame(frameCallback);
            pruneTimer();
        };
        frameHandle = requestAnimationFrame(frameCallback);
    }
}
function removeFrameCallback(callback: (dt: number) => void) {
    frameCallbacks.delete(callback);
    pruneTimer();
}
function pruneTimer() {
    if (frameCallbacks.size === 0 && frameHandle !== undefined) {
        cancelAnimationFrame(frameHandle);
        frameHandle = undefined;
    }
}

export function useHotkey(callback: (dt: number) => void, key: string, modifiers?: { ctrl?: boolean, shift?: boolean, alt?: boolean }, continuous: boolean = false) {
    // If the callback changes identity (due to re-rendering), we don't want the
    // effect to re-trigger, so we're using a ref here.
    const callbackRef = React.useRef(callback);
    callbackRef.current = callback;
    const indirectedCallback = React.useCallback((dt: number) => callbackRef.current(dt), []);

    React.useEffect(() => {
        const ctrl = !!modifiers?.ctrl;
        const shift = !!modifiers?.shift;
        const alt = !!modifiers?.alt;
        const isRightKey = (e: KeyboardEvent) => e.key === key
            && e.ctrlKey === ctrl
            && e.altKey === alt
            && e.shiftKey === shift
            && (e.target as HTMLElement).tagName.toLowerCase() !== "input";
        const onKeyDown = (e: KeyboardEvent) => {
            if (isRightKey(e)) {
                e.stopImmediatePropagation();
                e.preventDefault();
                indirectedCallback(0);
                if (continuous) {
                    addFrameCallback(indirectedCallback);
                }
            }
        };
        const onKeyUp = (e: KeyboardEvent) => {
            if (isRightKey(e)) {
                removeFrameCallback(indirectedCallback);
            }
        }
        document.addEventListener("keydown", onKeyDown);
        if (continuous)
            document.addEventListener("keyup", onKeyUp);
        return () => {
            document.removeEventListener("keydown", onKeyDown);
            if (continuous)
                document.removeEventListener("keyup", onKeyUp);
            removeFrameCallback(indirectedCallback);
        }
    }, [indirectedCallback, key, modifiers?.ctrl, modifiers?.alt, modifiers?.shift, continuous]);
}
