import React from 'react';
import { Screen } from '../App';
import "./MainMenu.css"
import _ from 'lodash';
import { GameStorage } from './GameStorage';

export function MainMenu(props: { setScreen: (screen: Screen) => void; }) {
    const latest = GameStorage.latest;
    return <div className="main-menu">
        <div className="button" onClick={() => props.setScreen({ type: "inGame", source: { type: "newGame", seed: _.random(0, 1 << 31).toString() } })}>Neues Spiel</div>
        <div className="button" onClick={() => props.setScreen({ type: "inGame", source: { type: "newGame", seed: "testing" } })}>Neues Test-Spiel</div>
        <div className="button"
            aria-disabled={latest === undefined}
            onClick={latest && (() => props.setScreen({ type: "inGame", source: { type: "saveGame", ...latest } }))}>
            Letztes Spiel laden
            {latest && <div className="latest-save-game">
                {latest.name}
                {latest.isAutosave && " (automatisch gespeichert)"}
            </div>}
        </div>
        <div className="button" onClick={() => props.setScreen({ type: "selectGame" })}>Laden</div>
        <div className="button" onClick={() => props.setScreen({
            type: "host",
            roomId: "myRoom",
            source: { type: 'newGame', seed: "mySeed" }
        })}>Hosten</div>
        <div className="button" onClick={() => props.setScreen({
            type: "client",
            roomId: "myRoom",
            clientId: "someClient"
        })}>Beitreten</div>
    </div>;
}
