import Utils from "../utils";
import { RemovableTagMap } from "../utils/utils";
import weakMemoize from "../utils/weakMemoize";
import { Battle } from "./Battle";
import { Faction } from "./Faction";
import { Resources } from "./Resources";

export interface Platoon {
    tag: string;
    position: PlatoonPosition;
    units: Unit[];
    /** List of nodes that should be visited next. */
    path: string[];
    faction: Faction;
}
/** Static helper class to create position objects. */
export class Pos {
    private constructor() { } // just a container for some static methods
    static OnEdge(edge: string, position: number): OnEdge {
        return { type: "onEdge", edge, position }
    }
    static OnNode(node: string): OnNode {
        return { type: "onNode", node }
    }
    static InBattle(battle: string): InBattle {
        return { type: "inBattle", battle }
    }
}
export type PlatoonPosition = OnEdge | OnNode | InBattle;
export interface OnEdge {
    type: "onEdge";
    /** The index of the edge as in `GameState.edges`. */
    edge: string;
    /** Relative position on the edge from 0 to 1. */
    position: number;
}
export interface OnNode {
    type: "onNode";
    /** The tag of the node. */
    node: string;
}
export interface InBattle {
    type: "inBattle";
    battle: string;
}
export interface Unit {
    kind: string;
    currentHealth: number;
    experience: number;
    level: number;
    remainingReloadTime?: number;
}
export interface UnitType extends Partial<UnitStats> {
    tag: string;
    name: string;
    description: string;
    features: string[],
    /** The technology required to train a unit of this type. Use `null` for untrainable units (e.g. enemies), leave out for units without technology requirements (i.e. initially available units). */
    requiredTech?: string | null,
    /** The building type required to be able to train a unit of this type. */
    requiredBuilding?: string;
}

export const defaultUnitStats = {
    maxHealth: 100,
    trainCosts: {} as Resources,
    trainTime: 100,
    manpower: 1,
    meleeAttack: 0,
    meleeRange: 0,
    rangedAttack: 0,
    rangedRange: 0,
    rangedStartupTime: 0,
    rangedReloadTime: 0,
    defense: 0,
    moveSpeed: 100,
    maxHealing: 2
}
export type UnitStats = typeof defaultUnitStats;

type PlatoonAt<T extends PlatoonPosition> = Platoon & { position: T };
type PlatoonsAt<T extends PlatoonPosition> = Group<PlatoonAt<T>>
type BattleAt<T extends OnNode | OnEdge> = Battle & { position: T }
type BattlesAt<T extends OnNode | OnEdge> = Group<BattleAt<T>>
// TODO: move to another file
class Group<T> {
    static collectPlatoons(platoons: RemovableTagMap<Platoon>): {
        onEdge: PlatoonsAt<OnEdge>,
        onNode: PlatoonsAt<OnNode>,
        inBattle: PlatoonsAt<InBattle>
    } {
        const onEdge = new Group() satisfies PlatoonsAt<OnEdge>;
        const onNode = new Group() satisfies PlatoonsAt<OnNode>;
        const inBattle = new Group() satisfies PlatoonsAt<InBattle>;
        for (const platoon of Utils.values(platoons)) {
            switch (platoon.position.type) {
                case "onEdge":
                    onEdge.#insert(platoon.position.edge, platoon as PlatoonAt<OnEdge>);
                    break;
                case "inBattle":
                    inBattle.#insert(platoon.position.battle, platoon as PlatoonAt<InBattle>);
                    break;
                case "onNode":
                    onNode.#insert(platoon.position.node, platoon as PlatoonAt<OnNode>);
                    break;
            }
        }
        return { onEdge, onNode, inBattle }
    }

    static collectBattles(battles: RemovableTagMap<Battle>): {
        onNode: BattlesAt<OnNode>,
        onEdge: BattlesAt<OnEdge>
    } {
        const onEdge = new Group() satisfies BattlesAt<OnEdge>;
        const onNode = new Group() satisfies BattlesAt<OnNode>;
        for (const battle of Utils.values(battles)) {
            switch (battle.position.type) {
                case "onEdge":
                    onEdge.#insert(battle.position.edge, battle as BattleAt<OnEdge>);
                    break;
                case "onNode":
                    onNode.#insert(battle.position.node, battle as BattleAt<OnNode>);
                    break;
            }
        }
        return { onEdge, onNode }
    }

    #insert(key: string, item: T) {
        const list = this.#items.get(key);
        if (list === undefined)
            this.#items.set(key, [item]);
        else
            list.push(item)
    }

    readonly #items = new Map<string, T[]>();
    private constructor() { }
    public at(key: string): T[] {
        return this.#items.get(key) ?? [];
    }
    public entries(): [string, T[]][] {
        return [...this.#items.entries()];
    }
    public values(): T[] {
        return [...this.#items.values()].flat();
    }
}

export const getPlatoonsByPosition = weakMemoize(Group.collectPlatoons);
export const getBattlesByPosition = weakMemoize(Group.collectBattles);

export function requiredXp(level: number): number {
    return (10 * Math.pow(2, level))
}
