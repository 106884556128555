import { produce } from "immer";
import { SaveGameRef, SaveGameMeta, SaveGame } from "./SaveGame";
import * as JSON from "../utils/JSON";

function getSavegameAttributeName(saveGame: SaveGameRef) {
  const prefix = saveGame.isAutosave ? "autosave_" : "savegame_";
  return prefix + saveGame.name;
}
function loadMetadata() {
  return JSON.parse(window.localStorage.getItem("savegame_meta") ?? "{}") as {
    [key in string]: SaveGameMeta;
  };
}
class SaveGameLocalStorage {
  #metadata = loadMetadata();
  constructor() {
    window.addEventListener("storage", e => {
      if (e.key === "savegame_meta") {
        this.#metadata = loadMetadata();
      }
    });
  }
  public get all() {
    return Object.values(this.#metadata);
  }
  public loadMeta(game: SaveGameRef) {
    return this.#metadata[getSavegameAttributeName(game)];
  }
  private async getSavegameFile(game: SaveGameRef, create?: boolean) {
    const rootDir = await navigator.storage.getDirectory();
    const dir = await rootDir.getDirectoryHandle("savegames", { create: true });
    return await dir.getFileHandle(getSavegameAttributeName(game), { create });
  }
  public async load(game: SaveGameRef) {
    const json = await this.getSavegameFile(game)
      .then(x => x.getFile())
      .then(x => x.text());

    if (json !== null) {
      return JSON.parse(json) as SaveGame;
    }
    else {
      throw new Error("Could not find savegame. Maybe the local storage is corrupted :-/");
    }
  }
  public async save(game: SaveGame) {
    if (game.ref === undefined)
      throw new Error("The savegame must have a ref to be saved.");
    const json = JSON.stringify(game);
    const attributeName = getSavegameAttributeName(game.ref);
    let file;
    try {
      file = await this.getSavegameFile(game.ref, true)
        .then(file => file.createWritable());

      file.write(json);

      this.#metadata[attributeName] = {
        ...game.ref,
        gameTime: game.gameState.time,
        realTime: new Date().getTime(),
        seed: game.seed
      };
      this.saveMetadata();
      window.localStorage.setItem("latestSavegame", attributeName);
    } catch (e) {
      console.error("Could not autosave: ", e);
      if (!game.ref.isAutosave) {
        alert("Could not save game:" + e);
      }
      await file?.abort();
    }
    finally {
      await file?.close();
    }
  }
  public autosave(saveGame: SaveGame) {
    return this.save(produce(saveGame, saveGame => {
      if (saveGame.ref === undefined) {
        saveGame.ref = { name: "Unbenannt", isAutosave: true };
      }
      saveGame.ref.isAutosave = true;
    }));
  }

  public has(game: SaveGameRef) {
    return this.loadMeta(game) !== undefined;
  }
  public delete(game: SaveGameRef) {
    const attributeName = getSavegameAttributeName(game);
    delete this.#metadata[attributeName];
    window.localStorage.removeItem(attributeName);
    this.saveMetadata();
  }
  private saveMetadata() {
    window.localStorage.setItem("savegame_meta", JSON.stringify(this.#metadata));
  }
  public get latest() {
    const latest = window.localStorage.getItem("latestSavegame");
    if (latest === null)
      return undefined;
    return this.#metadata[latest];
  }
  public clear() {
    window.localStorage.clear();
    this.#metadata = {};
  }
}

export const GameStorage = new SaveGameLocalStorage();
