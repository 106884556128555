import { produce } from "immer";
import _ from "lodash";
import Utils from "../../../utils";
import { GameState } from "../../GameState";
import { isTown, raze, Town } from "../../Node";
import { getPlatoonsByPosition } from "../../Platoon";
import { getUnitStats } from "../../getUnitStats";

export function updateTownDamage(gameState: GameState): GameState {
    const platoonsOnNodes = getPlatoonsByPosition(gameState.platoons).onNode;
    return {
        ...gameState, nodes: Utils.mapObject(gameState.nodes, node => {
            if (!isTown(node))
                return node;
            const platoons = platoonsOnNodes.at(node.tag);
            if (platoons.length === 0)
                return node;
            const damage = platoons.filter(p => p.faction !== node.faction)
                .flatMap(x => x.units)
                .map(unit => getUnitStats(gameState, unit).meleeAttack)
                .reduce(_.add, 0);
            if (damage === 0)
                return node;
            const town = produce(node as Town, town => {
                town.population -= damage;
            });
            if (town.population <= 0) {
                // make it not a town
                return raze(town);
            }
            else return town;
        })
    };
}
