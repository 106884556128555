import { flat } from "../Modifiers";
import { BuildingType } from "../Node";

export const defaultBuildingTypes: { [key: string]: BuildingType; } = {
    "farm": {
        tag: "farm",
        name: "Bauernhof",
        description: "Ein paar Felder, mit lichtscheuen Pflanzen und Pilzen bestellt.",
        buildCosts: {
            stone: 30,
            iron: 2
        },
        buildTime: 30,
        productionPerTurn: {
            food: 10
        },
        minWorkerJobs: 0,
        maxWorkerJobs: 5,
        constructionJobs: 5,
    },
    "house": {
        tag: "house",
        name: "Haus",
        description: "Ein einfaches Haus. Bietet Wohnraum für Zwerge.",
        buildCosts: {
            stone: 10
        },
        buildTime: 15,
        nodeModifiers: [flat("housing", 10)],
        constructionJobs: 5,
    },
    "barracks": {
        tag: "barracks",
        name: "Kaserne",
        description: "Hier werden Soldaten ausgebildet.",
        buildCosts: {
            stone: 100,
            iron: 10
        },
        buildTime: 60,
        nodeModifiers: [flat("housing", 5)],
        minWorkerJobs: 1,
        maxWorkerJobs: 1,
        constructionJobs: 5,
    },
    "apothecary": {
        tag: "apothecary",
        name: "Apotheke",
        description: "Eine Apotheke in der medikamente und elixiere zubereitet werden.",
        buildCosts: {
            stone: 100
        },
        buildTime: 60,
        nodeModifiers: [flat("popGrowth", 5), flat("amenities", 5), flat("healing", 20)],
        productionPerTurn: {
            food: -5
        },
        minWorkerJobs: 0,
        maxWorkerJobs: 2,
        constructionJobs: 5,
    },
    "blacksmith": {
        tag: "blacksmith",
        name: "Schmiede",
        description: "Eine Schmiede. Hier können Waffen aus Eisen oder Stahl hergestellt werden.",
        buildCosts: {
            stone: 100,
            iron: 20
        },
        buildTime: 60,
        productionPerTurn: {
            iron: -1,
            coal: -1,
            equipment: 1
        },
        minWorkerJobs: 0,
        maxWorkerJobs: 3,
        constructionJobs: 20,
    },
    "workshop": {
        tag: "workshop",
        name: "Werkstatt",
        description: "Hier werden Maschinenteile für Militär und Industrie hergestellt",
        buildCosts: {
            stone: 100,
            iron: 20
        },
        buildTime: 60,
        productionPerTurn: {
            iron: -1,
            coal: -1,
            mechanicalComponents: 1
        },
        minWorkerJobs: 0,
        maxWorkerJobs: 3,
        constructionJobs: 20,
    },
    "waterPoweredBlacksmith": {
        tag: "waterPoweredBlacksmith",
        name: "Wasserbetriebene Schmiede",
        description: "Eine Wasserbetriebene Schmiede. Hier können Waffen aus Eisen oder Stahl effizient hergestellt werden.",
        buildCosts: {
            mechanicalComponents: 20,
            iron: 20
        },
        buildTime: 60,
        productionPerTurn: {
            iron: -3,
            coal: -1,
            equipment: 3
        },
        upgradesFrom: "blacksmith",
        requiredFeature: "stream",
        requiredTech: "poweredMachinery",
        minWorkerJobs: 0,
        maxWorkerJobs: 3,
        constructionJobs: 20,
    },
    "waterPoweredWorkshop": {
        tag: "waterPoweredWorkshop",
        name: "Wasserbetriebene Werkstatt",
        description: "Hier werden Maschinenteile für Militär und Industrie effizient hergestellt",
        buildCosts: {
            mechanicalComponents: 20,
            iron: 20
        },
        buildTime: 60,
        productionPerTurn: {
            iron: -3,
            coal: -1,
            mechanicalComponents: 3
        },
        upgradesFrom: "workshop",
        requiredFeature: "stream",
        requiredTech: "poweredMachinery",
        minWorkerJobs: 0,
        maxWorkerJobs: 3,
        constructionJobs: 20,
    },

    "steamPoweredBlacksmith": {
        tag: "steamPoweredBlacksmith",
        name: "Dampfbetriebene Schmiede",
        description: "Eine dampfbetriebene Schmiede. Hier können Waffen aus Eisen oder Stahl effizient hergestellt werden.",
        buildCosts: {
            mechanicalComponents: 30,
            iron: 30
        },
        buildTime: 60,
        productionPerTurn: {
            iron: -3,
            coal: -2,
            equipment: 3
        },
        upgradesFrom: "blacksmith",
        requiredTech: "steamEngine",
        minWorkerJobs: 0,
        maxWorkerJobs: 3,
        constructionJobs: 20,
    },
    "steamPoweredWorkshop": {
        tag: "steamPoweredWorkshop",
        name: "Dampfbetriebene Werkstatt",
        description: "Hier werden Maschinenteile für Militär und Industrie effizient hergestellt",
        buildCosts: {
            mechanicalComponents: 30,
            iron: 30
        },
        buildTime: 60,
        productionPerTurn: {
            iron: -3,
            coal: -2,
            mechanicalComponents: 3
        },
        upgradesFrom: "workshop",
        requiredTech: "steamEngine",
        minWorkerJobs: 0,
        maxWorkerJobs: 3,
        constructionJobs: 20,
    },
    "orc camp": {
        tag: "orc camp",
        name: "Orklager",
        description: "Hier leben Orks.",
        buildCosts: {
            stone: 100,
            iron: 20
        },
        buildTime: 60,
        nodeModifiers: [flat("housing", 5)],
        requiredTech: null,
        constructionJobs: 20,
    },
    "abandoned house": {
        tag: "abandoned house",
        name: "Verlassenes Haus",
        description: "Ein verlassenes Haus. Mit wenig Aufwand könnte es wieder bewohnbar gemacht werden.",
        buildCosts: {
            stone: 100,
            iron: 20
        },
        buildTime: 60,
        nodeModifiers: [flat("housing", 5)],
        requiredTech: null,
        constructionJobs: 20,
    },
    "abandoned blacksmith": {
        tag: "abandoned blacksmith",
        name: "Verlassene Schmiede",
        description: "Eine verlassene Schmiede. Mit wenig Aufwand könnte sie wieder brauchbar gemacht werden.",
        buildCosts: {
            stone: 100,
            iron: 20
        },
        buildTime: 60,
        requiredTech: null,
        constructionJobs: 20,
    },
    "orcified blacksmith": {
        tag: "orcified blacksmith",
        name: "Verorkte Schmiede",
        description: "Eine von Orks besetzte Schmiede. Hier stellen sie ihre primitiven Schwerter her.",
        buildCosts: {
            stone: 100,
            iron: 20
        },
        buildTime: 60,
        requiredTech: null,
        constructionJobs: 20,
    },
    "huntersHut": {
        tag: "huntersHut",
        name: "Jägerhütte",
        description: "Von hier aus begeben sich Jäger auf gefährliche Ausflüge zur Oberwelt.",
        buildCosts: {
            stone: 100,
            equipment: 20,
            mechanicalComponents: 20
        },
        buildTime: 30,
        productionPerTurn: {
            food: 30
        },
        nodeModifiers: [
            flat("amenities", 10)
        ],
        requiredFeature: "exit",
        maxWorkerJobs: 5,
        constructionJobs: 10,
    },
    "lumberHut": {
        tag: "lumberHut",
        name: "Holzfällerhütte",
        description: "Von hier aus begeben sich Holzfäller auf gefährliche Ausflüge zur Oberwelt.",
        buildCosts: {
            stone: 100,
            equipment: 20,
        },
        buildTime: 30,
        productionPerTurn: {
            wood: 1
        },
        requiredFeature: "exit",
        maxWorkerJobs: 5,
        constructionJobs: 10,
    },
    "charcoalBurnery": {
        tag: "charcoalBurnery",
        name: "Köhlerei",
        description: "Hier wird Holz zu Kohle verarbeitet.",
        buildCosts: {
            stone: 100
        },
        buildTime: 30,
        productionPerTurn: {
            wood: -1,
            coal: 1
        },
        maxWorkerJobs: 3,
        constructionJobs: 10,
    },
    "fishery": {
        tag: "fishery",
        name: "Fischerei",
        description: "Eine Fischerei.",
        buildCosts: {
            stone: 100
        },
        buildTime: 30,
        productionPerTurn: {
            food: 20
        },
        nodeModifiers: [
            flat("amenities", 5)
        ],
        requiredFeature: "lake",
        maxWorkerJobs: 5,
        constructionJobs: 10,
    },
    "thermalBath": {
        tag: "thermalBath",
        name: "Thermalbad",
        description: "An heißen Quellen wird immer gerne gebadet, aber ein liebevoll ausgebautes Bad ist deutlich hübscher und bietet mehr Kapazitäten.",
        buildCosts: {
            stone: 500,
            iron: 100
        },
        buildTime: 120,
        requiredFeature: "thermalSpring",
        nodeModifiers: [
            flat("healing", 20),
            flat("amenities", 100)
        ],
        maxWorkerJobs: 10,
        constructionJobs: 50,
    }
};
