import React from 'react';

const months = ["Jan.", "Feb.", "Mär.", "Apr.", "Mai", "Jun.", "Jul.", "Aug.", "Sep.", "Okt.", "Nov.", "Dez."]
const daysPerMonth = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31]
const displayDate: string[] = []
for (let month = 0; month < 12; month++) {
  const days = daysPerMonth[month];
  const monthName = months[month];
  for (let day = 0; day < days; day++) {
    displayDate.push(`${day + 1}. ${monthName}`);
  }
}


export function TimeDisplay(props: { time: number; }) {
  const date = displayDate[Math.trunc(props.time % 365)];
  const year = Math.trunc(props.time / 365);
  return <>{date} {year === 0 ? "i.J.d.K." : <>{year} n.d.K.</>}</>;
}
