import { TagMap } from "../utils/utils";
import { FromTechnology, Modifier } from "./Modifiers";
import { SlotStats, defaultSlotStats, defaultNodeStats, NodeStats } from "./Node";
import { defaultUnitStats, UnitStats } from "./Platoon";

export interface Technology {
    tag: string;
    name: string;
    description: string;
    dependencies: string[];
    techTreePosition: [number, number];
    researchTime: number;
    modifiers?: TechModifier[]
}

export type UnitTechModifier = Modifier<keyof UnitStats> & { unitType?: string, features?: string[] };
export type SlotTechModifier = Modifier<keyof SlotStats> & { building?: string, features?: string[] }
export type NodeTechModifier = Modifier<keyof NodeStats> & { features?: string[] }

export function isSlotTechModifier(x: TechModifier): x is SlotTechModifier {
    return (defaultSlotStats as any)[x.property] !== undefined;
}
export function isUnitTechModifier(x: TechModifier): x is UnitTechModifier {
    return (defaultUnitStats as any)[x.property] !== undefined;
}
export function isNodeTechModifier(x: TechModifier): x is NodeTechModifier {
    return (defaultNodeStats as any)[x.property] !== undefined;
}

type TechModifier = 
    | SlotTechModifier
    | UnitTechModifier
    | NodeTechModifier

export type ResearchStatus = number | "finished" | undefined;

export function isAvailable(tech: string | null | undefined, research: { [key: string]: ResearchStatus }): "available" | "notResearched" | "unobtainable" {
    if (tech === null)
        return "unobtainable";
    else if (tech === undefined || research[tech] === "finished")
        return "available";
    else
        return "notResearched";
}

export function getTechModifiers(technologies: TagMap<Technology>, research: { [key: string]: ResearchStatus; }): ({source: FromTechnology} & TechModifier)[] {
    return Object.values(technologies)
        .filter(x => research[x.tag] === "finished")
        .flatMap(tech => tech.modifiers?.map(mod => ({
            ...mod, 
            source: {
                type: "fromTechnology", 
                technology: tech.tag
            }
        })) ?? []);
}
