import React from 'react';
import { Button, Icon, IconButton, LinearProgress, Paper, Typography } from '@material-ui/core';
import { Node, Town, UnitInTraining } from '../../logic/Node';
import { useGameState } from '../useGameState';
import { ResourceDeliveryUi } from '../ResourceUi';
import Utils from '../../utils';
import { Slots } from '../Slots';
import { ToggleLocalSelection } from './index';
import "./TrainUnitUi.css";
import { ResourceUtils } from '../../logic/Resources';
import { isAvailable } from '../../logic/Technology';
import { UnitIcon } from '../Icons';
import "./purchaseSelection.css"
import { StatsUi, StatsValue } from '../StatsUi';
import { getUnitTypeStats } from '../../logic/getUnitStats';
import { SubmitCommandContext } from '../SaveGame';
import { UnitType } from '../../logic/Platoon';

export function TrainUnitUi(props: { node: Town; }) {
  const unitTypes = useGameState(gs => gs.unitTypes);
  return <>
    <Typography display="inline" variant="h4">Einheit ausbilden</Typography>
    <br />
    <div className="purchase-selection trainingSelection">
      {Utils.mapObjectToList(unitTypes, unitType =>
        <UnitPurchaseItem node={props.node} unitType={unitType} key={unitType.tag} />
      )}
      <div className="trainingQueue">
        {props.node.trainingQueue?.map((unit, i) =>
          <TrainingQueueItem nodeTag={props.node.tag} unit={unit} key={i} index={i} />
        )}
      </div>
    </div>
  </>;
}

function UnitPurchaseItem(props: { node: Node, unitType: UnitType }) {
  const submitCommand = React.useContext(SubmitCommandContext);
  const { node, unitType } = props;
  const stats = useGameState(gs => {
    if (isAvailable(unitType.requiredTech, gs.research) !== "available") return;
    if (unitType.requiredBuilding !== undefined && !node.buildSlots.some(x => x.site.type === "building" && x.site.kind === unitType.requiredBuilding)) return;
    return getUnitTypeStats(gs, unitType);
  });
  if (!stats) return;
  return <div
    key={unitType.tag}
    className="purchase-selection-item"
    onClick={() => submitCommand({ type: "trainUnitCommand", node: node.tag, unitType: unitType.tag })}
  >
    <UnitIcon unitType={unitType} />
    <div>
      <div className="purchase-name">{unitType.name}</div>
      <div className="purchase-description">{unitType.description}</div>
      <div>
        <span className="resource">
          <div className="resource-icon far fa-hourglass" /><StatsValue stats={stats} stat="trainTime" /></span>
        <span className="resource">
          <div className="resource-icon fas fa-users" /><StatsValue stats={stats} stat="manpower" /></span>
        <StatsValue stats={stats} stat="trainCosts" />
      </div>
    </div>
    <div className="unitTypeStats">
      <StatsUi stats={stats} keys={["meleeAttack", "defense", "maxHealth", "moveSpeed"]} />
    </div>
  </div>
}

function TrainingQueueItem(props: { nodeTag: string, unit: UnitInTraining, index: number }) {
  const submitCommand = React.useContext(SubmitCommandContext);
  const { nodeTag, unit, index } = props;
  const unitType = useGameState(gs => gs.unitTypes[unit.kind]);
  const unitStats = useGameState(gs => getUnitTypeStats(gs, unitType));
  return <React.Fragment key={index}>
    <div className="trainingQueueNumber">{index + 1}</div>
    <div className="unitInTraining">
      <Typography variant="h5">{unitType.name}</Typography>
      <LinearProgress variant="buffer" value={unit.progress * 100} valueBuffer={ResourceUtils.maxProgress(unit.deliveredResources, unitStats.trainCosts) * 100} />
      <div>Ausbildungsfortschritt: {Math.floor(unit.progress * unitStats.trainTime).toFixed()} / {unitStats.trainTime} Tage</div>
      <span className="resource">
        <div className="resource-icon fas fa-users" />
        {unit.conscriptedManpower}/{unitStats.manpower}
      </span>
      <ResourceDeliveryUi deliveredResources={unit.deliveredResources} targetResources={unitStats.trainCosts} />
    </div>
    <IconButton onClick={() => submitCommand({ type: "cancelTrainingCommand", node: nodeTag, queueIndex: index })}>
      <Icon className="fa fa-times" />
    </IconButton>
  </React.Fragment>
}

export function TrainingQueueOverviewUi(props: { node: Town; }) {
  let toggleSelection = React.useContext(ToggleLocalSelection);
  if (props.node.faction === "player") {
    return <Slots>
      {props.node.trainingQueue?.map((unit, i) => <TrainingQueueOverviewItem key={i} unit={unit} />)}
      <Button variant="outlined" color="primary" onClick={() => toggleSelection("TrainUnit")}>
        <Typography variant="h5">+</Typography>
      </Button>
    </Slots>;
  }
  else if ((props.node.trainingQueue?.length ?? 0) === 0) {
    return <span>(Keine Einheiten in Ausbildung.)</span>
  }
  else {
    return <Slots>
      <TrainingQueueOverviewItem unit={props.node.trainingQueue![0]} disabled />
    </Slots>
  }
}

function TrainingQueueOverviewItem(props: { unit: UnitInTraining, disabled?: boolean }) {
  let toggleSelection = React.useContext(ToggleLocalSelection);
  const unitType = useGameState(gs => gs.unitTypes[props.unit.kind]);
  return <Paper onClick={() => !props.disabled && toggleSelection("TrainUnit")}>
    <UnitIcon unitType={unitType} />
    <br />
    ({(100 * props.unit.progress).toFixed()}&nbsp;%)
  </Paper>
}
