import { GameState } from "../logic/GameState";
import { GameObject } from "../logic/GameObject";
import { CanvasItem } from "./Canvas";
import { VisibleEdge } from "../logic/fogOfWar";
import { getBiomeColor } from "./NodeOnCanvas";

export function edgeOnCanvas(gameState: GameState, edge: VisibleEdge): CanvasItem<GameObject> {
    const [node1, node2] = edge.nodes.map(node => gameState.nodes[node]);
    const isVisible = edge.lastSeen === gameState.time;
    return {
        render(canvas, fow) {
            canvas.beginPath();
            canvas.moveTo(...node1.position);
            canvas.lineTo(...node2.position);
            canvas.strokeStyle = getBiomeColor(node1.biome);
            canvas.lineWidth = 10;
            canvas.lineCap = "round";
            canvas.stroke();

            if (isVisible) {
                fow.beginPath();
                fow.moveTo(...node1.position);
                fow.lineTo(...node2.position);
                fow.lineWidth = 15;
                fow.lineCap = "round";
                fow.stroke();
            }
        }
    }
}