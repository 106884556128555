import React from 'react';
import { NodeFeature, NodeFeatureProps, SlotFeature } from '../../logic/Node';
import { useGameState } from '../useGameState';
import Tooltip from '../Tooltip';
import { ModifierDisplay } from '../ModifierDisplay';
import { ObjectIcon } from '../Icons';
import { TimeDisplay } from '../TimeDisplay';
import { scaleModifier } from '../../logic/Modifiers';

export function SlotFeatureIcon(props: { feature: string; }) {
  const feature = useGameState(gs => gs.slotFeatures[props.feature]);
  return <Tooltip tooltip={<SlotFeatureDescription feature={feature} />}>
    <ObjectIcon class="slotFeature" tag={feature.tag} name={feature.name} />
  </Tooltip>;
}
function SlotFeatureDescription(props: { feature: SlotFeature; }) {
  const buildingTypes = useGameState(gs => gs.buildingTypes);
  return <div>
    <h3>{props.feature.name}</h3>
    <p>{props.feature.description}</p>
    {props.feature.modifiers.map(x => <div key={x.property}>
      {x.building !== undefined && `Wenn mit ${buildingTypes[x.building].name} bebaut:`}
      <ModifierDisplay modifier={x} />
    </div>)}
  </div>;
}

export function NodeFeatureIcon(props: { feature: NodeFeature; props: NodeFeatureProps }) {
  return <Tooltip tooltip={<NodeFeatureDescription {...props} />}>
    <ObjectIcon class="nodeFeature" tag={props.feature.tag} name={props.feature.name} />
  </Tooltip>;
}
function NodeFeatureDescription(props: { feature: NodeFeature; props: NodeFeatureProps }) {
  const {
    feature: { name, description, nodeModifiers, slotModifiers, decayTime },
    props: { strength, added, dropoffStart }
  } = props;
  const now = useGameState(gs => gs.time);
  const buildingTypes = useGameState(gs => gs.buildingTypes);

  let dropoffDisplay: JSX.Element | undefined;
  if (decayTime !== undefined) {
    const effectiveStrength = strength ?? 1;
    if (dropoffStart !== undefined) {
      dropoffDisplay = <div>
        Abfall von <TimeDisplay time={dropoffStart} /> bis <TimeDisplay time={dropoffStart + effectiveStrength * decayTime} />
      </div>
    }
    else {
      dropoffDisplay = <div>
        Abfall bis <TimeDisplay time={now + effectiveStrength * decayTime} />
      </div>
    }
  }
  return <div>
    <h3>
      {name}
      {strength !== undefined && <> ({(strength * 100).toPrecision(2)} %)</>}
    </h3>

    <p>{description}</p>

    {nodeModifiers !== undefined && <h4>Effekte auf Siedlung:</h4>}
    {nodeModifiers?.map(x => <div key={x.property}>
      <ModifierDisplay modifier={scaleModifier(x, strength)} />
    </div>)}

    {slotModifiers !== undefined && <h4>Effekte auf Gebäude:</h4>}
    {slotModifiers?.map(x => <div key={x.property}>
      {x.building && <span>{buildingTypes[x.building].name}: </span>}
      <ModifierDisplay modifier={scaleModifier(x, strength)} />
    </div>)}
    <br />
    {dropoffDisplay}

    {added !== undefined && <div>
      Aktiv seit <TimeDisplay time={added} />
    </div>}

  </div>;
}
