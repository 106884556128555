import Utils from "../../utils";
import { OrkifiedJunction } from "./generateMap"
import { Platoon } from "../Platoon";

export function generatePlatoons(nodes: OrkifiedJunction[]): Platoon[] {
    let platoons: Platoon[] = [];
    for (const node of nodes) {
        if (node.orcification > 0) {
            platoons.push(generateOrcPlatoonOnNode(node));
        }
    }
    return platoons;
}

function generateOrcPlatoonOnNode(spawnNode: OrkifiedJunction): Platoon {

    let platoonTag: string = Utils.getUnusedTag("enemy");
    let platoon: Platoon = {
        tag: platoonTag,
        path: [],
        position: { type: "onNode", node: spawnNode.tag },
        units: [],
        faction: "enemy"
    }
    for (let i = 0; i < spawnNode.orcification; i++) {
        platoon.units.push({ kind: "orc", currentHealth: 130, level: 0, experience: 0 });
        platoon.units.push({ kind: "goblin", currentHealth: 60, level: 0, experience: 0 });
        platoon.units.push({ kind: "goblin", currentHealth: 60, level: 0, experience: 0 });
    }

    if (spawnNode.orcification == 3){
        platoon.units.push({ kind: "heavy orc", currentHealth: 150, level: 0, experience: 0 });
    }
    if (spawnNode.orcification == 4){
        platoon.units.push({ kind: "heavy orc", currentHealth: 150, level: 0, experience: 0 });
        platoon.units.push({ kind: "heavy orc", currentHealth: 150, level: 0, experience: 0 });
    }

    for (const slot of spawnNode.buildSlots) {
        if (slot.site.type === "building" && slot.site.kind === "orcified blacksmith") {
            platoon.units.push({ kind: "heavy orc", currentHealth: 150, level: 0, experience: 0 });
        }
    }
    return platoon;
}