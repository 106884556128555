/**
 * @module
 * Provides JSON serialization and deserialization with support for Map and Set.
 */

function _traverse(obj: any): any {
    if (typeof obj !== "object" || obj === null)
        return obj;

    if (Array.isArray(obj)) {
        return obj.map(traverse);
    }

    if (obj.__proto__ === Set.prototype) {
        return {
            $type: "set",
            entries: [...obj].map(traverse),
        }
    }

    if (obj.__proto__ === Map.prototype) {
        return {
            $type: "map",
            entries: [...obj.entries()].map(traverse),
        }
    }

    const traversed = { ...obj };
    for (const key in traversed) {
        if (key.startsWith("$")) throw new Error();
        traversed[key] = traverse(obj[key]);
    }
    return traversed;
}

const traversedCache = new WeakMap();
function traverse(obj: any): any {
    if (typeof obj !== "object" || obj === null)
        return obj;

    const cached = traversedCache.get(obj);
    if (cached) return cached;

    const traversed = _traverse(obj);
    traversedCache.set(obj, traversed);
    return traversed;
}

export function stringify(value: any, spaces?: number | string) {
    return JSON.stringify(traverse(value), undefined, spaces)
}


function revive(obj: any): any {
    if (typeof obj !== "object" || obj === null)
        return obj;

    if (obj.$type === "set") {
        return new Set(obj.entries.map(revive));
    }
    else if (obj.$type === "map") {
        return new Map(obj.entries.map(revive));
    }

    if (Array.isArray(obj)) {
        return obj.map(revive);
    }

    const traversed = { ...obj };
    for (const key in traversed) {
        traversed[key] = revive(obj[key]);
    }
    return traversed;
}

export function parse(text: string) {
    return revive(JSON.parse(text));
}
