import { Modifier } from "./Modifiers";
import { Faction } from "./Faction";
import { Resources } from "./Resources";
import { GameState } from "./GameState";

export interface Junction {
  tag: string;
  position: [number, number];
  buildSlots: BuildSlot[];
  biome: Biome;
  abandonedStructure?: AbandonedStructures;
  features: NodeFeatureSet
}

export interface Town extends Junction {
  name: string;
  faction: Faction;
  description: string;
  founded: number;
  population: number;
  trainingQueue?: UnitInTraining[]
}

export function isTown(node: Node): node is Town {
  return (node as Town).population !== undefined;
}

export function raze(town: Town): Junction {
  const newNode = { ...town } as Partial<Town> & Junction;
  delete newNode.population;
  delete newNode.trainingQueue;
  return newNode;
}

export type Node = Junction | Town;

export interface BuildSlot {
  features: string[],
  site: Site
}
export type NodeFeatureSet = { [type: string]: NodeFeatureProps };
export interface NodeFeatureProps {
  strength?: number;
  /** 
   * The point in time when the feature was added. 
   * The feature is assumed to have "always been" if this is undefined. 
   **/
  added?: number;
  /** 
   * The point in time when the feature will start dropping off. 
   * The feature will start dropping off immediately if 
   **/
  dropoffStart?: number;
}
export type Site = Rubble | Mine | Construction | Building | EmptySlot;

interface HasJobs {
  active: boolean,
  assignedJobs: number,
  priority: number,
}

export function hasJobs(site: Site): site is Mine | Construction | Building {
  return site.type !== "empty" && site.type !== "rubble";
}

export interface Rubble {
  type: "rubble",
  resources: Resources
}
export interface Mine extends HasJobs {
  type: "mine",
  resources: Resources,
  /** The building type that is planned for this slot. Building will start once all resources have been extracted. */
  plannedBuildingType?: string
}

export interface Construction extends HasJobs {
  type: "construction",
  kind: string,
  progress: number,
  resources: Resources,
}

export interface Building extends HasJobs {
  type: "building",
  kind: string,
}

export interface EmptySlot {
  type: "empty"
}

export interface BuildingType extends Partial<BuildingStats> {
  tag: string;
  name: string;
  description: string;
  /** The technology required to construct a building of this type. Use `null` for unconstructable buildings (e.g. abandoned structures), leave out for buildings without technology requirements (i.e. initially available buildings). */
  requiredTech?: string | null;
  /**The feature that needs to be present on the slot or the node to enable the construction of this building. 
   * Leave this out if the building can be placed anywhere.
   * 
   * This may be extended, in the future, to include more complicated expressions like "and", "or" and "not". 
   * */
  requiredFeature?: string;
  nodeModifiers?: Modifier<keyof NodeStats>[];
  upgradesFrom?: string;
}
export const defaultBuildingStats = {
  buildCosts: {} as Resources,
  buildTime: 1,
  productionPerTurn: {} as Resources,
  minWorkerJobs: 0,
  maxWorkerJobs: 0,
  constructionJobs: 5,
}

export type BuildingStats = typeof defaultBuildingStats;
export const defaultSlotStats = {
  ...defaultBuildingStats,
  miningSpeed: 1,
  miningJobs: 5
};
export type SlotStats = typeof defaultSlotStats;
export const scalesWithJobs: { [key in keyof SlotStats]?: true } = {
  productionPerTurn: true,
  miningSpeed: true
}
export const defaultNodeStats = {
  amenities: 0,
  housing: 15,
  popGrowth: 5,
  healing: 10,
  foodUse: 0,
};
export type NodeStats = typeof defaultNodeStats;

export enum Biome {
  Volcanic = "Volcanic",
  VolcanicExtreme = "VolcanicExtreme",
  Lakes = "Lakes",
  ThermalSprings = "ThermalSprings",
  Standard = "Standard",
}

export enum AbandonedStructures {
  Mine = "Mine",
  Town = "Town",
  City = "City",
  Bastion = "Bastion"
}

export interface UnitInTraining {
  kind: string,
  deliveredResources: Resources,
  conscriptedManpower: number,
  progress: number
}
export type SlotFeatureModifier = Modifier<keyof SlotStats> & { building?: string };

export interface SlotFeature {
  tag: string,
  name: string,
  description: string,
  modifiers: SlotFeatureModifier[]
}

export interface NodeFeature {
  tag: string,
  name: string,
  description: string,
  slotModifiers?: SlotFeatureModifier[],
  nodeModifiers?: Modifier<keyof NodeStats>[],
  /** The time that this modifier needs to decay completely, in days. */
  decayTime?: number;
}

export type BuildSlotIdentifier = {
  nodeTag: string,
  slotIndex: number
}
export function resolveBuildSlot(gameState: GameState, slotIdentifier: BuildSlotIdentifier): { slot: BuildSlot, node: Node } {
  const node = gameState.nodes[slotIdentifier.nodeTag];
  const slot = node.buildSlots[slotIdentifier.slotIndex];
  return { node, slot };
}

/** Simple helper function to create a build slot with a building of the specified type. */
export function building(kind: string, features?: string[], active?: boolean): BuildSlot {
  return {
    features: features ?? [],
    site: {
      type: "building",
      active: active ?? true,
      kind,
      assignedJobs: 0,
      priority: 0
    }
  }
}

/** Simple helper function to create an empty build slot. */
export function emptySlot(features?: string[]): BuildSlot {
  return {
    features: features ?? [],
    site: { type: "empty" }
  }
}

export function getTowns(gameState: GameState, faction?: Faction) {
  if (faction !== undefined)
    return Object.values(gameState.nodes).filter(x => isTown(x) && x.faction === faction) as Town[];
  else
    return Object.values(gameState.nodes).filter(x => isTown(x)) as Town[];
}
