import React from 'react';
import { StatDefaults, Stats } from '../logic/makeStatsBuilder';
import { StatInfo } from '../logic/Modifiers';
import { Resources } from '../logic/Resources';
import { Stat, statDetails } from '../logic/StatDetails';
import { ModifierWithSourceDisplay } from './ModifierDisplay';
import { ResourceUi } from './ResourceUi';
import Tooltip from './Tooltip';


export function StatsUi<T extends StatDefaults>(props: { stats: Stats<T>; keys: (keyof T & string)[]; }) {
  return <>{props.keys.map(x => <React.Fragment key={x}>
    <StatsIcon name={x} />
    <StatsValue stats={props.stats} stat={x}/>
  </React.Fragment>)}</>;
}
export function StatsValue<T extends StatDefaults>(props: { stats: Stats<T>; stat: keyof T & string }) {
  const {stats} = props;
  const stat = stats[props.stat];
  const details = stats.getDetails(props.stat);
  return <span className="stat-value">
    <Tooltip tooltip={<StatsTooltip details={details} stat={props.stat} />}>
      {
      typeof stat === "object" ? 
      <ResourceUi resources={stat as Resources}/> : 
      Math.round(stat as number * 100) / 100
      }
      </Tooltip>
    </span>;
}

function ValueDisplay(props: {value: number | Resources}) {
  return typeof props.value === "object" ? 
  <ResourceUi resources={props.value as Resources}/> : 
  <>{props.value}</>
}

function StatsTooltip<T extends StatDefaults>(props: { details: StatInfo<any>, stat: keyof T & string }) {
  const { flatBoni, percentBoni, multiplierBoni } = props.details;
  const hasBoni = flatBoni.length > 0 || percentBoni.length > 0 || multiplierBoni.length > 0;
  const statDescription = statDetails[props.stat as keyof Stat];

  // We're using ascending numbers for unique keys here. 
  // This would not be ideal if the modifiers change constantly, but since that's unlikely and the damage is tiny, it's ok.
  let uniqueKey = 0;
  return <>
    {props.details.isDefault ? "Standardwert" : "Basiswert"}: {<ValueDisplay value={props.details.base}/>}
    {hasBoni && <div>
      {flatBoni.map(x => <ModifierWithSourceDisplay key={uniqueKey++} modifier={x} />)}
      {percentBoni.map(x => <ModifierWithSourceDisplay key={uniqueKey++} modifier={x} />)}
      {multiplierBoni.map(x => <ModifierWithSourceDisplay key={uniqueKey++} modifier={x} />)}
    </div>}
    <div>{statDescription.description}</div>
  </>
}
function StatsIcon(props: { name: string; }) {
  switch (props.name) {
    case "meleeAttack":
      return <span className="stat-icon fas fa-crosshairs" />;
    case "defense":
      return <span className="stat-icon fas fa-shield-alt" />;
    case "moveSpeed":
      return <span className="stat-icon fas fa-walking" />;
    case "maxHealth":
      return <span className="stat-icon fas fa-heart" />;
    default:
      return <span>&lt;{props.name}&gt;</span>;
  }
}
