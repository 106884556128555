import { GameState } from "../../GameState";
import { Faction } from "../../Faction";
import { BuildSlotIdentifier, hasJobs } from "../../Node";
import { Draft } from "immer";
import validateIsOwnTown from "./validateIsOwnTown";

export default function validateSiteHasJobs(gameState: Draft<GameState>, slotId: BuildSlotIdentifier, sender: Faction) {
    const { nodeTag, slotIndex } = slotId;
    const node = validateIsOwnTown(gameState, nodeTag, sender);
    if (node === undefined) return;
    const slot = node.buildSlots[slotIndex];
    if (slot === undefined) {
        console.warn(`Invalid command: The slot ${nodeTag}#${slotIndex} does not exist.`);
        return;
    }
    if (!hasJobs(slot.site)) {
        console.warn(`Invalid command: The slot ${nodeTag}#${slotIndex} does not contain a building, construction or mine.`);
        return;
    }
    return slot.site;
}
