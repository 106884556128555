import { GameState } from "../../GameState";
import { produce } from "immer";

export function updateNodeFeatures(gameState: GameState): GameState {
    const decayingFeatures = Object.values(gameState.nodes)
        .flatMap(x => Object.entries(x.features)
            .filter(([feature, props]) => gameState.nodeFeatures[feature].decayTime !== undefined
                && (props.added !== gameState.time)
                && (props.dropoffStart === undefined || gameState.time > props.dropoffStart))
            .map(([feature, props]) => ({ node: x.tag, feature, decayTime: gameState.nodeFeatures[feature].decayTime! })));
    return produce(gameState, gameState => {
        for (const { node, feature, decayTime } of decayingFeatures) {
            const props = gameState.nodes[node].features[feature];
            props.strength = (props.strength ?? 1) - 1 / decayTime;
            if (props.strength <= 0) {
                delete gameState.nodes[node].features[feature];
            }
        }
    });
}
