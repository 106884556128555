import { isDraft, current } from "immer";

/**
 * Memoizes a function using a `WeakMap`. Requires object identity to work. Immer drafts will be unpacked automatically.
 */
export default function weakMemoize<TSource extends object, TTarget>(generator: (source: TSource) => TTarget) {
    const cache = new WeakMap<TSource, TTarget>();
    return (source: TSource) => {
        // source = isDraft(source) ? original(source) : source;
        source = isDraft(source) ? current(source) : source;
        let result = cache.get(source);
        if (result === undefined) {
            result = generator(source);
            cache.set(source, result);
        }
        return result;
    };
}
