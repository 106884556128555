import React from 'react';
import ReactDOM from 'react-dom';
import "./Tooltip.css"

const TooltipContainerRefContext = React.createContext<React.RefObject<HTMLElement>>({ current: null });
export default function Tooltip(props: { tooltip: React.ReactNode; children: React.ReactNode; }) {
    const tooltipContainer = React.useContext(TooltipContainerRefContext);
    const elementRef = React.useRef<HTMLSpanElement>(null);
    const [visible, setVisible] = React.useState(false);
    const [width, setWidth] = React.useState(0);
    const [height, setHeight] = React.useState(0);
    let tooltipElement;
    if (visible && tooltipContainer.current !== null && elementRef.current !== null) {
        const sourceRect = elementRef.current.getBoundingClientRect();
        const containerRect = tooltipContainer.current.getBoundingClientRect();
        let left = sourceRect.left + sourceRect.width / 2 - width / 2;
        let top = sourceRect.top + sourceRect.height;
        if (left < 0)
            left = 0;
        else if (left + width > containerRect.right)
            left = containerRect.right - width;
        if (top + height > containerRect.bottom)
            top = sourceRect.top - height;
        tooltipElement = ReactDOM.createPortal(
            <div className="tooltip-wrapper" style={{ top, left }}>
                <div className="tooltip" ref={r => {
                    if (r === null) return;
                    setWidth(r.offsetWidth);
                    setHeight(r.offsetHeight);
                }}>
                    {props.tooltip}
                </div>
            </div>,
            tooltipContainer.current);
    }
    return <>
        <span
            className="hasTooltip"
            onMouseEnter={() => setVisible(true)}
            onMouseLeave={() => setVisible(false)}
            // onClick={() => setVisible(x => !x)}
            ref={elementRef}
        >
            {props.children}
        </span>
        {tooltipElement}
    </>;
}
export function TooltipContainer(props: { children: React.ReactNode; }) {
    const ref = React.useRef(null);
    return < >
        <TooltipContainerRefContext.Provider value={ref}>
            {props.children}
        </TooltipContainerRefContext.Provider>
        <div className="tooltip-container" ref={ref} />
    </>;
}
