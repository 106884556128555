import { GameState } from "../../GameState";
import { getTowns } from "../../Node";
import { getNodeStatsFor } from "../../getNodeStats";
import _ from "lodash";
import { produce } from "immer";
import Utils from "../../../utils";
import { getUnitStats } from "../../getUnitStats";
import { ResourceUtils } from "../../Resources";

export function applyFoodConsumption(gameState: GameState): GameState {
    const platoons = Utils.values(gameState.platoons).filter(x => x.faction === "player");
    const militaryFoodUse = platoons.flatMap(x => x.units.map(u => getUnitStats(gameState, u).manpower)).reduce(_.add, 0);

    const towns = getTowns(gameState, "player");
    const civilFoodUse = towns.map(x => getNodeStatsFor(gameState, x).foodUse).reduce(_.add, 0);

    const totalUse = militaryFoodUse + civilFoodUse;
    const existingFood = (gameState.resources["food"] ?? 0);

    if (totalUse > existingFood) {
        const missing = totalUse - existingFood;
        const addedMalnourishment = missing / totalUse / 30;
        return produce(gameState, gameState => {
            for (const town of towns.map(x => gameState.nodes[x.tag])) {
                const feature = town.features["malnourished"];
                const oldMalnourishment = feature === undefined ? 0 : (feature.strength ?? 1);
                let newMalnourishment;
                newMalnourishment = Math.min(oldMalnourishment + addedMalnourishment, 1);
                if (newMalnourishment === 1)
                    newMalnourishment = undefined;
                town.features["malnourished"] = { added: gameState.time, strength: newMalnourishment }
            }

            for (const platoon of platoons.map(x => gameState.platoons[x.tag]!)) {
                for (const unit of platoon.units) {
                    const maxHealth = getUnitStats(gameState, unit).maxHealth;
                    unit.currentHealth -= maxHealth * addedMalnourishment;
                }
                platoon.units = platoon.units.filter(x => x.currentHealth > 0)
                if (platoon.units.length === 0)
                    delete gameState.platoons[platoon.tag];
            }

            gameState.resources["food"] = 0;

            gameState.resourceUsage = ResourceUtils.addUsages(gameState.resourceUsage, "Militär", { food: -existingFood * (militaryFoodUse / totalUse) })
            gameState.resourceUsage = ResourceUtils.addUsages(gameState.resourceUsage, "Bevölkerung", { food: -existingFood * (civilFoodUse / totalUse) })

        });
    }

    else
        return produce(gameState, gameState => {
            gameState.resources["food"] = existingFood - totalUse;
            gameState.resourceUsage = ResourceUtils.addUsages(gameState.resourceUsage, "Militär", { food: -militaryFoodUse })
            gameState.resourceUsage = ResourceUtils.addUsages(gameState.resourceUsage, "Bevölkerung", { food: -civilFoodUse })
        });
}
